import * as React from "react";
import PropTypes from "prop-types";
import {StyleSheet, Text, View} from "react-native";
import {globalText} from "../fonts";

const NumberedText = ({children, prefix}) => {
    return (
        <View style={styles.container}>
            <Text style={styles.numberText}>{prefix}</Text>
            <View style={styles.textContainer}>
                {children}
            </View>
        </View>
    );
};
const styles = StyleSheet.create({
    container: {
        paddingLeft: 15,
        flexDirection: "row",
        flexWrap: "nowrap"
    },
    numberText: {
        paddingRight: 5,
    },
    textContainer: {
        width: "100%"
    },
    title: {
        ...globalText["body-sm-bold"],
        paddingRight: 5,
    },
});
NumberedText.propTypes = {
    prefix: PropTypes.string,
    children: PropTypes.any
};

export default NumberedText;