import axios from "axios";
import {getStore} from "../store";

/**
 * This base client will setup and use the JWT tokens for all communication.  This does NOT include the code
 * to refresh the tokens.  Use the ApiClient for that.
 */
class BaseApiClient {

    /**
     * Constructor for the API client.
     * @param baseUri The name of the base URI to use when making calls.  This allows the calls to only specify
     * the path and work for different environments.
     */
    constructor(baseUri) {
        const store = getStore();
        this.baseUri = baseUri;
        this.userId = null;
        if (store.getState()?.auth?.user && store.getState().auth.user["user_id"]) {
            this.userId = store.getState().auth.user["user_id"];
        }
        this.headers = {
            "Content-Type": "application/json",
            Authorization: store.getState().auth.tokens
                ? `Bearer ${store.getState().auth.tokens.jwtToken}`
                : null,
        };

        this.axiosInstance = axios.create({
            baseURL: this.baseUri,
            headers: this.headers,
        });
    }

    get(path, params) {
        const config = {
            url: `${this.baseUri}${path}`,
            method: "GET",
            params,
        };

        return this.request(config);
    }

    post(path, data) {
        const config = {
            url: `${this.baseUri}${path}`,
            method: "POST",
            data,
        };

        return this.request(config);
    }

    put(path, data) {
        const config = {
            url: `${this.baseUri}${path}`,
            method: "PUT",
            data,
        };
        return this.request(config);
    }

    delete(path, params) {
        const config = {
            url: `${this.baseUri}${path}`,
            method: "DELETE",
            params,
        };

        return this.request(config);
    }

    request(config) {
        return this.axiosInstance.request(config);
    }
}

export default BaseApiClient;
