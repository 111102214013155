import React, {useCallback} from 'react';
import {Linking, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {SvgXml} from "@cantoo/rn-svg";
import {globalText} from "../fonts";
import PropTypes from "prop-types";
import {getConfig} from "../config/config";
import {useColors, useConfig} from "../store/whiteLabelConfig/util";

const AnonymousTopBarNavigation = ({tabName}) => {

    const logo = useConfig().logo;
    const homeUrl = getConfig().openAutoUrl;
    const navigateHome = useCallback(() => Linking.openURL(homeUrl), []);
    const styles = buildStyles(useColors());

    return (
        <View style={styles.container}>
            <TouchableOpacity testID="logoIcon" onPress={navigateHome}>
                <SvgXml height="45px" width="100px" xml={logo}/>
            </TouchableOpacity>
            <View style={styles.screenLinks}>
                <View style={styles.screenLink}>
                    <Text style={styles.screenLinkText}>{tabName}</Text>
                </View>
            </View>
        </View>
    );
};

const buildStyles = colors => StyleSheet.create({
    container: {
        height: 58,
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 10,
        paddingLeft: 30,
        paddingRight: 30,
        borderBottomWidth: 1,
        borderBottomColor: colors.background["border-1-color"],
        zIndex: 1,
    },
    screenLinks: {
        marginRight: "auto",
        paddingLeft: 32,
    },
    screenLink: {
        marginRight: 32,
        paddingVertical: 13,
        paddingTop: 10,
        borderBottomColor: colors.primaryShades["primary-color-base"],
        ...globalText["body-base-regular"],
        color: colors.primaryShades["primary-color-base"],
    },
});

AnonymousTopBarNavigation.propTypes = {
    tabName: PropTypes.string,
    navigation: PropTypes.object
};

export default AnonymousTopBarNavigation;