import React, {useCallback, useRef, useState} from 'react';
import {Animated, StyleSheet, TextInput, View,} from 'react-native';
import PropTypes from "prop-types";
import {getPlatform} from "../utils/platform";
import {globalText} from "../fonts";
import {useColors} from "../store/whiteLabelConfig/util";

const AcvTextInput = (props) => {

    const textInput = useRef();

    const [isFocused, setFocused] = useState(false);
    const [textValue, setTextValue] = useState(props.value);

    const onTextValueChange = useCallback((newTextValue) => {
        setTextValue(newTextValue);
        props.onChangeText(newTextValue);
    }, []);
    const showInputWithText = useCallback(() => isFocused || textValue !== '', [isFocused, textValue]);
    const handleFocus = useCallback(() => setFocused(true), []);
    const handleBlur = useCallback(() => setFocused(false), []);
    const handleLabelPress = useCallback(() => textInput.current.focus(), []);

    const styles = buildStyles(useColors());
    const platform = getPlatform();

    const focusedStyling = showInputWithText() ? styles.focusedStyle : {};
    /* istanbul ignore next */
    const webFocusedStyling = platform === 'web' && showInputWithText() ? {outlineWidth: 0} : {};
    const textInputStyle = [styles.textStyling, focusedStyling, webFocusedStyling];
    return (
        <View style={styles.container}>
            <Animated.Text style={[styles.labelStyle, showInputWithText() ? styles.labelFocusedStyle : {}]}
                           onPress={handleLabelPress}>
                {props.label}
            </Animated.Text>
            <TextInput
                {...props}
                // Here we add a transparent placeholder, this is so we can test the text input by getting it
                // by the placeholder value.
                placeholder={props.label}
                placeholderTextColor={"rgba(0,0,0,0)"}
                value={textValue}
                onChangeText={onTextValueChange}
                style={textInputStyle}
                onFocus={handleFocus}
                onBlur={handleBlur}
                blurOnSubmit
                ref={textInput}
            />
        </View>
    );
};

const buildStyles = colors => StyleSheet.create({
    container: {
        backgroundColor: colors.background["background-1-color"],
        borderRadius: 5,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        marginBottom: 15
    },
    labelStyle: {
        ...globalText["body-base-regular"],
        position: 'absolute',
        left: 10,
        top: 14,
        fontSize: 18,
        color: colors.text["text-secondary-color"],
    },
    labelFocusedStyle: {
        color: colors.text["text-link-color"],
        top: 4,
        fontSize: 12
    },
    focusedStyle: {
        borderColor: colors.text["text-link-color"],
        borderBottomWidth: 2
    },
    textStyling: {
        ...globalText["body-base-regular"],
        height: 56,
        paddingTop: 13,
        padding: 10,
        borderWidth: 0,
    },
});

AcvTextInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChangeText: PropTypes.func.isRequired
};
export default AcvTextInput;
