// Ignored for unit testing as it is glue code. Test at a higher level.
/* istanbul ignore file */
import {applyMiddleware, createStore} from "redux";
import {persistReducer, persistStore} from "redux-persist";
import rootReducer from './rootReducers';
import thunk from "redux-thunk";
import AsyncStorage from "@react-native-async-storage/async-storage";

const persistConfig = {
    key: "acv",
    storage: AsyncStorage
};
// Define how we will reduce our state and where it will be stored.
const persistedReducer = persistReducer(persistConfig, rootReducer);
// Build a store for it.
const store = createStore(persistedReducer, applyMiddleware(thunk));
// VERY IMPORTANT: Setup store to be stored in local storage.
persistStore(store);

export const getStore = () => store;
export const getState = () => store.getState();