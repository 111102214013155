import {getConfig} from "../config/config";
import ApiClient from "./apiClient";
import {getStore} from "../store";
import {deleteSession} from "../store/auth/actions";

export const resetPassword = async email => {
    const gatewayClient = new ApiClient(getConfig().authApiUrl);
    return gatewayClient.post("/api/passwordreset/request", {
        userName: email
    });
};

export const login = async(email, password) => {
    const gatewayClient = new ApiClient(getConfig().authApiUrl);
    let response = await gatewayClient.post("/api/legacylogin/v2", {
        email,
        password,
        web: true,
    });
    return response.data;
};

export const logOut = () => {
    const store = getStore();
    store.dispatch(deleteSession());
};
