import axios from "axios";
import {deleteSession, refreshFailed, refreshSession, refreshStart,} from "../store/auth/actions";
import {getConfig} from "../config/config";
import {getStore} from "../store";
import BaseApiClient from "./baseApiClient";
import {pushMeasurement} from "../utils/telemetry";


/* istanbul ignore next */
const sleep = (ms) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
};

/* istanbul ignore next */
const shouldRefreshToken = (configUrl) => {
    // Exclude these urls from a token refresh workflow
    const urlExcludeList = ["/tokenrefresh", "/login"];
    return urlExcludeList.every((url) => !configUrl.includes(url));
};

const buildClient = (baseUri) => {
    return new BaseApiClient(baseUri);
};

const getRefreshedJwt = async () => {
    let store = getStore();

    const {refreshToken} = store.getState().auth.tokens;
    const apiClient = buildClient(getConfig().authApiUrl);

    let data;
    try {
        data = (
            await apiClient.post("/api/tokenrefresh", {
                refreshTokenId: refreshToken,
            })
        ).data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            store.dispatch(deleteSession());
        }
        store.dispatch(refreshFailed());
        return Promise.reject(error);
    }
    store.dispatch(refreshSession(data));
    return store.getState().auth.tokens.jwtToken;
};

//TODO: Refactor this and add tests.
/* istanbul ignore next */
const interceptors = {
    response: {
        success(response) {
            return response;
        },
        async error(error) {
            if (error) {
                const status = error.response?.status;
                let config, configUrl = "";
                switch (status) {
                    case 401:
                        config = error.config;
                        if( config.url) {
                            configUrl = config.url;
                        }
                        // If the request isn't part of the token refresh workflow
                        if (shouldRefreshToken(configUrl)) {
                            const store = getStore();
                            let jwtToken;

                            // If another request is already refreshing the token
                            if (store.getState().auth.refreshingToken) {
                                let retries = 1;

                                // Wait with a backoff for the existing token refresh to complete
                                while (store.getState().auth.refreshingToken && retries < 5) {
                                    await sleep(1000 * retries);
                                    retries += 1;
                                }

                                // The existing token refresh never completed after 10 seconds
                                if (store.getState().auth.refreshingToken) {
                                    return Promise.reject(error);
                                }
                                // The existing token refresh completed, load the new jwt from state
                                jwtToken = store.getState().auth.tokens.jwtToken;
                            } else {
                                // No existing token refresh in progress, start new token refresh
                                store.dispatch(refreshStart());

                                // Try to get a new jwt
                                jwtToken = await getRefreshedJwt();
                            }

                            if (jwtToken) {
                                // Retry the original request with the new jwt
                                config.headers.Authorization = `Bearer ${jwtToken}`;

                                return axios.request(config);
                            }
                        } else {
                            // The request was a 401 as part of the token refresh workflow
                            return Promise.reject(error);
                        }

                        break;
                    default:
                        return Promise.reject(error);
                }
            }
        },
    },
};

// Just Glue Code.  Testing at higher level.
/* istanbul ignore next */
const installInterceptors = (axiosInstance) => {
    // Setup JWT 401 handlers.
    axiosInstance.interceptors.response.use(
        interceptors.response.success,
        interceptors.response.error
    );
    // Setup Latency handlers.
    axiosInstance.interceptors.request.use(
        (config) => ({...config, startTime: performance.now()}),
        (error) => Promise.reject(error));
    axiosInstance.interceptors.response.use((response) => {
            pushMeasurement("latency",
                {
                    success: true,
                    url: response.config.url,
                    method: response.config.method,
                    durationMs: performance.now() - response.config.startTime
                });
            return response;
        },
        (error) => {
            pushMeasurement("latency",
                {
                    success: false,
                    statusCode: error.response.status,
                    url: error.response.config.url,
                    method: error.response.config.method,
                    durationMs: parseInt(performance.now() - error.response.config.startTime)
                });
            Promise.reject(error);
        });
    return axiosInstance;
};

export {getRefreshedJwt, installInterceptors};
