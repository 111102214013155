import React, {useCallback, useEffect, useState} from "react";
import {FlatList, View} from "react-native";
import {SafeAreaView} from "react-native-safe-area-context";
import {bid, fetchAuctionInfo, fetchCarsForLane, proxyBid} from "../../apiClients/auctionsApi";
import AuctionHeader from "../../components/auction/auctionHeader";
import AuctionRow from "../../components/auction/auctionRow";
import useInterval from "../../utils/interval";
import PropTypes from "prop-types";
import useLargeFormFactorCheck from "../../utils/responsive";
import BidSwipeModal from "../../components/bid/bidSwipeModal";
import BidButtonModal from "../../components/bid/bidButtonModal";
import {useColors, useConfig} from "../../store/whiteLabelConfig/util";
import BidProxyModal from "../../components/bid/bidProxyModal";
import {pushMeasurement} from "../../utils/telemetry";
import {useToastMessaging} from "../../utils/messaging";
import {useTranslation} from "react-i18next";

const HomeScreen = (props) => {

    const toast = useToastMessaging();
    const {t} = useTranslation();

    // Auctions we show on the screen.
    const [auctions, setAuctions] = useState([]);
    // Updates from a periodic refresh.
    const [auctionApiUpdates, setAuctionApiUpdates] = useState([]);
    // An auction that is open in the bidding dialog.
    const [openAuction, setOpenAuction] = useState(null);

    // Determine whether app is refreshing to display loading spin
    const [isLoading, setIsLoading] = useState(false);

    const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));
    const [showBidSwipeModal, setShowBidSwipeModal] = useState(false);
    const [showBidButtonModal, setShowBidButtonModal] = useState(false);
    const [showProxyModal, setShowProxyModal] = useState(false);
    const laneId = useConfig().laneId;

    /* istanbul ignore next */
    const handleAuctionOpen = useCallback((auction) => {
        if (openAuction && openAuction.closeAction) {
            openAuction.closeAction(openAuction);
        }
        setOpenAuction(auction);
    }, [auctions]);

    /* istanbul ignore next */
    const handleAuctionClose = useCallback((auction) => {
        if ((auction && openAuction && auction.id === openAuction.id) ||
            (!auction && openAuction)) {
            setOpenAuction(null);
        }
    }, [auctions]);

    /**
     * Merge new/updated LIVE auctions from API to the current auction list
     * @returns {((*&T)|T)[]}
     */
    const mergeApiAuctionUpdates = () => {
        const existingAuctionIds = auctions.map(auction => auction.id);
        const newAuctions = auctionApiUpdates.filter((auction) => !existingAuctionIds.includes(auction.id));
        /* istanbul ignore next */
        const mergedAuctions = auctions.map(existingAuction => ({
            ...existingAuction,
            ...auctionApiUpdates.find(updatedAuction => updatedAuction.id === existingAuction.id)
        }));
        return [...mergedAuctions, ...newAuctions];
    };

    /**
     * A hook to update the timers on the auctions when the current time state changes.
     */
    useEffect(() => {
        // Merge in any API auction updates.
        /* istanbul ignore next */
        const allAuctions = auctionApiUpdates ? mergeApiAuctionUpdates() : auctions;
        // Update the currentTime so the timer changes are reflected.
        setAuctions(allAuctions.map(auction => ({...auction, currentTime})));
        // Clean up any "old" API updates.
        setAuctionApiUpdates([]);
    }, [currentTime]);

    /**
     * Return list of ENDING auctions (timer is over and status is ACTIVE)
     * @returns {Object[]} - list of ENDING auctions
     */
    const getEndingAuctions = () => {
        return auctions
            .filter((auction) => auction.end_time <= auction.currentTime && auction.status === 'active')
            .map((auction) => auction.id);
    };

    /**
     * A hook to update status of the ENDING auctions
     */
    useEffect(() => {
        let mounted = true;

        // Get list of ENDING auctions
        const endingAuctionIds = getEndingAuctions();

        // If there is any ENDING auctions, then update those auctions info
        if(endingAuctionIds.length > 0){
            /**
             * Call API to fetch updated auction info by list of auction ids
             * And then merge the updated auctions to current auction list
             * This is used to update info (status) of all ENDED auctions -> SOLD/UNSOLD
             * @param auctionIds
             */
            const updateAuctionStatus = async (auctionIds) => {
                const auctionInfoResponse = await fetchAuctionInfo(auctionIds);
                if (mounted) {
                    // Push updated auctions to AuctionAPI queue to avoid glitch timer
                    // Next tick when current time changes, the AuctionAPI will be automatically merged into all auctions list
                    setAuctionApiUpdates(auctionInfoResponse.results);
                }
            };

            updateAuctionStatus(endingAuctionIds);
        }

        /* istanbul ignore next */
        return () => {
            mounted = false;
        };

    }, [currentTime]);


    /**
     * Update current time state.  This will trigger an update of the auctions and a merge of an API updates.
     */
    useInterval(() => setCurrentTime(Date.now() / 1000), props.timerIntervalMs, true);

    /**
     * Periodic refresh of cars state from the active lane.
     */
    useInterval(() => fetchCarsForLane(laneId).then((res) => setAuctionApiUpdates(res.results)),
        props.refreshIntervalMs, true);


    const getItemId = useCallback(item => item.id.toString(), []);
    const renderItem = useCallback(({item}) => {
        return <AuctionRow
            onOpen={handleAuctionOpen}
            onClose={handleAuctionClose}
            onBidSwipeShow={startBidSwipeModal}
            navigation={props.navigation}
            item={item}
            onBidButtonShow={startBidButtonModal(item)}
            onProxyButtonShow={startProxyModal(item)}
        />;
    }, [auctions]);


    /* istanbul ignore next */
    const startBidSwipeModal = () => {
        setShowBidSwipeModal(true);
    };

    /* istanbul ignore next */
    const startBidButtonModal = (item) => {
        return () => {
            setOpenAuction(item);
            setShowBidButtonModal(true);
        };
    };

    /* istanbul ignore next */
    const startProxyModal = (item) => {
        return () => {
            // Using same state here for auction item
            setOpenAuction(item);
            setShowProxyModal(true);
        };
    };

    /* istanbul ignore next */
    const finishBid = useCallback(async (auctionId, amount) => {
        try {
            if (auctionId && amount) {
                await bid(auctionId, amount);
            }
        } catch (error) {
            const title = t("There was a problem.");
            const errorMessage = t("There was a problem placing your bid. Please try again.");
            toast.showError(title, errorMessage);
            pushMeasurement("bidError", {error});
        } finally {
            setShowBidSwipeModal(false);
            setShowBidButtonModal(false);
            handleAuctionOpen(null);
        }
    }, [auctions]);

    /**
     * Manually refresh auctions to get LIVE auctions and remove all ENDED auctions
     */
    const onRefreshAuctions = useCallback(async () => {
        try{
            setIsLoading(true);
            // Fetch LIVE auctions from server
            const res = await fetchCarsForLane(laneId);
            const cars = res.results;

            // Update auction list timer data
            setAuctions(cars.map(auction => ({...auction, currentTime})));
        } catch(error){
            pushMeasurement("fetchCarsForLaneError", {error});
        }
        finally{
            setIsLoading(false);
        }
    }, [currentTime]);

    /* istanbul ignore next */
    const finishProxy = useCallback(async (auctionId, amount) => {
        if (auctionId && amount) {
            try {
                await proxyBid(auctionId, amount);
                setShowProxyModal(false);
                handleAuctionOpen(null);
            } catch (err) {
                const title = t("There was a problem.");
                const errorMessage = t("errorProxyGeneric");
                toast.showError(title, errorMessage);
                pushMeasurement("proxyError", {err});
                return err.response;
            }
        } else {
            setShowProxyModal(false);
            handleAuctionOpen(null);
        }
    }, [auctions, setShowProxyModal, handleAuctionOpen]);


    const isDesktopOrLaptop = useLargeFormFactorCheck();
    const colors = useColors();
    return (
        <View style={{flex: 1, flexDirection: "column"}}>
            <SafeAreaView
                style={{width: "100%", height: "100%", backgroundColor: colors.background["background-3-color"]}}>
                {   /* istanbul ignore next */
                    isDesktopOrLaptop ? (
                        <View>
                            <View style={{width: 860, alignSelf: "center"}}>
                                <FlatList
                                    onScroll={handleAuctionOpen}
                                    ListHeaderComponent={<AuctionHeader cars={auctions} onRefresh={onRefreshAuctions} />}
                                    showsVerticalScrollIndicator={true}
                                    data={auctions}
                                    keyExtractor={getItemId}
                                    renderItem={renderItem}
                                /></View>
                        </View>
                    ) : (
                        <FlatList
                            ListHeaderComponent={<AuctionHeader cars={auctions}/>}
                            showsVerticalScrollIndicator={true}
                            data={auctions}
                            keyExtractor={getItemId}
                            renderItem={renderItem}
                            refreshing={isLoading}
                            onRefresh={onRefreshAuctions}
                        />
                    )}
            </SafeAreaView>
            {   /* istanbul ignore next */
                showBidSwipeModal ? <BidSwipeModal closeAction={finishBid}
                                                   auctionDetails={openAuction}
                                                   highBid={openAuction.high_bid ? openAuction.high_bid : openAuction.start_price}
                                                   currentTime={currentTime}
                                                   endTime={openAuction["end_time"]}/> : null
            }
            {   /* istanbul ignore next */
                showBidButtonModal ? <BidButtonModal closeAction={finishBid}
                                                     auctionDetails={openAuction}
                                                     highBid={openAuction.high_bid ? openAuction.high_bid : openAuction.start_price}
                                                     currentTime={currentTime}
                                                     endTime={openAuction["end_time"]}/> : null
            }
            {   /* istanbul ignore next */
                showProxyModal ? <BidProxyModal closeAction={finishProxy}
                                                auctionDetails={openAuction}
                                                highBid={openAuction.high_bid ? openAuction.high_bid : openAuction.start_price}
                                                currentTime={currentTime}
                                                endTime={openAuction["end_time"]}/> : null
            }
        </View>
    );
};

HomeScreen.propTypes = {
    // Defines the interval between refreshes of all active auctions on the lane.
    refreshIntervalMs: PropTypes.number,
    // Defines the interval between updates to the timers.
    timerIntervalMs: PropTypes.number,
    navigation: PropTypes.object
};

HomeScreen.defaultProps = {
    refreshIntervalMs: 3000,
    timerIntervalMs: 1000
};

export default HomeScreen;
