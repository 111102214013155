import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import AcvTextInput from "../../components/textInput";
import {useTranslation} from "react-i18next";
import {buildStyles} from "./styles";
import {useColors} from "../../store/whiteLabelConfig/util";
import {pushMeasurement} from "../../utils/telemetry";
import {useToastMessaging} from "../../utils/messaging";
import PrimaryButton from "../../components/primaryButton";
import HoverableButton from "../../components/hoverableButton";
import {Text, View} from "react-native";
import PropTypes from "prop-types";
import * as api from "../../apiClients/auth";
import {isValidEmail} from "../../utils/validation";


const ForgotPasswordScreen = (props) => {
    const toast = useToastMessaging();
    const {t} = useTranslation();

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    // Disable 'Request Password Reset' button
    const [disabled, setDisabled] = useState(false);

    const updateEmail = useCallback(email => setEmail(email), [email]);
    const styles = buildStyles(useColors());

    useEffect(() => {
        // Disable the submit button if validation failed
        const isEmail = isValidEmail(email);
        setDisabled(!isEmail);
    }, [email]);

    const requestResetPassword = useCallback(async () => {
        try{
            setLoading(true);
            await api.resetPassword(email);
        }
        finally {
            setLoading(false);

            const title = t("success");
            const errorMessage = t("resetPasswordEmailSent");
            toast.showSuccess(title, errorMessage);

            pushMeasurement('requestResetPassword', {email: updateEmail});
        }
    }, [email, toast]);

    const returnLogin = useCallback(() => {
        // If there is previous page, then goBack
        if(props.navigation.canGoBack()){
            props.navigation.goBack();
        }
        // If user access this page directly via url without a previous page, then directly navigate to 'Login'
        else{
            props.navigation.push('login');
        }
    }, []);

    return (
        <View style={styles.container}>
            <View style={styles.forgotPasswordContainer}>
                <View style={styles.formContainer}>
                    <Text style={styles.title}>{t('resetPassword')}</Text>
                    <Text style={[styles.marginTop, styles.description]}>{t('resetPasswordDesc')}</Text>
                </View>
                <View style={styles.formContainer}>
                    <AcvTextInput
                        autoFocus={true}
                        autoCapitalize="none"
                        label={t("emailAddress")}
                        keyboardType="email-address"
                        testID="requested-email"
                        onChangeText={updateEmail}
                        value={email}/>
                    <PrimaryButton
                        title={t("requestResetPassword")}
                        testID="request-reset-password"
                        disabled={loading || disabled}
                        onPress={requestResetPassword}/>
                </View>

                <View style={styles.marginTop}>
                    <HoverableButton
                        testID={"return-login"}
                        title={t("returnToLogin")}
                        onPress={returnLogin}
                    />
                </View>
            </View>
        </View>);
};

ForgotPasswordScreen.propTypes = {
    navigation: PropTypes.object
};

export default ForgotPasswordScreen;
