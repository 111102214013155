import {StyleSheet, Text, View} from "react-native";
import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import PrimaryButton from "../primaryButton";
import {getCurrency} from "locale-currency";
import {useColors, useConfig} from "../../store/whiteLabelConfig/util";

const AuctionActionContainer = (props) => {

    const {t} = useTranslation();
    let currentBidText = t('currentBid');
    const bidIncrement = 100;
    const locale = useConfig().country;
    const bidDisabled = props.isHighBidder || !props.isBidPermitted || props.isAuctionEnded;
    const proxyDisabled = !props.isBidPermitted || props.isAuctionEnded;
    let colors = useColors();
    let bidColor = colors.text["text-primary-color"];
    if (props.isHighBidder && props.isActive) {
        bidColor = "#00A878";
    } else if (props.isOutBid && props.isActive) {
        bidColor = "#FFC000";
    }

    const localSetting = {
        style: 'currency',
        currency: getCurrency(locale),
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    };
    let proxyAmount;
    if (props.proxyAmount) {
        proxyAmount = props.proxyAmount.toLocaleString(locale, localSetting);
    }

    let currentBid = props.highBid.toLocaleString(locale, localSetting);
    const floorPrice = props.floorPrice.toLocaleString(locale, localSetting);
    const reservePriceTitle = t('reservedPrice');
    const highestBidTitle = t('highestBid');
    const proxyButtonTitle = proxyAmount ? t('editProxy') : t('setProxy');
    const bidButtonTitle = `${t('bid')} + ${bidIncrement.toLocaleString(locale, localSetting)}`;

    const styles = buildStyles(colors);
    const proxyPrefix = t('Proxy');
    return (
        <View style={styles.bidProxyContainer}>
            {props.isActive ?
                (<>
                    <Text style={styles.currentBidHeader}>{currentBidText}</Text>
                    <Text style={[styles.currentBid, {color: bidColor}]}>{currentBid}</Text>
                    {proxyAmount ? <Text style={styles.currentProxy}>{`${proxyPrefix}: ${proxyAmount}`}</Text> : null}
                    {props.showBidButton &&
                        <>
                            <PrimaryButton testID="btn-bid" style={styles.bidButton} title={bidButtonTitle} padding={10}
                                           disabled={bidDisabled} onPress={props.onBidButtonShow}/>
                            <PrimaryButton testID="btn-proxy" style={styles.bidButton} title={proxyButtonTitle}
                                           padding={10} disabled={proxyDisabled} onPress={props.onProxyButtonShow}/>
                        </>
                    }
                </>) : (
                    <>
                        <View style={styles.statusAmountContainer}>
                            <Text style={styles.endedStatusHeader}>{highestBidTitle}</Text>
                            <Text style={[styles.currentBid, {color: bidColor}]}>{currentBid}</Text>

                        </View>
                        <View style={styles.statusAmountContainer}>
                            <Text style={styles.endedStatusHeader}>{reservePriceTitle}</Text>
                            <Text style={[styles.currentBid, {color: bidColor}]}>{floorPrice}</Text>
                        </View>
                    </>
                )}
        </View>
    );
};

const buildStyles = colors => StyleSheet.create({
    bidButton: {
        marginTop: 10
    },
    bidProxyContainer: {
        maxWidth: 154,
        backgroundColor: colors.background["background-1-color"],
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    currentBidHeader: {
        textTransform: "uppercase",
        color: colors.text["text-tertiary-color"],
        fontFamily: "Roboto_500Medium",
        fontSize: 10,
        lineHeight: 16,
        letterSpacing: 1.5
    },
    statusAmountContainer: {
        alignItems: "center",
        paddingBottom: 15
    },
    endedStatusHeader: {
        textTransform: "uppercase",
        color: colors.text["text-tertiary-color"],
        fontFamily: "Roboto_500Medium",
        fontSize: 10,
        lineHeight: 14,
    },
    currentBid: {
        fontFamily: "Roboto_500Medium",
        letterSpacing: 0.15,
        fontSize: 20,
        lineHeight: 30
    },
    currentProxy: {
        marginTop: 5,
        color: '#00A878',
    }

});
AuctionActionContainer.propTypes = {
    isHighBidder: PropTypes.bool,
    isBidPermitted: PropTypes.bool,
    isProxyPermitted: PropTypes.bool,
    isAuctionEnded: PropTypes.bool,
    isOutBid: PropTypes.bool,
    highBid: PropTypes.number,
    proxyAmount: PropTypes.number,
    floorPrice: PropTypes.number,
    isActive: PropTypes.bool,
    showBidButton: PropTypes.bool,
    onBidButtonShow: PropTypes.func,
    onProxyButtonShow: PropTypes.func,
};
export default AuctionActionContainer;
