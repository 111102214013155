import * as React from "react";
import Svg, {Path} from "@cantoo/rn-svg";
import PropTypes from "prop-types";
import {useColors} from "../../store/whiteLabelConfig/util";

const Gavel = (props) => {

    let {color, widthHeight} = props;
    const defaultColor = useColors().greys["ui-grey-x-dark"];
    /* istanbul ignore next */
    if(!color) {
        color = defaultColor;
    }
    return (
        <Svg
            aria-hidden="true"
            data-prefix="fas"
            data-icon="gavel"
            xmlns="http://www.w3.org/2000/svg"
            width={widthHeight}
            height={widthHeight}
            viewBox="0 0 512 512"
            style={{ transform: [{ rotateY: '180deg' }] }}
        >
            <Path
                fill={color}
                d="m504.971 199.362-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"
            />
        </Svg>
    );
};

Gavel.propTypes = {
    widthHeight: PropTypes.number,
    color: PropTypes.string,
};

Gavel.propDefaults = {
    widthHeight: 24,
};
export default Gavel;