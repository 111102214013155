import * as React from "react";
import Svg, {Path} from "@cantoo/rn-svg";
import PropTypes from "prop-types";
import {useColors} from "../../store/whiteLabelConfig/util";

const HighestBidder = (props) => {
    let {color, widthHeight} = props;
    const defaultColor = useColors().greys["ui-grey-x-dark"];
    /* istanbul ignore next */
    if(!color) {
        color = defaultColor;
    }
    return (
        <Svg
            testID="highestBidder"
            data-icon="highestBidder"
            width={widthHeight}
            height={widthHeight}
            viewBox={`0 0 24 24`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M18.967 19.67a.996.996 0 0 0 0-1.41l-6.675-6.675a.996.996 0 0 0-1.41 0L4.207 18.26a.996.996 0 1 0 1.41 1.41l5.975-5.965 5.965 5.965c.39.39 1.03.38 1.41 0Z"
                fill={color}
            />
            <Path
                d="M18.967 12.377a.996.996 0 0 0 0-1.41l-6.675-6.675a.996.996 0 0 0-1.41 0l-6.675 6.675a.996.996 0 1 0 1.41 1.41l5.975-5.965 5.965 5.965c.39.39 1.03.38 1.41 0Z"
                fill={color}
            />
        </Svg>);
};

HighestBidder.propTypes = {
    widthHeight: PropTypes.number,
    color: PropTypes.string,
};

HighestBidder.propDefaults = {
    widthHeight: 24,
};

export default HighestBidder;