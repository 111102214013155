import {Text, View} from "react-native";
import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {verifyLocaleAndTranslate} from "../../translations";

const VehicleDetailsRow = (props) => {
    const {t} = useTranslation();
    const {item, styles} = props;

    return (
        <View testID={`vehicle-details-row`} style={styles.rowContainer}>
            <View style={styles.cellKey}>
                <Text style={styles.textKey}>{verifyLocaleAndTranslate(item.name, t)}:</Text>
            </View>
            <View style={styles.cellValue}>
                <Text style={styles.textValue}>{verifyLocaleAndTranslate(item.value, t)}</Text>
            </View>
        </View>
    );
};

VehicleDetailsRow.propTypes = {
    item: PropTypes.object,     // Object vehicle detail including name and value
    styles: PropTypes.object    // Object containing styles
};

export default VehicleDetailsRow;
