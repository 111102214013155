import React, {useCallback} from 'react';
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import HomeScreen from "../screens/home";
import VDPScreen from "../screens/vdp";
import {HeaderBackButton} from "@react-navigation/elements";

const VehicleFlow = () => {
    const Stack = createNativeStackNavigator();

    const navigateBack = useCallback( ({navigation}) => {
        return () => navigation.goBack();
    }, []);

    const setVDPScreenOptions = useCallback(({navigation}) => {
        return ({
          headerShown: true,
          title: "",
          headerBackTitle: "",
          headerLeft: () => (
              <HeaderBackButton testID="backButton" onPress={navigateBack({navigation})} />)
        } );
    }, []);

    return (
        <Stack.Navigator
            screenOptions={{headerShown: false}}
            initialRouteName="home"
            testID="vehicle-stack-navigator"
        >
            <>
                <Stack.Screen name="live" component={HomeScreen}
                              options={{title: null}}/>
                <Stack.Screen name="vdp" component={VDPScreen}
                              options = {setVDPScreenOptions}
                />
            </>
        </Stack.Navigator>
    );
};

export default VehicleFlow;
