import React, {useCallback} from 'react';
import {StyleSheet, View} from "react-native";
import PropTypes from "prop-types";
import TimerIcon from "./icons/timerIcon";
import TimerText from "./timerText";
import {useColors} from "../store/whiteLabelConfig/util";

const Timer = (props) => {

    let colors = useColors();
    const colorTimeShort = colors.text["text-error-color"];
    const colorNormal = props.colorNormal ? props.colorNormal : colors.text["text-primary-color"];
    const secondsRemaining = Math.max(props.endTime - props.currentTime, 0);

    const getTimerColor = useCallback(() => {
        if (secondsRemaining > 60 || secondsRemaining < 1) {
            return colorNormal;
        } else {
            return colorTimeShort;
        }
    }, [secondsRemaining]);

    const timerText = {
        ...props.style,
        paddingLeft: 3,
        color: getTimerColor()
    };

    return (
        <View style={styles.timerContainer}>
            {props.showIcon && <TimerIcon height={props.iconSize} color={getTimerColor()}/>}
            <TimerText testIDSuffix={props.testIDSuffix} style={timerText} endTime={props.endTime} currentTime={props.currentTime}/>
        </View>);
};

const styles = StyleSheet.create({
    timerContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center"
    },
});

Timer.propTypes = {
    testIDSuffix: PropTypes.string,
    style: PropTypes.object,
    endTime: PropTypes.number,
    currentTime: PropTypes.number,
    colorNormal: PropTypes.string,
    iconSize: PropTypes.number,
    showIcon: PropTypes.bool,
};

Timer.defaultProps = {
    testIDSuffix: '',
    style: {
        alignSelf: "center",
        fontFamily: "Roboto_500Medium",
        letterSpacing: 0.25,
        fontSize: 14,
        lineHeight: 21
    },
    iconSize: 13,
    showIcon: true,
};
export default Timer;