import {StyleSheet} from "react-native";
import {globalHeading, globalText} from "../../fonts";

export const buildStyles = (colors) => StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        backgroundColor: colors.greys["ui-white-color"]
    },
    privacyContainer: {
        marginTop: 10,
        padding: 10,
        paddingLeft: "10%",
        elevation: 4,
        width: "80%",
        maxWidth: 900,
        alignItems: "flex-start",
        alignSelf: "flex-start"
    },

    title: {
        paddingVertical: 10,
        ...globalHeading["h5-medium"]
    },
    paragraph: {
        ...globalText["body-sm-regular"],
        paddingBottom: 10,
    },
    line: {
        ...globalText["body-sm-regular"],
        paddingLeft: 10,
    },
    bullit: {
        ...globalText["body-sm-regular"],
        paddingLeft: 15,
        paddingBottom: 4,
    },
    link: {
        color: colors.text["text-link-color"]
    },
    termsContainer: {
        paddingTop: 15,
    },
    paragraphTitle: {
        ...globalText["body-sm-bold"],
        paddingRight: 5,
    }
});