import React from 'react';
import {StyleSheet, Text} from "react-native";
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {Ionicons} from '@expo/vector-icons';
import VehicleFlow from "./vehicleStackNavigator";
import SettingsNavigation from "./settingsNavigator";
import {useTranslation} from 'react-i18next';
import {useColors, useConfig} from "../store/whiteLabelConfig/util";
import {SvgXml} from "@cantoo/rn-svg";
import {globalText} from "../fonts";
import {ListingsScreen} from "../screens/listings";
import VehicleIcon from "../components/icons/vehicle";
import {getCodeFlag} from "../config/config";

const Tab = createBottomTabNavigator();

export default function TabBarFlow() {
    const logo = useConfig().logo;
    const enableInventoryTab = getCodeFlag("showInventory");
    const colors = useColors();
    const styles = buildStyles(colors);
    const {t} = useTranslation();
    const marketplace = t('marketplace');
    const inventory = t('Inventory');
    const menu = 'menu';
    return (
        <Tab.Navigator
            screenOptions={{headerShown: false, inactiveTintColor: "grey", tabBarLabelPosition: "below-icon"}}
        >
            <Tab.Screen name="home" component={VehicleFlow}
                        options={{
                            tabBarLabel: ({focused}) => {
                                return <Text style={styles.tabText}>{focused ? marketplace : ""}</Text>;
                            },
                            tabBarIcon: () => (
                                <SvgXml height="70px" width="70px" xml={logo}/>
                            ),
                        }}/>
            {enableInventoryTab && <Tab.Screen name="listings" component={ListingsScreen}
                        options={{
                            tabBarLabel: ({focused}) => {
                                return <Text style={styles.tabText}>{focused ? inventory : ""}</Text>;
                            },
                            tabBarIcon: () => (
                                <VehicleIcon color={colors.greys["ui-grey-dark"]} height={24}/>
                            ),
                            tabBarTestID: "tabInventory"
                        }}/>}
            <Tab.Screen name="Menu" component={SettingsNavigation}
                        options={{
                            tabBarLabel: ({focused}) => {
                                return <Text style={styles.tabText}>{focused ? menu : ""}</Text>;
                            },
                            tabBarIcon: ({color, size}) => (
                                <Ionicons name="menu" color={color} size={size}/>
                            ),
                            tabBarTestID: "tabMenu"
                        }}/>
        </Tab.Navigator>
    );
}

const buildStyles = colors => StyleSheet.create({

    tabText: {
        ...globalText["body-sm-regular"],
        color: colors.primaryShades["primary-color-base"],
    }
});
