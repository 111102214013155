import * as React from "react";
import Svg, {Path} from "@cantoo/rn-svg";
import PropTypes from "prop-types";
import {useColors} from "../../store/whiteLabelConfig/util";

const OutbidIcon = (props) => {
    let {color, widthHeight} = props;
    const defaultColor = useColors().greys["ui-grey-x-dark"];
    /* istanbul ignore next */
    if(!color) {
        color = defaultColor;
    }
    return (
        <Svg
            testID="outBid"
            viewBox="0 0 24 24"
            width={widthHeight}
            height={widthHeight}
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <Path
                d="M4.207 4.292a.996.996 0 0 0 0 1.41l6.675 6.675c.39.39 1.02.39 1.41 0l6.675-6.675a.996.996 0 1 0-1.41-1.41l-5.975 5.965-5.965-5.965c-.39-.39-1.03-.38-1.41 0Z"
                fill={color}/>
            <Path
                d="M4.207 11.585a.996.996 0 0 0 0 1.41l6.675 6.675c.39.39 1.02.39 1.41 0l6.675-6.675a.996.996 0 1 0-1.41-1.41l-5.975 5.965-5.965-5.965c-.39-.39-1.03-.38-1.41 0Z"
                fill={color}/>
        </Svg>);
};
OutbidIcon.propTypes = {
    widthHeight: PropTypes.number,
    color: PropTypes.string,
};

OutbidIcon.propDefaults = {
    widthHeight: 24,
};
export default OutbidIcon;