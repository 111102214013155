// Excluded because we need to run with the jsdom environment for jest.
/* istanbul ignore file */
import React from 'react';
import {StyleSheet, View} from "react-native";
import Modal from 'modal-enhanced-react-native-web';
import PropTypes from "prop-types";


const ACVModal = (props) => {
    const {closeAction, children} = props;

    return (
        <View style={styles.webModalContainer}>
            <Modal
                isVisible={true}
                onBackButtonPress={closeAction}
                onBackdropPress={closeAction}
                style={styles.webModal}>
                {children}
            </Modal>
        </View>
    );
};

ACVModal.propTypes = {
    closeAction: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

const styles = StyleSheet.create({
    webModal: {
        alignSelf: "center",
        justifyContent: 'center',
    },
    webModalContainer: {
        height: "100%",
        width: "100%",
        position: "absolute",
        overflow: "hidden"
    }
});

export default ACVModal;