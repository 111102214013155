import * as React from "react";
import Svg, {Path} from "@cantoo/rn-svg";
import PropTypes from "prop-types";

const ErrorIcon = (props) => {
    const {widthHeight, color} = props;
    return (
        <Svg
            width={widthHeight}
            height={widthHeight}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0Zm0 11c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1Zm1 4H9v-2h2v2Z"
                fill={color}
            />
        </Svg>
    );
};

ErrorIcon.propTypes = {
    widthHeight: PropTypes.number,
    color: PropTypes.string,
};

ErrorIcon.defaultProps = {
    widthHeight: 12,
    color: "#000"
};
export default ErrorIcon;
