import React, {useCallback} from 'react';
import {Linking, StyleSheet, View} from "react-native";
import {SafeAreaView} from "react-native-safe-area-context";
import {getConfig} from "../../config/config";
import {getStore} from "../../store";
import {deleteSession} from "../../store/auth/actions";
import {useTranslation} from 'react-i18next';
import PropTypes from "prop-types";
import LanguageSelector from "../languageSelector";
import {getPlatform, getVersion} from "../../utils/platform";
import {useColors} from "../../store/whiteLabelConfig/util";
import {pushMeasurement} from "../../utils/telemetry";
import MenuItem from "../../components/menuItem";

const MenuScreen = (props) => {
    const {onMouseLeave} = props;
    const logout = useCallback(() => {
        // Record the measurement first to know who logged out.
        pushMeasurement("logout");
        getStore().dispatch(deleteSession());
    }, []);
    const {t} = useTranslation();
    const bundleVersion = getVersion();
    const baseUrl = getConfig().openAutoUrl;
    const styles = buildStyles(useColors());
    const version = `${t('version')}: ${bundleVersion}`;
    const buildOpenUrlCallback = (url) => {
        return async () => {
            if (getPlatform() === 'web') {
                window.open(url, "_blank");
            } else {
                await Linking.openURL(url);
            }
        };
    };
    const openPrivacy = useCallback(buildOpenUrlCallback(`${baseUrl}/privacy`), []);
    const openTandCs = useCallback(buildOpenUrlCallback(`${baseUrl}/legal`), []);
    return (
        <View style={styles.container} testID="menuScreenView" onMouseLeave={onMouseLeave}>
            <SafeAreaView style={styles.safeContainer}>
                <MenuItem title={version}/>
                <MenuItem title={t('common:privacyPolicy')}
                          action={openPrivacy}
                          actionName={"openPrivacy"}
                          isLink={true}/>
                <MenuItem title={t('common:TermsAndConditions')}
                          action={openTandCs}
                          actionName={"openTandCs"}
                          isLink={true}/>
                {getPlatform() !== 'ios' ? <LanguageSelector/> : null}
                <MenuItem title={t('common:logout')} action={logout} actionName={"logout"}/>
            </SafeAreaView>
        </View>
    );
};

const buildStyles = colors => StyleSheet.create({
    container: {
        flex: 1,
        elevation: 1
    },
    safeContainer: {
        width: "100%",
        height: "100%",
        alignItems: "flex-start",
        backgroundColor: colors.background["background-3-color"]
    },
});

MenuScreen.propTypes = {
    navigation: PropTypes.object,
    onMouseLeave: PropTypes.func,
};
export default MenuScreen;
