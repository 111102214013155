import {Image, StyleSheet, Text, View} from "react-native";
import {globalText} from "../../fonts";
import React from "react";
import LocationIcon from "../icons/location";
import PropTypes from "prop-types";
import AuctionStatus from "./auctionStatus";
import StatusPillSmall from "../statusPillSmall";
import {useTranslation} from "react-i18next";
import {getCurrency} from "locale-currency";
import {useColors, useConfig} from "../../store/whiteLabelConfig/util";

const AuctionSmall = (props) => {
    const auctionDetails = props.item;
    const testIDSuffix = props.item.id ? props.item.id.toString() : '';
    const {t} = useTranslation();
    const locale = useConfig().country;
    let dist = t('km');

    if (locale === 'en-US'){
        dist = t('Miles');
    }

    let colors = useColors();
    let bidColor = colors.text["text-primary-color"];
    if (props.isHighBidder) {
        bidColor = "#00A878";
    } else if (props.isOutBid) {
        bidColor = "#FFC000";
    }

    const doorsDrivetrainTrim = `${auctionDetails.doors}d ${auctionDetails.drivetrain} ${auctionDetails.trim}`;
    let pickupLocation = ` ${auctionDetails.pickup_city}, ${auctionDetails.pickup_region}`;
    let odometer = `${auctionDetails.odometer.toLocaleString(locale)} ${dist}`;
    let currentBid = `${props.highBid.toLocaleString(locale, {style: 'currency', currency: getCurrency(locale),
        minimumFractionDigits: 0, maximumFractionDigits: 0})}`;
    let yearMakeModel = `${auctionDetails.year} ${auctionDetails.make} ${auctionDetails.model}`;

    const styles = buildStyles(colors);
    return (
        <View testID={"auction-row-small"} style={styles.container}>
            <View style={styles.imageContainer}>
                <Image
                    source={{uri: auctionDetails.primary_image.acv_thumbnail}}
                    style={styles.image}/>
            </View>
            <View testID={"details-container-small"} style={styles.detailsContainer}>
                <View style={{flex: 1}}>
                    <Text
                        style={styles.yearMakeModel}>{yearMakeModel}</Text>
                    <Text
                        style={styles.doorsDrivetrainTrim}>{doorsDrivetrainTrim}</Text>
                    <Text
                        style={styles.odometer}>{odometer}</Text>
                    <View style={styles.locationContainer}>
                        <LocationIcon color={colors.greys["ui-grey-dark"]}/>
                        <Text
                            style={styles.locationText}>{pickupLocation}</Text>
                    </View>
                </View>
                <View style={styles.statusContainer}>
                    <AuctionStatus testIDSuffix={testIDSuffix}
                                   status={auctionDetails.status}
                                   currentTime={auctionDetails.currentTime}
                                   endTime={auctionDetails["end_time"]}/>
                    <View style={styles.badgesBidContainer}>
                        <View style={styles.badgesContainer}>
                            {props.isNoReserve && <StatusPillSmall backgroundColor={colors.greys["ui-grey-x-dark"]}
                                                                   iconType={"reserve_met"}/>}
                            {props.isReserveMet && <StatusPillSmall backgroundColor={colors.greys["ui-grey-x-dark"]}
                                                                    iconType={"reserve_met"}/>}
                            {props.isHighBidder && <StatusPillSmall backgroundColor={colors.text["text-success-color"]}
                                                                    iconType={"highest_bidder"}/>}
                            {props.isOutBid && <StatusPillSmall backgroundColor={"#FFC000"}
                                                                iconType={"out_bid"}/>}
                        </View>
                        <Text style={[styles.highBid, {color: bidColor}]}>{currentBid}</Text>
                    </View>
                </View>
            </View>
        </View>
    );
};

const buildStyles = colors => StyleSheet.create({
    container: {
        flexDirection: "row",
        height: 130,
        borderBottomWidth: 2,
        borderColor: colors.greys["ui-grey-base-dark"],
        backgroundColor: colors.background["background-3-color"],
        paddingBottom: 15
    },
    imageContainer: {
        flex: 1,
    },
    image: {
        width: "100%",
        height: "100%",
        resizeMode: "contain"
    },
    detailsContainer: {flex: 2, paddingLeft: 12, flexDirection: "column"},
    yearMakeModel: {
        fontFamily: "Roboto_500Medium",
        letterSpacing: 0.1,
        fontSize: 14,
        lineHeight: 18,
        color: colors["ui-grey-x-dark"]
    },
    doorsDrivetrainTrim: {
        ...globalText["body-sm-regular"],
        color: colors.text["text-tertiary-color"]
    },
    odometer: {
        ...globalText["body-sm-regular"],
        color: colors.text["text-tertiary-color"],
    },
    locationContainer: {flexDirection: "row", alignItems: "center"},
    locationText: {
        ...globalText["body-sm-regular"],
        color: colors.text["text-tertiary-color"],
    },
    statusContainer: {
        flexDirection: "row",
        alignItems: "flex-end"
    },
    badgesBidContainer: {
        flex: 1,
        flexDirection: "column",
        alignItems: "flex-end"
    },
    badgesContainer: {
        flexDirection: "row",
        paddingBottom: 4,
    },
    highBid: {
        fontFamily: "Roboto_500Medium",
        letterSpacing: 0.1,
        fontSize: 14,
        lineHeight: 14,
    },
});

AuctionSmall.propTypes = {
    item: PropTypes.object,
    highBid: PropTypes.number,
    isHighBidder: PropTypes.bool,
    isReserveMet: PropTypes.bool,
    isNoReserve: PropTypes.bool,
    isOutBid: PropTypes.bool,
};
export default AuctionSmall;
