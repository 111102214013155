import {StyleSheet, Text, View} from "react-native";
import React from "react";
import {globalHeading} from "../../fonts";
import PropTypes from "prop-types";
import useLargeFormFactorCheck from "../../utils/responsive";
import {useTranslation} from 'react-i18next';
import {useColors} from "../../store/whiteLabelConfig/util";
import IconButton from "../iconButton";
import RefreshIcon from "../icons/refreshIcon";

const AuctionHeader = (props) => {
    let numberCars = "0";
    if (props.cars) {
        numberCars = props.cars.length;
    }
    const {t} = useTranslation();
    const isLargeScreen = useLargeFormFactorCheck();
    const styles = buildStyles(useColors());

    const onRefresh = props.onRefresh;

    if (isLargeScreen) {
        return (<View style={styles.largeContainer}>
                <Text testID="number-of-cars-large"
                      style={[globalHeading["subtitle-sm-medium"], styles.smallAuctionsText]}
                >{numberCars} {t('results')}</Text>

                <View style={styles.refreshButton}>
                    <IconButton testID={'refresh-btn'} onPress={onRefresh}>
                        <RefreshIcon widthHeight={13}/>
                    </IconButton>
                </View>
            </View>);
    } else {
        return (<View style={styles.smallContainer}>
                    <Text style={[styles.smallAuctionsText, globalHeading["subtitle-sm-medium"]]}>{t('liveAuctions')}</Text>

                    <Text testID="number-of-cars-small"
                          style={[globalHeading["subtitle-sm-medium"], styles.smallAuctionsText, {marginLeft: "auto"}]}
                    >{numberCars} {t('results')}</Text>
                </View>
        );
    }
};

const buildStyles = colors => StyleSheet.create({
    smallContainer: {
        padding: 12,
        backgroundColor: colors.background["background-2-color"],
        flexDirection: "row",
        borderBottomColor: colors.greys["ui-grey-base-dark"],
        borderBottomWidth: 2,
        alignItems: "center"
    },
    smallAuctionsText: {color: colors.text["text-tertiary-color"]},

    largeContainer: {
        padding: 12,
        flexDirection: "row",
        alignItems: "center"
    },

    refreshButton: {
        marginLeft: 5,
        marginRight: 5
    }
});

AuctionHeader.propTypes = {
    cars: PropTypes.array,
    onRefresh: PropTypes.func
};

export default AuctionHeader;
