import * as React from "react";
import Svg, {ClipPath, Path} from "@cantoo/rn-svg";
import PropTypes from "prop-types";
import {useColors} from "../../store/whiteLabelConfig/util";

const Eye = (props) => {

    let {color, widthHeight} = props;
    const defaultColor = useColors().greys["ui-grey-x-dark"];
    /* istanbul ignore next */
    if(!color) {
        color = defaultColor;
    }

    return (
        <Svg viewBox="0 0 150.8 93.9"
             xmlns="http://www.w3.org/2000/svg"
             width={widthHeight}
             height={widthHeight}>
            <ClipPath id="a">
                <Path
                    d="M110.8 73.5c-10.9 6.6-22.7 9.9-35.4 9.9S50.9 80.1 40 73.5 20.1 58 12.8 46.9c8.3-12.8 18.6-22.4 31-28.8-3.3 5.6-5 11.8-5 18.3 0 10 3.6 18.6 10.7 25.8S65.4 73 75.4 73s18.6-3.6 25.8-10.7c7.1-7.1 10.7-15.7 10.7-25.8 0-6.6-1.7-12.7-5-18.3 12.4 6.4 22.8 15.9 31 28.8-7.1 11.1-16.2 19.9-27.1 26.5zM58 19c4.9-4.9 10.7-7.3 17.5-7.3 1.1 0 2 .4 2.8 1.1.8.8 1.1 1.7 1.1 2.8s-.4 2-1.1 2.8c-.8.8-1.7 1.1-2.8 1.1-4.7 0-8.7 1.7-12 5s-5 7.3-5 12c0 1.1-.4 2-1.1 2.8-.8.8-1.7 1.1-2.8 1.1s-2-.4-2.8-1.1c-.8-.8-1.1-1.7-1.1-2.8 0-6.8 2.4-12.6 7.3-17.5zm88.8 22.3c-7.6-12.4-17.8-22.4-30.7-30C103.3 3.8 89.7 0 75.4 0S47.6 3.8 34.7 11.3C21.9 18.8 11.6 28.8 4 41.3c-1.1 1.9-1.6 3.8-1.6 5.6s.5 3.7 1.6 5.6C11.7 65 21.9 75 34.7 82.5c12.8 7.6 26.4 11.3 40.7 11.3s27.8-3.8 40.7-11.3c12.8-7.5 23.1-17.5 30.7-30 1.1-1.9 1.6-3.8 1.6-5.6s-.5-3.7-1.6-5.6z"/>
            </ClipPath>
            <Path clipPath="url(#a)" d="M-2.6-5h156V98.9h-156z" fill={color}/>
        </Svg>
    );
};
Eye.propTypes = {
    widthHeight: PropTypes.number,
    color: PropTypes.string,
};

Eye.propDefaults = {
    widthHeight: 24,
};
export default Eye;
