import Timer from "../timer";
import React from "react";
import {StyleSheet, Text} from "react-native";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useColors} from "../../store/whiteLabelConfig/util";

const AuctionStatus = (props) => {

    const { t } = useTranslation();

    const getAuctionStatus = () => {
        switch (props.status) {
            case 'active':
                return t('ending');
            case 'sold':
            case 'ended_accepted':
                return t('sold');
            default:
                return t('unsold');
        }
    };

    const styles = props.style;
    const colors = useColors();
    if(!styles.color) {
        styles.color = colors.text["text-primary-color"];
    }

    const style = StyleSheet.create({
        statusText: {
            ...styles,
            color: props.colorNormal
        }
    });

    return props.status === 'active' && props.endTime > props.currentTime ? (
        <Timer
            testIDSuffix={props.testIDSuffix}
            style={styles}
            iconSize={props.iconSize}
            colorNormal={props.colorNormal}
            currentTime={props.currentTime}
            endTime={props.endTime}
            showIcon={props.showIcon}
        />) : (
        <Text testID={`auction-status-${props.testIDSuffix}`} style={style.statusText}>{getAuctionStatus()}</Text>);
};

AuctionStatus.propTypes = {
    testIDSuffix: PropTypes.string,
    style: PropTypes.object,
    status: PropTypes.string.isRequired,
    endTime: PropTypes.number.isRequired,
    currentTime: PropTypes.number.isRequired,
    colorNormal: PropTypes.string,
    iconSize: PropTypes.number,
    showIcon: PropTypes.bool,
};

AuctionStatus.defaultProps = {
    testIDSuffix: '',
    style: {
        marginLeft: 3,
        alignSelf: "center",
        fontFamily: "Roboto_500Medium",
        letterSpacing: 0.25,
        fontSize: 14,
        lineHeight: 21,
    },
    iconSize: 13,
    showIcon: true
};
export default AuctionStatus;