import React, {useCallback, useState} from 'react';
import {StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import {globalHeading, globalText} from "../../fonts";
import CancelIcon from "../icons/cancelIcon";
import ACVModal from "../modal";
import TimerText from "../timerText";
import PropTypes from "prop-types";
import {useTranslation} from 'react-i18next';
import PrimaryButton from "../primaryButton";
import TertiaryButton from "../tertiaryButton";
import {getCurrency} from "locale-currency";
import {useColors, useConfig} from "../../store/whiteLabelConfig/util";
import useLargeFormFactorCheck from "../../utils/responsive";
import BidSlider from "../slider/bidSlider";

const BidProxyModal = (props) => {

    const {closeAction, auctionDetails, highBid, currentTime, endTime} = props;
    const locale = useConfig().country;
    const proxyIncrement = 50;

    const isDesktopOrLaptop = useLargeFormFactorCheck();

    const [proxyInput, setProxyInput] = useState("");
    const [proxyConfirmation, setProxyConfirmation] = useState("");

    const proxyMaximum = 10000000;

    const [confirmationError, setConfirmationError] = useState(null);
    const [inputError, setInputError] = useState(null);

    const [sliderValue, setSliderValue] = useState([0]);
    const handleSliderChange = useCallback(async (value) => {
        setSliderValue(value);
        if (value[0] >= 10) {

            setInputError(null);
            setConfirmationError(null);

            const highAmount = auctionDetails.proxy_bid ? auctionDetails.proxy_bid.amount : highBid;
            const proxyValue = parseCurrency(proxyInput);
            const confirmationValue = parseCurrency(proxyConfirmation);
            if (proxyValue !== confirmationValue) {
                setConfirmationError(t('errorMismatch'));
            } else if (proxyValue <= highAmount) {
                setInputError(t('errorLowProxy'));
            } else if (proxyValue % proxyIncrement !== 0) {
                setInputError(t('errorProxyIncrement'));
            } else {
                await closeAction(auctionDetails.id, String(proxyValue));
            }
        }
    }, [proxyConfirmation, proxyInput, inputError, confirmationError]);

    const handleProxyPress = useCallback(async () => {
        setInputError(null);
        setConfirmationError(null);

        const highAmount = auctionDetails.proxy_bid ? auctionDetails.proxy_bid.amount : highBid;
        const proxyValue = parseCurrency(proxyInput);
        const confirmationValue = parseCurrency(proxyConfirmation);
        if (proxyValue !== confirmationValue) {
            setConfirmationError(t('errorMismatch'));
        } else if (proxyValue <= highAmount) {
            setInputError(t('errorLowProxy'));
        } else if (proxyValue > proxyMaximum){
            setInputError(t('errorHighProxy'));
        } else if (proxyValue % proxyIncrement !== 0) {
            setInputError(t('errorProxyIncrement'));
        } else {
            await closeAction(auctionDetails.id, String(proxyValue));
        }
    }, [proxyConfirmation, proxyInput, inputError, confirmationError]);

    const colors = useColors();

    const {t} = useTranslation();
    const placeBid = t("common:setProxy");
    const placeBidSwipe = t("common:swipeProxy");
    const cancel = t("common:cancel");
    const styles = buildStyles(colors);
    const currencyOptions = {
        style: 'currency',
        currency: getCurrency(locale), minimumFractionDigits: 0, maximumFractionDigits: 0
    };

    const highBidText = highBid.toLocaleString(locale, currencyOptions);

    const parseCurrency = (text) => {
        const stripped = text.replace(/\D/g, "");
        return stripped ? parseInt(stripped) : '';
    };

    const updateProxy = useCallback(input =>
        setProxyInput(parseCurrency(input).toLocaleString(locale, currencyOptions)), [setProxyInput]);

    const updateProxyConfirmation = useCallback(input =>
        setProxyConfirmation(parseCurrency(input).toLocaleString(locale, currencyOptions)), [setProxyConfirmation]);

    return (
        <ACVModal closeAction={closeAction}>
            <View style={styles.modalContent}>
                <View style={styles.rowContainer}>
                    <Text style={styles.proxyTitle}>{t('proxyBid')}</Text>
                    <TouchableOpacity style={styles.cancel} testID={"cancelIcon"} onPress={closeAction}>
                        <CancelIcon color={useColors().text["text-secondary-color"]} widthHeight={12}/>
                    </TouchableOpacity>
                </View>
                <View style={styles.bidDetailsContainer}>
                    <Text style={styles.bidDetailsPrefix}>{`${t('currentBid')}: `}</Text>
                    <Text style={styles.bidDetailsValue}>{highBidText}</Text>
                </View>
                <View style={styles.bidDetailsContainer}>
                    <Text style={styles.bidDetailsPrefix}>{t('timeRemaining')}:</Text>
                    <TimerText
                        style={styles.bidDetailsValue}
                        currentTime={currentTime}
                        endTime={endTime}/>
                </View>
                <View style={styles.proxyDetailsRowContainer}>
                    <View style={styles.proxyDetailsContainer}>
                        <Text style={styles.proxyDetailsTitle}>{t("yourProxy")}</Text>
                        <TextInput
                            autoFocus={true}
                            testID={'proxy-input'}
                            keyboardType={"number-pad"}
                            style={styles.proxyDetailsTextInput}
                            onChangeText={updateProxy}
                            value={proxyInput}
                            maxLength={13}
                        />
                        {inputError ?
                            <Text testID={"proxy-input-error"} style={styles.errorText}>{inputError}</Text>
                            : <Text style={styles.proxyHelp}>{t("proxyMultiples")}</Text>}
                    </View>
                </View>
                <View style={styles.proxyDetailsRowContainer}>
                    <View style={styles.proxyDetailsContainer}>
                        <Text style={styles.proxyDetailsTitle}>{t("confirmProxyTitle")}</Text>
                        <TextInput
                            keyboardType={"number-pad"}
                            testID={'proxy-confirm'}
                            style={styles.proxyDetailsTextInput}
                            onChangeText={updateProxyConfirmation}
                            value={proxyConfirmation}
                            maxLength={13}
                        />
                        {confirmationError ?
                            <Text testID={"proxy-confirmation-error"}
                                  style={styles.errorText}>{confirmationError}</Text>
                            : <Text style={styles.proxyHelp}>{t("confirmProxySubtitle")}</Text>}

                    </View>
                </View>

                {isDesktopOrLaptop ? (
                <View style={styles.buttonRowContainer}>
                    <TertiaryButton onPress={closeAction} title={cancel}/>
                    <PrimaryButton testID={"proxy-button-primary"} onPress={handleProxyPress} title={placeBid}/>
                </View>
                ) : (
                <View style={styles.buttonRowContainer}>
                    <BidSlider
                        sliderValue={sliderValue}
                        handleSliderChange={handleSliderChange} // eslint-disable-line react/jsx-no-bind
                        testID="proxySlider"
                        title={placeBidSwipe}
                    />
                </View>
                )}
            </View>
        </ACVModal>);
};

const buildStyles = colors => StyleSheet.create({
    proxyHelp: {
        ...globalText["body-sm-bold"],
        color: colors.text["text-tertiary-color"],
        paddingBottom: 15
    },
    bidDetailsPrefix: {
        ...globalText["body-sm-regular"],
        color: colors.text["text-tertiary-color"]
    },
    errorText: {
        color: colors.text["text-error-color"]
    },
    cancel: {
        color: colors.text["text-secondary-color"],
        ...globalText.button
    },
    cancelText: {
        paddingLeft: 10,
        color: colors.text["text-inverse-color"],
        ...globalText.button
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
    },
    proxyTitle: {
        ...globalHeading["h6-medium"],
        fontFamily: 'Roboto_700Bold',
        color: colors.text["text-primary-color"],
    },
    bidDetailsTitle: {
        ...globalHeading["subtitle-base-medium"],
        color: colors.text["text-tertiary-color"]
    },
    bidDetailsValue: {
        paddingLeft: 5,
        ...globalHeading["subtitle-base-medium"],
        fontFamily: 'Roboto_700Bold',
        color: colors.text["text-primary-color"],
    },
    proxyDetailsRowContainer: {
        flexDirection: "column",
        paddingTop: 18
    },
    proxyDetailsContainer: {
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingRight: 20
    },
    proxyDetailsTitle: {
        fontSize: 17,
        lineHeight: 20,
        fontFamily: 'Roboto_700Bold',
        color: colors.text["text-secondary-color"],
        paddingBottom: 5,
    },
    proxyDetailsTextInput: {
        fontSize: 32,
        lineHeight: 38,
        fontFamily: 'Roboto_700Bold',
        color: colors.text["text-secondary-color"],
        paddingVertical: 10,
        backgroundColor: colors.background["background-1-color"],
        borderColor: colors.background["background-1-color"],
        borderLeftWidth: 16,
        borderRightWidth: 16,
        borderTopWidth: 8,
        borderBottomWidth: 8,
        width: 300,
        height: 70,
        marginVertical: 8,
    },
    rowContainer: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: 7,
        alignSelf: "center"
    },
    buttonRowContainer: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: 25,
        alignSelf: "center"
    },
    bidDetailsContainer: {
        flexDirection: "row"
    },
    modalContent: {
        backgroundColor: colors.background["background-2-color"],
        padding: 25
    },
    bottomModal: {
        justifyContent: 'flex-end',
        margin: 0,
    },
    webModal: {
        alignSelf: "center",
        justifyContent: 'center',
    },
    webModalContainer: {
        height: "100%",
        width: "100%",
        position: "absolute",
        overflow: "hidden"
    }
});

BidProxyModal.propTypes = {
    closeAction: PropTypes.func,
    auctionDetails: PropTypes.object,
    highBid: PropTypes.number,
    currentTime: PropTypes.number,
    endTime: PropTypes.number
};

export default BidProxyModal;
