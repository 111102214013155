import * as React from "react";
import Svg, {Path} from "@cantoo/rn-svg";
import PropTypes from "prop-types";

const LocationIcon = (props) => {
    return (
            <Svg
                fill="none"
                viewBox={`0 0 20 20`}
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMinYMin slice"
                width="12"
                height="12"
            >
                <Path
                    d="M8 0a8 8 0 0 0-8 8.2c0 3.2 2.5 7 7.3 11.2.4.4 1 .4 1.4 0 4.9-4.3 7.3-8 7.3-11.2A8 8 0 0 0 8 0Zm0 10a2 2 0 0 1-2-2c0-1.1.9-2 2-2a2 2 0 0 1 2 2 2 2 0 0 1-2 2Z"
                    fill={props.color}
                />
            </Svg>
    );
};

LocationIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
};

LocationIcon.defaultProps = {
    width: 16,
    height: 24,
    color: "#212121"
};
export default LocationIcon;