import * as React from "react";
import DoubleArrow from "../icons/doubleArrow";
import {StyleSheet} from "react-native";
import {LinearGradient} from "expo-linear-gradient";
import {useColors} from "../../store/whiteLabelConfig/util";
import * as PropType from "prop-types";

const SliderButton = (props) => {
    const {testIdPrefix} = props;
    const colors = useColors();

    return (
        <LinearGradient
            testID={testIdPrefix + '-' + 'slider-button'}
            start={{x: 0.15, y: 0.45}}
            end={{x: 0.875, y: 0.55}}
            colors={colors.gradient["primary-gradient"]}
            style={styles.container}>
            <DoubleArrow/>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        width: 48,
        height: 48,
        borderRadius: 6,
        justifyContent: "center",
        alignItems: "center",
    }
});

SliderButton.propTypes = {
    testIdPrefix: PropType.string
};

export default SliderButton;
