import {getConfig} from "../config/config";
import ApiClient from "./apiClient";
import {defaultConfig} from "../store/whiteLabelConfig/reducer";
import {getState} from "../store";

export const fetchWhiteLabelConfig = async () => {
    const gatewayClient = new ApiClient(getConfig().platformApiUrl);
    let whiteLabelConfig = defaultConfig;
    try {
        const configResponse = await gatewayClient.get("/api/whitelabel");
        whiteLabelConfig = configResponse.data.data;
        whiteLabelConfig.canBid = JSON.stringify(configResponse.data.data.canBid);
        if (!whiteLabelConfig.country) {
            whiteLabelConfig.country = 'en-US';
        }
    } catch (error) {
        throw Error("No white label configuration found for user.");
    }
    return whiteLabelConfig;
};

export const pushMeasurements = async (deviceDetails, measurements) => {
    if(measurements && getState()?.auth?.tokens) {
        const gatewayClient = new ApiClient(getConfig().platformApiUrl);
        try {
            await gatewayClient.post("/api/t", {deviceDetails, data: measurements});
        } catch (error) {
            throw Error("Unable to update telemetry measurements.");
        }
    }
};