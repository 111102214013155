import * as React from "react";
import Svg, {Path} from "@cantoo/rn-svg";
import PropTypes from "prop-types";

const MenuIcon = (style) => {
    return (
        <Svg
            aria-hidden="true"
            data-prefix="far"
            data-icon="menu"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="12"
            fill={style.color}
        >
            <Path
                d="M1 12h16c.55 0 1-.45 1-1s-.45-1-1-1H1c-.55 0-1 .45-1 1s.45 1 1 1Zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H1c-.55 0-1 .45-1 1s.45 1 1 1ZM0 1c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H1C.45 0 0 .45 0 1Z"
                color="currentColor"
            />
        </Svg>
    );
};
MenuIcon.propTypes = {
    style: PropTypes.shape({
        color: PropTypes.string
    })
};

export default MenuIcon;