import {getState, getStore} from "../store";
import {recordMeasurement} from "../store/telemetry/actions";
import {getVersion} from "./platform";

/**
 * Provides the abiliity to record a telemetry measurement.  This "measurement" can be
 * to log how long something takes, or to just log that something has happened.
 *
 * @param {string} name The name of the event.  This should not be dynamic, but rather static.
 * @param {*} data The dynamic data that provides context to the name.
 */
export const pushMeasurement = (name, data = {}) => {
    const userId = getState().auth?.user?.user_id;
    const sessionId = getState().auth?.user?.session_token;
    const deviceTimestamp = Date.now();
    const version = getVersion();
    getStore().dispatch(recordMeasurement({name, deviceTimestamp, version,  userId, sessionId, ...data}));
};
