import React, {useState} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {globalHeading, globalText} from "../../fonts";
import CancelIcon from "../icons/cancelIcon";
import BidSlider from "../slider/bidSlider";
import ACVModal from "../modal";
import TimerText from "../timerText";
import PropTypes from "prop-types";
import {useTranslation} from 'react-i18next';
import {getCurrency} from "locale-currency";
import {useColors, useConfig} from "../../store/whiteLabelConfig/util";

const BidSwipeModal = (props) => {

    const {closeAction, auctionDetails, highBid, currentTime, endTime} = props;
    const locale = useConfig().country;
    const bidIncrement = 100;
    const convertedIncrement = bidIncrement.toLocaleString(locale, {
        style: 'currency',
        currency: getCurrency(locale), minimumFractionDigits: 0, maximumFractionDigits: 0
    });

    const [sliderValue, setSliderValue] = useState([0]);
    const handleSliderChange = async (value) => {
        setSliderValue(value);
        if (value[0] >= 10) {
            await closeAction(auctionDetails.id, highBid + bidIncrement);
        }
    };

    const {t} = useTranslation();
    const placeBid = t("swipeToBid") + convertedIncrement;
    const styles = buildStyles(useColors());

    return (
        <ACVModal closeAction={closeAction}>
            <TouchableOpacity style={styles.cancel} onPress={closeAction}>
                <CancelIcon/>
                <Text style={styles.cancelText}>{t('cancel')}</Text>
            </TouchableOpacity>
            <View style={styles.modalContent}>

                <View style={styles.rowContainer}>
                    <Text style={styles.bidTitle}>{t('bid')} + {convertedIncrement}</Text>
                </View>
                <View style={styles.rowContainer}>
                    <Text style={styles.bidDetailsTitle}>{t('vehicle')}</Text>
                    <Text
                        style={styles.bidDetailsValue}>{auctionDetails.year} {auctionDetails.make} {auctionDetails.model}</Text>
                </View>
                <View style={styles.rowContainer}>
                    <Text style={styles.bidDetailsTitle}>{t('timeRemaining')}</Text>
                    <TimerText
                        style={styles.bidDetailsValue}
                        currentTime={currentTime}
                        endTime={endTime}/>
                </View>
                <View style={styles.rowContainer}>
                    <Text style={styles.bidDetailsTitle}>{t('currentBid')}</Text>
                    <Text style={styles.currentBid}>{highBid.toLocaleString(locale, {
                        style: 'currency',
                        currency: getCurrency(locale), minimumFractionDigits: 0, maximumFractionDigits: 0
                    })}</Text>
                </View>
                <View style={styles.rowContainer}>
                    <Text style={styles.bidDetailsTitle}>{t("yourBid")}</Text>
                    <Text style={styles.bidDetailsValue}>{(highBid + bidIncrement).toLocaleString(locale, {
                        style: 'currency',
                        currency: getCurrency(locale), minimumFractionDigits: 0, maximumFractionDigits: 0
                    })}</Text>
                </View>
                <View style={styles.rowContainer}>
                    <BidSlider
                        sliderValue={sliderValue}
                        handleSliderChange={handleSliderChange} // eslint-disable-line react/jsx-no-bind
                        testID="bidSlider"
                        title={placeBid}
                    />
                </View>
            </View>
        </ACVModal>);
};

const buildStyles = colors => StyleSheet.create({
    cancel: {
        flexDirection: "row",
        alignSelf: "flex-end",
        paddingRight: 18,
        paddingBottom: 11,
        color: colors.text["text-inverse-color"],
        ...globalText.button
    },
    cancelText: {
        paddingLeft: 10,
        color: colors.text["text-inverse-color"],
        ...globalText.button
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
    },
    bidTitle: {
        ...globalHeading["h6-medium"],
        color: colors.text["text-secondary-color"]
    },
    bidDetailsTitle: {
        ...globalHeading["subtitle-base-medium"],
        color: colors.text["text-secondary-color"]
    },
    bidDetailsValue: {
        ...globalHeading["subtitle-base-regular"],
        color: colors.text["text-secondary-color"]
    },
    currentBid: {
        ...globalHeading["subtitle-base-regular"],
        color: colors.text["text-secondary-color"],
        textDecorationLine: "line-through"
    },
    rowContainer: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: 7,
        maxWidth: 330,
        alignSelf: "center"
    },
    modalContent: {
        backgroundColor: colors.background["background-2-color"],
        padding: 22,
        paddingBottom: 54,
    },
    bottomModal: {
        justifyContent: 'flex-end',
        margin: 0,
    },
    webModal: {
        alignSelf: "center",
        justifyContent: 'center',
    },
    webModalContainer: {
        height: "100%",
        width: "100%",
        position: "absolute",
        overflow: "hidden"
    }
});

BidSwipeModal.propTypes = {
    closeAction: PropTypes.func,
    auctionDetails: PropTypes.object,
    highBid: PropTypes.number,
    currentTime: PropTypes.number,
    endTime: PropTypes.number
};

export default BidSwipeModal;
