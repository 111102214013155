import {defaultConfig} from "./reducer";
import {useStore} from "react-redux";


const useConfig = () => {
    try {
        return useStore().getState().whiteLabelConfig;
    } catch (err) {
        return defaultConfig;
    }
};
const useColors = () => {
    return useConfig().colors;
};

export {useColors, useConfig};