import * as React from "react";
import Svg, {Path} from "@cantoo/rn-svg";
import PropTypes from "prop-types";

const CancelIcon = (props) => {
    const {widthHeight, color} = props;
    return (
        <Svg
            width={widthHeight}
            height={widthHeight}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="m7.273 6 3.846-3.846.793-.793a.3.3 0 0 0 0-.424l-.848-.849a.3.3 0 0 0-.425 0L6 4.728 1.36.087a.3.3 0 0 0-.423 0l-.85.848a.3.3 0 0 0 0 .425L4.728 6l-4.64 4.638a.3.3 0 0 0 0 .425l.85.848a.3.3 0 0 0 .424 0L6 7.273l3.845 3.846.793.793a.3.3 0 0 0 .425 0l.848-.848a.3.3 0 0 0 0-.425L7.273 6Z"
                fill={color}
            />
        </Svg>
    );
};

CancelIcon.propTypes = {
    widthHeight: PropTypes.number,
    color: PropTypes.string,
};

CancelIcon.defaultProps = {
    widthHeight: 12,
    color: "#fff"
};
export default CancelIcon;
