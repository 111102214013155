import * as React from "react";
import {ScrollView, Text, View} from "react-native";
import {SafeAreaView} from "react-native-safe-area-context";
import AnonymousTopBarNavigation from "../../navigation/anonymousTopBarNavigation";
import NumberedText from "../../components/numberedText";
import ExternalLink from "../../components/ExternalLink";
import {buildStyles} from "./styles";
import {useColors} from "../../store/whiteLabelConfig/util";

const TermsAndConditionsScreen = () => {
    const styles = buildStyles(useColors());
    return (
        <SafeAreaView style={styles.container}>
            <AnonymousTopBarNavigation tabName={"Legal Terms & Conditions"}/>
            <ScrollView>
                <View style={styles.legalContainer}>
                    <Text style={styles.title}>Open Auto Terms of Service</Text>
                    <Text style={styles.paragraph}>This is a binding legal agreement between you, your business and your
                        representatives (collectively, “you,” “your” or “Customer”) and ACV Auctions Inc. (“ACV
                        Auctions,”
                        “we” or “our”). These terms of service (“Terms”) cover your use and access to the services
                        available
                        from ACV Auctions through which you may sell and purchase vehicles and any services provided
                        related
                        thereto (the “Services”). A Customer can mean the party selling a vehicle (the “Seller”), the
                        party
                        buying a vehicle (the “Buyer”), anyone who bids on a vehicle, or anyone using the Services.
                        These
                        Terms and any amendments to them will be effective as of the date specified herein or in the
                        amended
                        Terms. By using or accessing the Services, you agree to be bound by the current Terms, the ACV
                        Auctions Privacy Policy located at <ExternalLink url={"https://open.auto/privacy"}/>
                    </Text>

                    <View style={styles.termsContainer}>
                        <NumberedText prefix={"1."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Eligible Participants.</Text>
                                <Text style={styles.paragraph}>ACV Auctions provides a business-to-business online
                                    marketplace. Only representatives of licensed dealerships are eligible to
                                    participate.
                                    By using the Services you represent and warrant that you are a licensed dealer of
                                    motor
                                    vehicles and are legally eligible to purchase or sell wholesale vehicles for resale.
                                    A
                                    state issued dealer’s license, applicable resale certificate(s), and federal tax
                                    identification are among the documents and information a dealership will need to
                                    provide
                                    to utilize the Services.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"2."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Registration.</Text>
                                <Text style={styles.paragraph}>To be eligible to access the Services, you must complete
                                    ACV
                                    Auctions’ online registration process. Upon receipt and approval of your
                                    registration
                                    documents, you and your designated authorized representatives (“Authorized
                                    Representatives”) will be issued unique names and passwords to access the Services.
                                    You
                                    are liable and responsible for all actions, omissions and any failure to act of your
                                    Authorized Representatives in connection with your Authorized Representatives’ use
                                    of
                                    the Services. You represent and warrant that all information that you provide to ACV
                                    Auctions is accurate and complete and that you will notify us in writing of any
                                    changes
                                    to the information provided. You hereby authorize ACV Auctions to share the
                                    information
                                    you provide to us (including, without limitation, financial information) with ACV
                                    Auctions partners solely to the extent such information is needed to provide the
                                    Services. You agree not to give anyone access to the Services other than your
                                    Authorized
                                    Representatives. Further, you represent and warrant to ACV Auctions that: (a) you
                                    and
                                    your Authorized Representatives are in compliance with and shall comply with all
                                    laws
                                    and regulations that apply to your business; and (b) you and your Authorized
                                    Representatives have secured and will maintain all permits, licenses and
                                    governmental
                                    approvals required to operate your business including, without limitation, as
                                    required
                                    to access and participate in the Services. We reserve the right to temporarily or
                                    permanently suspend your access rights and/or the access rights of any of your
                                    Authorized Representatives at any time in our sole and absolute discretion.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"3."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Permitted Use.</Text>
                                <Text style={styles.paragraph}>You agree that you will use the Services solely for your
                                    internal business purposes and will not: (i) license, sublicense, sell, resell,
                                    rent,
                                    lease, transfer, assign (except as permitted by Section 25 herein), distribute, time
                                    share or otherwise commercially exploit or make the Services available to any third
                                    party, other than to your Authorized Representatives or as otherwise contemplated by
                                    these Terms; (ii) send spam or otherwise duplicative or unsolicited messages in
                                    violation of applicable laws; (iii) send or store infringing, obscene, threatening,
                                    libelous, or otherwise unlawful or tortious material, including material that
                                    violates
                                    third party privacy rights; (iv) send or store malicious code or viruses; (v)
                                    interfere
                                    with or disrupt the integrity or performance of the Services or the data contained
                                    therein; or (vi) attempt to gain unauthorized access to the Services or its related
                                    systems or networks. You further agree that you will not (i) modify, copy or create
                                    derivative works based on the Services; (ii) frame or mirror any content forming
                                    part of
                                    the Services; (iii) reverse engineer or decompile the Services; (iv) access the
                                    Services
                                    in order to (A) build a competitive product or service, or (B) copy any ideas,
                                    features,
                                    functions or graphics of the Services; or (v) permit or authorize any third party to
                                    do
                                    any of the above at your direction or on your behalf.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"4."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>ACV Auctions’ Role.</Text>
                                <Text style={styles.paragraph}>ACV Auctions provides services to facilitate the exchange
                                    of
                                    vehicles between Buyers and Sellers. ACV Auctions does not take title to the
                                    vehicles
                                    listed for sale via the Services. ACV Auctions is neither the Buyer nor the Seller
                                    of
                                    the vehicles listed for sale. As a commercial party, you should research the
                                    condition
                                    and value of the vehicle and the terms of sale before bidding. ACV Auctions is not a
                                    party to the vehicle sale contract, but is a third party beneficiary of the
                                    contract,
                                    entitled to the parties’ performance and to seek legal remedies for the parties’
                                    breach.
                                    You agree that any legal claims arising out of a transaction will be between you and
                                    the
                                    other party (either Buyer or Seller) in the transaction and not against ACV Auctions
                                    or
                                    any ACV Auctions partner.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"5."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Listing, Bidding and Purchase.</Text>
                                <Text style={styles.paragraph}>Please see ACV Auctions’ FAQ page (
                                    <ExternalLink url={"https://www.acvauctions.com/features"}/>) for detailed
                                    information regarding the listing,
                                    bidding and sale process.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"6."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Listing, Bidding and Purchase.</Text>
                                <Text style={styles.paragraph}>The Seller represents, warrants and agrees to the
                                    following
                                    terms:</Text>
                            </Text>
                                <NumberedText prefix={"1."}>
                                    <Text style={styles.paragraph}>Seller is a licensed dealer of motor vehicles;</Text>
                                </NumberedText>
                                <NumberedText prefix={"2."}>
                                    <Text style={styles.paragraph}>any amounts owed to ACV Auctions or its affiliates by
                                        Seller may be deducted from any proceeds due Seller;</Text>
                                </NumberedText>
                                <NumberedText prefix={"3."}>
                                    <Text style={styles.paragraph}>ACV Auctions may stop payment or refuse to authorize
                                        payment to Seller pursuant to a right of offset;</Text>
                                </NumberedText>
                                <NumberedText prefix={"4."}>
                                    <Text style={styles.paragraph}>risk of loss for a vehicle remains with the Seller as
                                        long as the vehicle is at Seller’s premises. Once the Buyer retrieves the
                                        vehicle,
                                        or otherwise causes the vehicle to be removed from Seller’s premises, the risk
                                        of
                                        loss for the vehicle is transferred to the Buyer or its agents;</Text>
                                </NumberedText>
                                <NumberedText prefix={"5."}>
                                    <Text style={styles.paragraph}>it is responsible for completion and execution of the
                                        required odometer mileage statement pertaining to the vehicle and on the
                                        certificate
                                        of title;</Text>
                                </NumberedText>
                                <NumberedText prefix={"6."}>
                                    <Text style={styles.paragraph}>that it is the true and lawful owner of the vehicle
                                        except as set forth in the vehicle’s announcements;</Text>
                                </NumberedText>
                                <NumberedText prefix={"7."}>
                                    <Text style={styles.paragraph}>that the vehicle is free from all liens and
                                        encumbrances
                                        except as set forth in the vehicle’s announcements;</Text>
                                </NumberedText>
                                <NumberedText prefix={"8."}>
                                    <Text style={styles.paragraph}>that it has full rights, power and authority to sell
                                        and
                                        transfer title to the Buyer in accordance with the ACV Auctions Title Policy (
                                        <ExternalLink url={"https://www.acvauctions.com/title-policy"}/> ) except as set
                                        forth in the vehicle’s
                                        announcements;</Text>
                                </NumberedText>
                                <NumberedText prefix={"9."}>
                                    <Text style={styles.paragraph}>a $500 cancellation fee will be assessed to a Seller
                                        if a
                                        transaction is cancelled or abandoned after a Buyer&lsquo;s bid either meets the
                                        reserve
                                        or is otherwise accepted;</Text>
                                </NumberedText>
                                <NumberedText prefix={"10."}>
                                    <Text style={styles.paragraph}>unless otherwise agreed, Seller will hold and make
                                        the
                                        sold vehicle available on its lot for at least seven business days after the
                                        sale of
                                        the vehicle for the Buyer or its agent to pick up;</Text>
                                </NumberedText>
                                <NumberedText prefix={"11."}>
                                    <Text style={styles.paragraph}>the number of vehicles posted by a Seller may be
                                        limited
                                        by ACV Auctions during an introductory period;</Text>
                                </NumberedText>
                                <NumberedText prefix={"12."}>
                                    <Text style={styles.paragraph}>unless otherwise agreed, Seller will permit ACV
                                        Auctions
                                        to inspect on Seller’s lot the vehicles Seller intends to sell through the
                                        Services,
                                        for the purpose of creating a vehicle condition report; and</Text>
                                </NumberedText>
                                <NumberedText prefix={"13."}>
                                    <Text style={styles.paragraph}>Seller shall abide by and comply with all applicable
                                        laws, rules and regulations, including but not limited to all rules and
                                        regulations
                                        set forth by the applicable department of motor vehicles.</Text>
                                </NumberedText>
                        </NumberedText>
                        <NumberedText prefix={"7."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Buyer Obligations.</Text>
                                <Text style={styles.paragraph}>The Buyer represents, warrants and agrees to the
                                    following
                                    terms:</Text>
                            </Text>
                            <NumberedText prefix={"1."}>
                                <Text style={styles.paragraph}>that it is a licensed motor vehicle dealer in good
                                    standing;</Text>
                            </NumberedText>
                            <NumberedText prefix={"2."}>
                                <Text style={styles.paragraph}>Buyer will purchase the vehicle for the Buyer’s bid price
                                    which can be accepted by the Seller until 3:00AM Eastern time of the day after the
                                    auction is launched;</Text>
                            </NumberedText>
                            <NumberedText prefix={"3."}>
                                <Text style={styles.paragraph}>Buyer will not resell the vehicle until good funds have
                                    been
                                    transmitted to ACV Auctions;</Text>
                            </NumberedText>
                            <NumberedText prefix={"4."}>
                                <Text style={styles.paragraph}>Buyer will pick up the vehicle within seven business days
                                    unless Buyer is located in California and picking up a vehicle in California, in
                                    which
                                    case, Buyer will pick up the vehicle within three business days;</Text>
                            </NumberedText>
                            <NumberedText prefix={"5."}>
                                <Text style={styles.paragraph}>Buyer will select a payment option by 11:59 pm same day
                                    the
                                    bid is accepted by the Seller or this sale may be considered null and void at ACV
                                    Auctions’ discretion; in such event Buyer may be subject to financial penalty and/or
                                    suspension of Buyer’s account access;</Text>
                            </NumberedText>
                            <NumberedText prefix={"6."}>
                                <Text style={styles.paragraph}>that no stop payment of Buyer’s payment to ACV Auctions
                                    shall
                                    be honored;</Text>
                            </NumberedText>
                            <NumberedText prefix={"7."}>
                                <Text style={styles.paragraph}>that any stop payment order shall be deemed by the
                                    parties to
                                    be evidence of fraud existing at the time the transaction was consummated and shall
                                    be
                                    construed by the parties as an intent to defraud in order to consummate the
                                    transaction;</Text>
                            </NumberedText>
                            <NumberedText prefix={"8."}>
                                <Text style={styles.paragraph}>that it will abide by the timeframes for delivery of
                                    title
                                    set forth in ACV Auctions’ titles policy;</Text>
                            </NumberedText>
                            <NumberedText prefix={"9."}>
                                <Text style={styles.paragraph}>that ACV Auctions may deposit any payment immediately,
                                    regardless of whether the vehicle’s certificate of title has been submitted by
                                    Seller;</Text>
                            </NumberedText>
                            <NumberedText prefix={"10."}>
                                <Text style={styles.paragraph}>that Buyer is responsible for pickup and transport, or
                                    may
                                    elect to utilize ACV Transportation LLC services;</Text>
                            </NumberedText>
                            <NumberedText prefix={"11."}>
                                <Text style={styles.paragraph}>that it is solvent;</Text>
                            </NumberedText>
                            <NumberedText prefix={"12."}>
                                <Text style={styles.paragraph}>that the vehicle is purchased solely for resale;</Text>
                            </NumberedText>
                            <NumberedText prefix={"13."}>
                                <Text style={styles.paragraph}>that the vehicle is purchased for resale in the form of
                                    tangible personal property in the regular course of business and is the sort usually
                                    purchased by Buyer for resale;</Text>
                            </NumberedText>
                            <NumberedText prefix={"14."}>
                                <Text style={styles.paragraph}>that if it is planning to export a vehicle, Buyer is
                                    responsible for ensuring satisfaction of all export requirements and all import
                                    requirements of the destination country;</Text>
                            </NumberedText>
                            <NumberedText prefix={"15."}>
                                <Text style={styles.paragraph}>that in the event that the vehicle is used for any
                                    purpose
                                    other than for resale, Buyer will pay directly to the proper taxing authorities such
                                    sale or use tax as may then be accrued and become payable;</Text>
                            </NumberedText>
                            <NumberedText prefix={"16."}>
                                <Text style={styles.paragraph}>that it holds a retail sales tax registration,
                                    certificate,
                                    license, or other permit, issued by the sales taxing authority of Seller’s state,
                                    county, and country as appropriate;</Text>
                            </NumberedText>
                            <NumberedText prefix={"17."}>
                                <Text style={styles.paragraph}>title to the vehicle will not pass to Buyer until
                                    complete
                                    payment has been received by ACV Auctions and the title has been received from the
                                    Seller;</Text>
                            </NumberedText>
                            <NumberedText prefix={"18."}>
                                <Text style={styles.paragraph}>ACV Auctions reserves the right to limit the number of
                                    vehicles purchased by a Buyer at any time</Text>
                            </NumberedText>
                            <NumberedText prefix={"19."}>
                                <Text style={styles.paragraph}>failure to remit payment within two business days will
                                    result
                                    in a fee equal to the total buy fee;</Text>
                            </NumberedText>
                            <NumberedText prefix={"20."}>
                                <Text style={styles.paragraph}>Buyer is subject to account suspension at the discretion
                                    of
                                    ACV Auctions;</Text>
                            </NumberedText>
                            <NumberedText prefix={"21."}>
                                <Text style={styles.paragraph}>Buyer will not attempt to manipulate bids, intentionally
                                    bid
                                    up the price of any vehicle, or otherwise fraudulently bid on any vehicle;
                                    and</Text>
                            </NumberedText>
                            <NumberedText prefix={"22."}>
                                <Text style={styles.paragraph}>Buyer shall abide by and comply with all applicable laws,
                                    rules and regulations, including but not limited to all rules and regulations set
                                    forth
                                    by the applicable department of motor vehicles.</Text>
                            </NumberedText>
                        </NumberedText>
                        <NumberedText prefix={"8."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Transport.</Text>
                                <Text styles={styles.paragraph}>If you elect through the Services to arrange for the
                                    transportation of any vehicles purchased through the Services, such transportation
                                    shall
                                    be arranged by ACV Transportation LLC, an authorized affiliate of ACV Auctions, and
                                    shall be governed exclusively by the terms and conditions entered into by you and
                                    ACV
                                    Transportation LLC located at <ExternalLink
                                        url={"https://www.acvauctions.com/transportation-terms"}/> . You
                                    agree to pay ACV Auctions for any transportation costs due to ACV Transportation
                                    LLC,
                                    and ACV Auctions will remit funds to ACV Transportation LLC.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"9."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Payment Terms.</Text>
                                <Text styles={styles.paragraph}>In consideration of access to the Services, Buyer and
                                    Seller
                                    each agree to pay ACV Auctions or partners (as applicable) all fees and charges
                                    assessed
                                    by ACV Auctions and/or the ACV Auctions partners for access to the Services
                                    (collectively, the “Fees”). Fees are subject to change without notice. Please
                                    request a
                                    current list of ACV Auctions’ Fees from your assigned account manager or email
                                    finance@acvauctions.com. The purchase price of the vehicle, service charges, plus
                                    all
                                    applicable taxes and Fees payable by Buyer in connection with a transaction is the
                                    “Total Payment Amount.” All Fees are non-refundable.</Text>
                                <Text styles={styles.paragraph}>Buyer shall pay ACV Auctions the Total Payment Amount
                                    within
                                    two (2) business days after the Buyer’s bid is accepted as the winning bid (the
                                    “Payment
                                    Due Date”). Payment shall be made in U.S. dollars by, ACH electronic funds transfer,
                                    check, floor plan, or by another financing arrangement acceptable to ACV Auctions
                                    (each
                                    a “Payment Method”). If payment is not received by the Payment Due Date, the amount
                                    owing will be automatically debited using the default payment method selected by the
                                    Buyer. ACV Auctions reserves the right to specify the type of Payment Method it will
                                    accept from the Buyer in our sole and absolute discretion. In addition to whatever
                                    rights of set-off ACV Auctions may have in any jurisdiction where vehicles are sold,
                                    if
                                    Buyer fails to pay Fees or other amounts owing when due to ACV Auctions or any ACV
                                    Auctions partner, ACV Auctions will be entitled to immediately set off the amount
                                    owed
                                    by Buyer from any funds due from ACV Auctions to Buyer. In addition, if you fail to
                                    pay
                                    the Total Payment Amount by the Payment Due Date, we may: (a) electronically debit
                                    your
                                    bank account or floor plan account in accordance with the default payment method
                                    selected by you or another account made available to ACV Auctions; (b) withhold
                                    title
                                    documents until all amounts owed have been paid; (c) cancel the sale transaction;
                                    (d)
                                    charge interest on any past due amounts at the rate of one and one half (1.5%)
                                    percent
                                    per month, calculated in U.S. dollars, or the maximum rate allowed by law, whichever
                                    is
                                    less; and/or (e) pursue any other remedy or relief permitted by law. If ACV Auctions
                                    accepts payment from Buyer by electronic check, Buyer authorizes ACV Auctions to
                                    initiate debit entries to its account at the financial institution on which the
                                    electronic check was written. The electronic check debit authorization will remain
                                    in
                                    force until we have received written notice from you of its termination. ACV
                                    Auctions
                                    reserves the right to decline payment by electronic check at any time and for any
                                    reason. Any payment that is withdrawn by Buyer or its bank, rejected or returned for
                                    non-sufficient funds (“NSF”) must be settled and replaced immediately. NSF
                                    transactions
                                    will be subject to a service charge up to the maximum amount allowed by law and
                                    shall be
                                    immediately due and payable.</Text>
                                <Text styles={styles.paragraph}>ACV Auctions will pay Seller the purchase price of the
                                    vehicle less the Fees due from Seller to ACV Auctions after receipt of a clear,
                                    transferable title from Seller.</Text>
                                <Text styles={styles.paragraph}>You agree to pay all costs including, without
                                    limitation,
                                    attorney fees, court costs and other expenses reasonably incurred by ACV Auctions or
                                    an
                                    ACV Auctions partner, to collect any monies owing by you.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"10."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Arbitration.</Text>
                                <Text styles={styles.paragraph}>If, upon pickup and inspection of the vehicle, the Buyer
                                    discovers a defect that is allowed to be arbitrated pursuant to the ACV Auctions
                                    Arbitration Policy, Buyer may pursue a remedy through the arbitration process as set
                                    forth in such policy. Sellers who repeatedly fail to make required announcements or
                                    Buyers who repeatedly submit questionable arbitration claims may have their auction
                                    privileges temporarily or permanently suspended.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"11."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Data.</Text>
                                <Text styles={styles.paragraph}>ACV has the right to use certain Customer generated
                                    information, content, photographs and vehicle data for the limited use of promoting
                                    and
                                    carrying out the Services requested by such Customer. Customers and their
                                    representative
                                    dealerships grant ACV a limited license to utilize non-confidential information for
                                    the
                                    general purpose of promoting and providing Services to our Customers, and this
                                    license
                                    extends to trusted third parties with whom we work. Customers agree that this
                                    permission
                                    is royalty free, irrevocable and worldwide for so long as Customer Information is
                                    held
                                    by ACV Auctions. All data collected by ACV Auctions related to vehicles, and all
                                    transaction data related to the Services shall be owned by ACV Auctions without
                                    restrictions on its use of such data.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"12."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>ACV Auctions Intellectual Property.</Text>
                                <Text styles={styles.paragraph}>ACV Auctions reserves all rights, title, and interest in
                                    and
                                    to the Services, the ACV Auctions trademarks, logos and other brand features. These
                                    Terms do not grant Customers any rights in the Services except as expressly set
                                    forth
                                    herein. ACV Auctions’ trademarks may not be used in connection with any product,
                                    service, or marketing without the prior written consent of ACV Auctions. ACV
                                    Auctions
                                    welcomes feedback, but reserves the right to use comments or suggestions without any
                                    obligation to the Customer.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"13."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Availability, Errors and Inaccuracies.</Text>
                                <Text styles={styles.paragraph}>ACV Auctions is constantly updating the Services, and
                                    may
                                    experience delays in updating information on the Services. The information found on
                                    the
                                    Services may contain errors or inaccuracies and may not be complete or current.
                                    Products
                                    or services may be mispriced, described inaccurately, or unavailable on the
                                    Services,
                                    and we cannot guarantee the accuracy or completeness of any information found on the
                                    Services. We therefore reserve the right to change or update information and to
                                    correct
                                    errors, inaccuracies, or omissions at any time without prior notice.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"14."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Links to Other Web Sites.</Text>
                                <Text styles={styles.paragraph}>The Services may contain links to third party web sites
                                    or
                                    services that are not owned or controlled by ACV Auctions. ACV Auctions has no
                                    control
                                    over, and assumes no responsibility for the content, privacy policies, or practices
                                    of
                                    any third party web sites or services. We do not warrant the offerings of any of
                                    these
                                    entities/individuals or their websites. You acknowledge and agree that ACV Auctions
                                    shall not be responsible or liable, directly or indirectly, for any damage or loss
                                    caused or alleged to be caused by or in connection with use of or reliance on any
                                    such
                                    content, goods or services available on or through any such third party web sites or
                                    services. We strongly advise you to read the terms and conditions and privacy
                                    policies
                                    of any third party web sites or services that you visit.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"15."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Termination.</Text>
                                <Text styles={styles.paragraph}>Customers are free to stop using our Services at any
                                    time.
                                    We also reserve the right to suspend or end the Services at any time at our
                                    discretion
                                    and without notice. For example, we may suspend or terminate a Customer’s access and
                                    use
                                    of the Services if they are not complying with these Terms, or use the Services in a
                                    manner that would cause us legal liability, disrupt the Services or disrupt others’
                                    use
                                    of the Services. ACV Auctions reserves the right to terminate and delete your
                                    account.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"16."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>DISCLAIMER OF WARRANTIES AND LIABILITIES.</Text>
                                <Text styles={styles.paragraph}>ACV AUCTIONS AND THE ACV AUCTIONS AFFILIATES PROVIDE THE
                                    SERVICES TO CUSTOMER ON AN “AS IS” AND “AS AVAILABLE” BASIS, AND HEREBY DISCLAIM ALL
                                    EXPRESS AND IMPLIED WARRANTIES RELATED TO CUSTOMER’S AND/OR AUTHORIZED
                                    REPRESENTATIVE’S
                                    USE OF AND ACCESS TO THE SERVICES, INCLUDING ANY IMPLIED WARRANTIES OF
                                    MERCHANTABILITY,
                                    FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR ANY WARRANTIES WHICH MAY BE
                                    CREATED THROUGH COURSE OF DEALING.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"17."}>
                            <Text>
                                <Text styles={styles.paragraph}>CUSTOMER AGREES THAT ACV AUCTIONS AND ITS AFFILIATES
                                    SHALL
                                    HAVE NO LIABILITY WHATSOEVER, ACTUAL OR OTHERWISE, TO CUSTOMER OR ITS AUTHORIZED
                                    REPRESENTATIVES BASED ON ANY OF THE FOLLOWING: (i) ANY DELAY, INTERRUPTION IN USE
                                    OF,
                                    FAILURE IN OR BREAKDOWN OF THE SERVICES OR ERRORS OR DEFECTS IN TRANSMISSION
                                    OCCURRING
                                    IN THE COURSE OF ACCESSING OR USING THE SERVICES; (ii) ANY UNLAWFUL OR UNAUTHORIZED
                                    USE
                                    OF THE SERVICES; (iii) ANY LOSS OF OR DAMAGE TO CUSTOMER&apos;S RECORDS OR INFORMATION;
                                    (iv)
                                    ANY CLAIM RESULTING FROM THE TERMINATION OF ACCESS TO THE SERVICES OR (v) ANY CLAIM
                                    RELATED TO ANY VEHICLE SOLD OR BOUGHT USING THE SERVICES, EXCEPT AS MAY BE SPECIFIED
                                    IN
                                    THE GO GREEN POLICY FOUND WITHIN ACV AUCTIONS’ ARBITRATION POLICY.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"18."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Indemnity.</Text>
                                <Text styles={styles.paragraph}>Each Customer agrees at their own expense to defend,
                                    indemnify, and hold ACV Auctions and its affiliates, and each of their directors,
                                    officers, employees and agents, harmless from and against any loss, damage,
                                    liability,
                                    demand, lawsuit, debt, charge, action, penalty, interest, claim, cost, tax or
                                    expense
                                    whatsoever, including without limitation any and all out-of-pocket costs and actual
                                    legal and accounting fees, duties and bonds, which ACV Auctions may incur, suffer or
                                    be
                                    required to pay as a result of or relating to, arising out of, or in connection with
                                    (a)
                                    your use of the Services; (b) your violation of the Terms; (c) your violation of any
                                    third party right, including, but not limited to, any intellectual property right,
                                    right
                                    of attribution, association, integrity, publicity, confidentiality, property or
                                    privacy
                                    right; (d) any claim in connection with a vehicle; or (d) any claim that Customer
                                    information, or your use the property of others, may have caused or did cause damage
                                    to
                                    a third party, including, but not limited to, claims that Customer information, or
                                    your
                                    use of the property of others, infringe the rights of others or caused damages to
                                    others.

                                    Customer also agrees to indemnify ACV Auctions for reasonable legal and accounting
                                    fees,
                                    costs, and expenses incurred in enforcing the rights of ACV Auctions against
                                    Customer
                                    under these Terms.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"19."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Representations Regarding Taxes.</Text>
                                <Text styles={styles.paragraph}>Buyer certifies that it holds the appropriate
                                    documentation
                                    issued by the sales tax authority of the state, province, or locality of its
                                    business or
                                    where it purchases vehicles, as applicable, which exempts Buyer from the payment of
                                    sales tax, and warrants that it will provide a copy of such applicable documentation
                                    to
                                    ACV Auctions upon registration, at any time upon request, and as necessary to
                                    maintain
                                    the validity of such documentation. In the event that vehicles or other products
                                    purchased are used for any purpose other than for resale, Buyer will pay directly to
                                    the
                                    proper taxing authorities such sale or use tax as may then accrue and be due and
                                    payable.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"20."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Dispute Resolution.</Text>
                                <Text styles={styles.paragraph}>Any controversy or claim between Customer and ACV
                                    Auctions
                                    arising out of or relating to these Terms or the validity, inducement, or breach
                                    hereof
                                    (each such controversy or claim is hereinafter referred to as a &quot;Dispute&quot;), shall be
                                    settled as follows:</Text>
                                <Text styles={styles.paragraph}>First, the parties involved in the Dispute shall attempt
                                    to
                                    resolve any Dispute prior to commencing the procedures set forth below.</Text>
                                <Text styles={styles.paragraph}>If after seven days the parties are unable to resolve
                                    the
                                    Dispute, the parties shall submit to non-binding mediation which shall take place
                                    for a
                                    period of one day in Erie County, New York before a mediator that is mutually
                                    acceptable
                                    to the parties. If the parties are unable to agree on the selection of a mediator, a
                                    mediator will be chosen by an arbitrator selected pursuant to the rules of the
                                    American
                                    Arbitration Association (&quot;AAA&quot;) who will then select such mediator from a list of
                                    distinguished neutral mediators maintained by the AAA. The mediator shall confer
                                    with
                                    the parties to design procedures to conclude the mediation within no more than 30
                                    days
                                    after initiation. Each party has the right to pursue any provisional relief from the
                                    appropriate court, such as attachment, preliminary injunction, specific performance
                                    (the
                                    parties acknowledging that monetary damages may not be sufficient remedy), replevin,
                                    etc. to avoid irreparable harm, maintain the status quo, or preserve the subject
                                    matter
                                    of the Dispute even though mediation has not been commenced or completed.</Text>
                                <Text styles={styles.paragraph}>Customer may only resolve disputes with ACV Auctions on
                                    an
                                    individual basis, and may not bring a claim as a plaintiff or a class member in a
                                    class,
                                    consolidated, or representative action. Class arbitrations, class actions, private
                                    attorney general actions, and consolidation with other arbitrations are not allowed,
                                    and
                                    Customer expressly disclaims any right to bring or participate in any such action.
                                    If
                                    the parties are unable to resolve the Dispute within the 45 day period referred to
                                    above, or in the event that the agreement to arbitrate is found not to apply to
                                    Customer
                                    or Customer’s claim, Customer and ACV Auctions agree that any judicial proceeding
                                    will
                                    be brought in the federal or state courts of Erie County, New York. Both Customer
                                    and
                                    ACV Auctions consent to venue and personal jurisdiction there.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"21."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Controlling Law.</Text>
                                <Text styles={styles.paragraph}>The Services provided hereunder are performed in the
                                    State
                                    of New York and these Terms will be governed by New York law except for its
                                    conflicts of
                                    laws principles. You represent that you have sufficient authority and all right and
                                    full
                                    power to agree to these Terms and to perform your respective obligations under these
                                    Terms on behalf of yourself or on behalf of the organization for whom you are
                                    acting, as
                                    the case may be.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"22."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Entire Agreement.</Text>
                                <Text styles={styles.paragraph}>These Terms constitute the entire agreement between you
                                    and
                                    ACV Auctions with respect to the subject matter of these Terms, and supersede and
                                    replace any other prior or contemporaneous agreements, or terms and conditions
                                    applicable to the subject matter of these Terms.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"23."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Waiver, Severability & Assignment. </Text>
                                <Text styles={styles.paragraph}>ACV Auctions’ failure to enforce a provision is not a
                                    waiver
                                    of its right to do so later. If a provision is found unenforceable, the remaining
                                    provisions of the Terms will remain in full effect and an enforceable term will be
                                    substituted reflecting our intent as closely as possible. You may not assign any of
                                    your
                                    rights under these Terms without ACV Auctions’ prior written consent, and any such
                                    attempt will be void. ACV Auctions may assign its rights to any of its affiliates or
                                    subsidiaries, or to any successor in interest of any business associated with the
                                    Services.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"24."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Changes.</Text>
                                <Text styles={styles.paragraph}>We reserve the right, at our sole discretion, to modify
                                    or
                                    replace these Terms at any time with respect to all sales occurring after such
                                    modifications are effective. Modifications will take effect immediately upon posting
                                    of
                                    the revised Terms, provided however, if a change is material we will provide at
                                    least 30
                                    days’ notice prior to such modifications taking effect. What constitutes a material
                                    modification will be determined at our sole discretion. By continuing to access or
                                    use
                                    our Service after any modifications become effective, you agree to be bound by the
                                    revised terms.</Text>
                            </Text>
                        </NumberedText>
                        <NumberedText prefix={"25."}>
                            <Text>
                                <Text style={styles.paragraphTitle}>Contact Us, Notices.</Text>
                                <Text styles={styles.paragraph}>Notices must be sent via first class mail or overnight
                                    courier and are deemed given when received. Notices to Customer may also be sent to
                                    the
                                    applicable account email address and are deemed given when sent. Notices to ACV
                                    Auctions
                                    must be sent to:</Text>
                                <Text styles={styles.paragraphTitle}>ACV Auctions, Inc.</Text>
                                <Text styles={styles.paragraph}>640 Ellicott St.</Text>
                                <Text styles={styles.paragraph}>Suite 321</Text>
                                <Text styles={styles.paragraph}>Buffalo, NY 14203</Text>
                            </Text>
                        </NumberedText>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export default TermsAndConditionsScreen;