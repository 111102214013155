export default {
    languageSelector: 'Selector de idioma',
    marketplace: 'Mercado',
    results: 'Resultados',
    bid: 'Pujar',
    menu: 'menú',
    liveAuctions: 'Subastas en vivo',
    logout: 'Cerrar sesión',
    privacyPolicy: 'Política de privacidad',
    changeLanguage: 'Cambiar idioma',
    emailAddress: 'Correo electrónico',
    password: 'Contraseña',
    login: 'Iniciar sesión',
    forgotPassword: 'Olvidé mi contraseña?',
    requestResetPassword: 'Solicitar restablecimiento de contraseña',
    NetworkError: 'Error de red',
    wrongCredentials: 'El correo electrónico y/o la contraseña son incorrectos.',
    noServerConnection: 'No pudimos conectar con el servidor. Compruebe su conexión e inténtelo de nuevo más tarde.',
    unablePasswordReset: 'No se puede restablecer la contraseña',
    resetPassword: 'Restablecer la contraseña',
    resetPasswordDesc: 'Para restablecer su contraseña, ingrese su correo electrónico asociado con su cuenta.',
    emailSent: 'Se ha enviado un correo electrónico con instrucciones para restablecer su contraseña.',
    requestReset: 'Solicitar Restablecimiento',
    returnToLogin: 'Volver a iniciar sesión',
    vehicleDetails: 'Detalles del Vehículo',
    views: 'Vistas',
    bids: 'Ofertas',
    photo: 'Foto',
    ending: 'Finalizando',
    bidding: 'Puja',
    vin: 'NIV',
    city: 'Ciudad',
    odometer: 'Odómetro',
    transmission: 'Transmisión',
    automatic: 'Automática',
    manual: 'Manual',
    trim: 'Nivel de acabado',
    drivetrain: 'Tren de transmisión',
    engine: 'Motor',
    fuelType: 'Tipo de combustible',
    year: 'Año',
    make: 'Marca',
    model: 'Modelo',
    color: 'Color',
    auctionId: 'ID de Subasta',
    auctionDate: 'Fecha de Subasta',
    sold: 'Vendido',
    unsold: 'No Vendido',
    swipeToBid: 'Desliza para pujar',
    currentBid: 'Oferta Actual',
    yourBid: 'Su Oferta',
    timeRemaining: 'Tiempo Restante',
    vehicle: 'Vehículo',
    cancel: 'Cancelar',
    highBid: 'Oferta Alta',
    active: 'activo',
    reservedPrice: 'Precio de Reserva',
    highestBid: 'La Oferta Más Alta',
    highestBidder: 'Mejor Postor',
    reservedMet: 'Reserva Alcanzada',
    noReserve: 'Sin reserve',
    version: 'Versión',
    outBid: 'Sobrepujado',
    auctionDetails: "Detalles de Subasta",
    Miles: 'Millas',
    km: 'km',
    day: 'día',
    days: 'días',

    // COLORS
    black: 'Negro',
    white: 'Blanco',
    silver: 'Plata',
    red: 'Rojo',
    gray: 'Gris',
    green: 'Verde',
    blue: 'Azul',
    purple: 'Púrpura',
    orange: 'Naranja',
    beige: 'Beige',
    brown: 'Café',
    yellow: 'Amarillo',
    gold: 'Oro',
    blueLight: 'Azul (Luz)',
    blueDark: 'Azul (Oscuro)',
    tan: 'Bronceado',
    maroon: 'Marrón',
    pearl: 'Perla',
    pink: 'Rosa',

    // Proxy Bidding
    proxyBid: 'Pujar hasta',
    setProxy: 'Fijar puja hasta',
    swipeProxy: 'Swipe To Set Proxy',
    editProxy: 'Editar oferta hasta',
    yourProxy: 'Su puja hasta',
    proxyMultiples: 'Oferte en múltiplos de $50',
    confirmProxyTitle: "Confirme su puja hasta",
    confirmProxySubtitle: 'Por favor, confirme su oferta hasta',
    errorMismatch: 'Error! Los montos no coinciden. Actualice su oferta hasta.',
    errorProxyIncrement: 'Error! Oferte en múltiplos de $50.',
    errorLowProxy: 'Error! La oferta es demasiado bajo. Aumente su oferta.',
    errorHighProxy:  'Error! La oferta es demasiado alta. Disminuir su oferta.',
    errorProxyGeneric: 'Error! Algo salió mal. Vuelva a intentarlo más tarde.',

    // Flue Type
    gas: 'Gasolina',
    flex: 'Carburant Flexible',
    diesel: 'Diesel',
    hybrid: 'Hybride',
    lpg: 'LPG',
    plugInHybrid: 'Híbrido Conectable',
    electric: 'Eléctrico',
    cng: 'CNG',
    TermsAndConditions: "Términos & Condiciones Legales",
    PrivacyPolicy: "Política de privacidad",
    Support: "Apoyo",
    success: 'Exito.',
    resetPasswordEmailSent: 'Su contraseña ha sido restablecida. Por favor revise su correo electrónico.',
    "There was a problem.": "Había un problemo.",
    "The email/password combination you entered is invalid. Please try again.": "La combinación de correo electrónico y contraseña que ingresó no es válida. Inténtalo de nuevo.",
    "There was a problem placing your bid. Please try again.": "Hubo un problema al realizar su oferta. Inténtalo de nuevo."
};

