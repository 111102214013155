import React from 'react';

import {useToast} from "react-native-toast-notifications";

export const useToastMessaging = () => {
    const toast = useToast();
    return {
        showSuccess: (title, text, linkText, linkAction) => toast.show(text, {
            type: "success",
            data: {title, linkText, linkAction},
            duration: 10000,
            swipeEnabled: false,
        }),
        showCaution: (title, text, linkText, linkAction) => toast.show(text, {
            type: "caution",
            data: {title, linkText, linkAction},
            duration: 10000,
            swipeEnabled: false,
        }),
        showError: (title, text, linkText, linkAction) => toast.show(text, {
            type: "danger",
            data: {title, linkText, linkAction},
            duration: 10000,
            swipeEnabled: false,
        }),
    };
};

