import {globalHeading} from "../../fonts";
import {StyleSheet, Text, TouchableOpacity} from "react-native";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from 'react-i18next';
import {getCurrency} from "locale-currency";
import {useColors, useConfig} from "../../store/whiteLabelConfig/util";

const BidSwipeButton = (props) => {
    const locale = useConfig().country;
    const bidIncrement = 100;
    const convertedIncrement = bidIncrement.toLocaleString(locale, {
        style: 'currency',
        currency: getCurrency(locale), minimumFractionDigits: 0, maximumFractionDigits: 0
    });
    const style = buildStyles(useColors());
    const {t} = useTranslation();

    return (<TouchableOpacity style={style.bidButton}
                              {...props}>
        <Text style={style.primaryButtonText}>{t('common:bid')} + {convertedIncrement}</Text>
    </TouchableOpacity>);
};

const buildStyles = colors => StyleSheet.create({
    bidButton: {
        padding: 20,
        paddingTop: 50,
        backgroundColor: colors.primaryShades["primary-color-base"]
    }, primaryButtonText: {
        color: colors.text["text-inverse-color"],
        textTransform: 'uppercase',
        ...globalHeading["subtitle-base-medium"]
    }
});

BidSwipeButton.propTypes = {
    onPress: PropTypes.func,
    disabled: PropTypes.bool
};

export default BidSwipeButton;
