import React, {useCallback} from 'react';
import {Text} from "react-native";
import PropTypes from "prop-types";
import {useColors} from "../store/whiteLabelConfig/util";
import {useTranslation} from "react-i18next";

const SECONDS_IN_A_MINUTE = 60;
const SECONDS_IN_A_DAY = 86400;
const SECONDS_IN_AN_HOUR = 3600;

const TimerText = (props) => {
    const {currentTime, endTime, testIDSuffix, style} = props;
    let colors = useColors();

    const { t } = useTranslation();

    const pad = (value) => {
        if (value?.toString().length < 2) {
            return `0${value}`.slice(-2);
        }
        return value;
    };

    const getFormattedTime = useCallback(() => {
        const secondsRemaining = Math.max(endTime - currentTime, 0);
        const days = Math.floor(secondsRemaining/ SECONDS_IN_A_DAY);
        const hours = Math.floor((secondsRemaining % SECONDS_IN_A_DAY) / SECONDS_IN_AN_HOUR);
        const minutes = Math.floor((secondsRemaining % SECONDS_IN_AN_HOUR) / SECONDS_IN_A_MINUTE);
        const seconds = Math.floor(secondsRemaining % SECONDS_IN_A_MINUTE);

        if(days > 1)
            return `${days} ${t('days')}`;
        if(days === 1)
            return `${days} ${t('day')}`;
        if(hours > 0)
            return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
        return `${pad(minutes)}:${pad(seconds)}`;
    }, [currentTime, endTime]);

    const getTimerColor = useCallback(() => {
        const secondsRemaining = Math.max(endTime - currentTime, 0);
        if (secondsRemaining > SECONDS_IN_A_MINUTE || secondsRemaining < 1) {
            return colors.text["text-primary-color"];
        } else {
            return colors.text["text-error-color"];
        }
    }, [currentTime, endTime]);

    return (<Text testID={`auction-timer-${testIDSuffix}`} style={[{color: getTimerColor()}, style]}>{getFormattedTime()}</Text>);
};
TimerText.propTypes = {
    endTime: PropTypes.number,
    currentTime: PropTypes.number,
    testIDSuffix: PropTypes.string,
    style: PropTypes.object
};

TimerText.defaultProps = {
    testIDSuffix: '',
    style: {
        marginLeft: 3,
        alignSelf: "center",
        fontFamily: "Roboto_500Medium",
        letterSpacing: 0.25,
        fontSize: 14,
        lineHeight: 21,
    }
};
export default TimerText;
