import {Image, StyleSheet, Text, View} from "react-native";
import {globalHeading, globalText} from "../../fonts";
import React, {useCallback, useState} from "react";
import LocationIcon from "../icons/location";
import PropTypes from "prop-types";
import AuctionStatus from "./auctionStatus";
import StatusPillLarge from "../statusPillLarge";
import AuctionActionContainer from "./auctionActionContainer";
import {useTranslation} from "react-i18next";
import {useColors, useConfig} from "../../store/whiteLabelConfig/util";

const AuctionLarge = (props) => {
    const item = props.item;
    const {t} = useTranslation();

    const locale = useConfig().country;
    let dist = t('km');

    if (locale === 'en-US'){
        dist = t('Miles');
    }

    /* istanbul ignore next */
    const testIDSuffix = item.id ? item.id.toString() : '';

    /* istanbul ignore next */
    const proxyAmount = item.proxy_bid ? item.proxy_bid.amount : null;
    const doorsCylindersTrimText = `${item.doors}dr ${item.drivetrain} • ${item.cylinders} Cyl • ${item.trim}`;
    const yearMakeModel = `${item.year} ${item.make} ${item.model}`;
    const odometer = `${item.odometer.toLocaleString(locale)} ${dist}`;
    const pickupLocation = ` ${item.pickup_city}, ${item.pickup_region}`;
    // We do not create a stylesheet for this yet because it would change it to a number.
    const colors = useColors();
    const auctionStatusStyle = {
        marginLeft: 3,
        alignSelf: "flex-end",
        fontFamily: "Roboto_500Medium",
        letterSpacing: 0.15,
        fontSize: 20,
        lineHeight: 30,
        color: colors.text["text-secondary-color"]
    };
    const highestBidder = t('highestBidder');
    const outBid = t('outBid');
    const reserveMet = t('reservedMet');
    const noReserve = t('noReserve');

    const [showBidButton, setShowBidButton] = useState(false);

    const enableBidButton = useCallback(() => setShowBidButton(true), []);
    const hideBidButton = useCallback(() => setShowBidButton(false), []);
    const styles = buildStyles(colors);

    return (
        <View testID={"auction-row-large"} style={styles.container} onMouseEnter={enableBidButton} onMouseLeave={hideBidButton}>
            <Image
                source={{uri: item.primary_image.acv_thumbnail}}
                style={styles.image}
            />
            <View testID={"details-container-large"} style={styles.detailsContainer}>
                <Text style={styles.yearMakeModelText}>{yearMakeModel}</Text>
                <Text style={styles.doorsCylindersTrimText}>{doorsCylindersTrimText}</Text>
                <Text testID={"odometer-text-large"} style={styles.odometerText}>{odometer}</Text>
                <View style={styles.bottomContainer}>
                    <View style={styles.locationContainer}>
                        <LocationIcon color={colors.greys["ui-grey-dark"]}/>
                        <Text style={styles.locationText}>{pickupLocation}</Text>
                    </View>
                    <View style={styles.timerBadgesContainer}>
                        <AuctionStatus
                            testIDSuffix={testIDSuffix}
                            style={auctionStatusStyle}
                            iconSize={15}
                            colorNormal={colors.text["text-secondary-color"]}
                            status={props.item.status}
                            currentTime={props.item.currentTime}
                            endTime={props.item["end_time"]}/>
                        <View style={styles.badgesContainer}>
                            {props.isNoReserve && <StatusPillLarge backgroundColor={colors.greys["ui-grey-x-dark"]}
                                                                   iconType={"reserve_met"}
                                                                   text={noReserve}/>}
                            {props.isReserveMet && <StatusPillLarge backgroundColor={colors.greys["ui-grey-x-dark"]}
                                                                    iconType={"reserve_met"}
                                                                    text={reserveMet}/>}
                            {props.isHighBidder && <StatusPillLarge backgroundColor={colors.text["text-success-color"]}
                                                                    iconType={"highest_bidder"}
                                                                    text={highestBidder}/>}
                            {props.isOutBid && <StatusPillLarge backgroundColor={"#FFC000"}
                                                                    iconType={"out_bid"}
                                                                    text={outBid}/>}
                        </View>
                    </View>
                </View>
            </View>
            <AuctionActionContainer isHighBidder={props.isHighBidder}
                                    isBidPermitted = {props.isBidPermitted}
                                    isAuctionEnded = {props.isAuctionEnded}
                                    isOutBid={props.isOutBid}
                                    highBid={props.highBid}
                                    proxyAmount={proxyAmount}
                                    floorPrice={props.item.floor_price}
                                    isActive={props.item.status === 'active'}
                                    showBidButton={showBidButton}
                                    onBidButtonShow={props.onBidButtonShow}
                                    onProxyButtonShow={props.onProxyButtonShow}/>
        </View>
    );
};

const buildStyles = colors => StyleSheet.create({
    container: {
        flexDirection: "row",
        height: 156,
    },
    image: {
        width: 156,
        height: 156,
    },
    detailsContainer: {
        backgroundColor: colors.background["background-2-color"],
        flex: 1,
        paddingTop: 11,
        paddingLeft: 12,
        flexDirection: "column"
    },
    yearMakeModelText: {
        ...globalHeading["subtitle-base-medium"],
        color: colors.text["text-secondary-color"]
    },
    doorsCylindersTrimText: {
        ...globalText["body-sm-regular"],
        color: colors.text["text-tertiary-color"]
    },
    odometerText: {
        ...globalText["body-sm-regular"],
        color: colors.text["text-tertiary-color"]
    },
    bottomContainer: {
        flex: 1,
        flexDirection: "row",
        paddingBottom: 13,
        paddingRight: 16,
    },
    locationContainer: {
        alignSelf: "flex-end",
        flex: 1,
        flexDirection: "row",
        alignItems: "baseline"
    },
    locationText: {
        ...globalText["body-sm-regular"],
        color: colors.text["text-tertiary-color"],
    },
    timerBadgesContainer: {
        alignSelf: "flex-end",
    },
    badgesContainer: {
        flexDirection: "row",
    },
    bidProxyContainer: {
        maxWidth: 154,
        backgroundColor: colors.background["background-1-color"],
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    currentBidHeader: {
        textTransform: "uppercase",
        color: colors.text["text-tertiary-color"],
        fontFamily: "Roboto_500Medium",
        fontSize: 10,
        lineHeight: 16,
        letterSpacing: 1.5
    },
    currentBid: {
        fontFamily: "Roboto_500Medium",
        letterSpacing: 0.15,
        fontSize: 20,
        lineHeight: 30
    }
});

AuctionLarge.propTypes = {
    item: PropTypes.object,
    currentTime: PropTypes.number,
    isHighBidder: PropTypes.bool,
    isBidPermitted: PropTypes.bool,
    isAuctionEnded: PropTypes.bool,
    highBid: PropTypes.number,
    isReserveMet: PropTypes.bool,
    isNoReserve: PropTypes.bool,
    isOutBid: PropTypes.bool,
    onBidButtonShow: PropTypes.func,
    onProxyButtonShow: PropTypes.func,
};
export default AuctionLarge;
