import React, {useCallback, useState} from 'react';
import {StyleSheet, TouchableOpacity} from "react-native";
import PropTypes from "prop-types";
import {useColors} from "../store/whiteLabelConfig/util";

const IconButton = (props) => {
    let colors = useColors();
    const style = buildStyle(props, colors);

    const [hovering, setHovering] = useState(false);
    const setHoverEnter = useCallback(() => setHovering(true), []);
    const setHoverExit = useCallback(() => setHovering(false), []);

    return (<TouchableOpacity
                style={[style.iconButton, style.round, hovering ? style.hovering : {}]}
                testID={props.testID}
                onMouseEnter={setHoverEnter}
                onMouseLeave={setHoverExit}
                onPress={!props.disabled && props.onPress}
                disabled={props.disabled}>

            {props.children}
        </TouchableOpacity>
    );
};

const buildStyle = (props, colors) => StyleSheet.create({
    iconButton: {
        padding: 5,
        opacity: props.disabled ? 0.2 : 1,
        justifyContent: "center",      
        borderRadius: 6,
        alignItems: "center"
    },
    round: {
        width: 30,
        height: 30,
        borderRadius: 45
    },
    hovering: {
        backgroundColor: colors.background["border-1-color"]
    }
});

IconButton.propTypes = {
    testID: PropTypes.string,
    disabled: PropTypes.bool,
    onPress: PropTypes.func,
    // Defines the content elements of the app container
    children: PropTypes.node.isRequired
};

IconButton.defaultProps = {
    disabled: false,
    testID: ''
};

export default IconButton;