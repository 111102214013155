/* istanbul ignore file */
import * as React from 'react';
import {
    Roboto_100Thin,
    Roboto_100Thin_Italic,
    Roboto_300Light,
    Roboto_300Light_Italic,
    Roboto_400Regular,
    Roboto_400Regular_Italic,
    Roboto_500Medium,
    Roboto_500Medium_Italic,
    Roboto_700Bold,
    Roboto_700Bold_Italic,
    Roboto_900Black,
    useFonts
} from "@expo-google-fonts/roboto";
import {getStore} from "./src/store";
import {Provider} from "react-redux";
import MainAppNavigation from "./src/navigation";
import initTranslations from "./src/translations";

import {ToastProvider} from "react-native-toast-notifications";
import {ToastMessage} from "./src/components/toastMessage";
import "./src/utils/errorWrapper"
import {SafeAreaProvider} from "react-native-safe-area-context";

initTranslations();
const store = getStore();

function App() {

    let [fontsLoaded] = useFonts({
        Roboto_100Thin,
        Roboto_100Thin_Italic,
        Roboto_300Light,
        Roboto_300Light_Italic,
        Roboto_400Regular,
        Roboto_400Regular_Italic,
        Roboto_500Medium,
        Roboto_500Medium_Italic,
        Roboto_700Bold,
        Roboto_700Bold_Italic,
        Roboto_900Black,
    });
    if (!fontsLoaded) {
        return null;
    }

    return (
        // eslint-disable-next-line react/jsx-no-bind
        <SafeAreaProvider>
            <ToastProvider placement={"bottom"} renderToast={(toastOptions) => (<ToastMessage toastOptions={toastOptions}/>)}>
                <Provider store={store}>
                    <MainAppNavigation/>
                </Provider>
            </ToastProvider>
        </SafeAreaProvider>
    );
}

export default App;
