import React from 'react';
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {globalText} from "../fonts";
import MenuScreen from "../screens/menu";
import LanguageSelectorScreen from "../screens/languageSelector";

const SettingsNavigation = () => {
    const Stack = createNativeStackNavigator();
    return (
        <Stack.Navigator
            screenOptions={{headerShown: false}}
            initialRouteName="settings"
        >
            <>
                <Stack.Screen name="settings" component={MenuScreen}
                              options={{title: null}}/>
                <Stack.Screen name="langselection" component={LanguageSelectorScreen}
                              options={{
                                  headerShown: true,
                                  headerBackTitle: "",
                                  headerBackTitleVisible: true,
                                  title: null,
                                  headerBackTitleStyle: globalText["button"],
                                  headerStyle: {color: 'red'}
                              }}
                />
            </>
        </Stack.Navigator>
    );
};

export default SettingsNavigation;