export default {
    languageSelector: 'Select Language',
    marketplace: 'Marketplace',
    results: 'Results',
    bid: 'Bid',
    menu: 'Menu',
    liveAuctions: 'Live Auctions',
    logout: 'Logout',
    privacyPolicy: 'Privacy Policy',
    changeLanguage: 'Change Language',
    emailAddress: 'Email Address',
    password: 'Password',
    login: 'Log In',
    forgotPassword: 'Forgot Password?',
    requestResetPassword: 'Request Password Reset',
    NetworkError: 'Network Error',
    wrongCredentials: 'Email address and/or password is wrong',
    noServerConnection: 'Could not connect to Server. Please check your connection and try again later',
    unablePasswordReset: 'Unable to reset password',
    resetPassword: 'Reset Your Password',
    resetPasswordDesc: 'To reset your password, please enter the email address associated with your account.',
    emailSent: 'An email has been sent with instructions to reset your password',
    requestReset: 'Request Reset',
    returnToLogin: 'Return to Log In',
    vehicleDetails: 'Vehicle Details',
    views: 'Views',
    bids: 'Bids',
    photo: 'Photo',
    ending: 'ENDING',
    bidding: 'Bidding',
    vin: 'VIN',
    city: 'City',
    odometer: 'Odometer',
    transmission: 'Transmission',
    automatic: 'Automatic',
    manual: "Manual",
    trim: 'Trim',
    drivetrain: 'Drivetrain',
    engine: 'Engine',
    fuelType: 'Fuel Type',
    year: 'Year',
    make: 'Make',
    model: 'Model',
    color: 'Color',
    auctionId: 'Auction ID',
    auctionDate: 'Auction Date',
    sold: 'SOLD',
    unsold: 'UNSOLD',
    swipeToBid: 'Swipe to bid',
    currentBid: 'Current Bid',
    yourBid: 'Your Bid',
    timeRemaining: 'Time Remaining',
    vehicle: 'Vehicle',
    cancel: 'Cancel',
    highBid: 'High Bid',
    active: 'active',
    remaining: 'remaining',
    reservedPrice: 'Reserve Price',
    highestBid: 'Highest Bid',
    highestBidder: 'Highest Bidder',
    reservedMet: 'Reserve Met',
    noReserve: 'No Reserve',
    version: 'Version',
    outBid: 'OutBid',
    auctionDetails: "Auction Details",
    Miles: 'Miles',
    km: 'km',
    day: 'day',
    days: 'days',

    // COLORS
    black: 'Black',
    white: 'White',
    silver: 'Silver',
    red: 'Red',
    gray: 'Gray',
    green: 'Green',
    blue: 'Blue',
    purple: 'Purple',
    orange: 'Orange',
    beige: 'Beige',
    brown: 'Brown',
    yellow: 'Yellow',
    gold: 'Gold',
    blueLight: 'Blue (Light)',
    blueDark: 'Blue (Dark)',
    tan: 'Tan',
    maroon: 'Maroon',
    pearl: 'Pearl',
    pink: 'Pink',

    // Proxy Bidding
    proxyBid: 'Proxy Bid',
    setProxy: 'Set Proxy',
    swipeProxy: 'Swipe To Set Proxy',
    editProxy: 'Edit Proxy',
    yourProxy: 'Your Proxy',
    proxyMultiples: 'Bid In Multiples Of $50',
    confirmProxyTitle: "Confirm Proxy Bid",
    confirmProxySubtitle: 'Please confirm your proxy amount',
    errorMismatch: 'Error! Amounts do not match. Update your proxy.',
    errorProxyIncrement: 'Error! Bid In Multiples Of $50.',
    errorLowProxy: 'Error! Proxy Is Too Low. Increase Your Proxy.',
    errorHighProxy:  'Error! Proxy Is Too High. Decrease Your Proxy.',
    errorProxyGeneric: 'Error! Something went wrong. Please try again later.',

    // Fuel Type
    gas: 'Gas',
    flex: 'Flex',
    diesel: 'Diesel',
    hybrid: 'Hybrid',
    lpg: 'LPG',
    plugInHybrid: 'Plug-in Hybrid',
    electric: 'Electric',
    cng: 'CNG',
    TermsAndConditions: "Legal Terms & Conditions",
    PrivacyPolicy: "Privacy Policy",
    Support: "Support",
    success: 'Success.',
    resetPasswordEmailSent: 'Your password has been reset. Please check your email.',
    "There was a problem.": "There was a problem.",
    "The email/password combination you entered is invalid. Please try again.": "The email/password combination you entered is invalid. Please try again.",
    "There was a problem placing your bid. Please try again.":"There was a problem placing your bid. Please try again."
};

