import React from 'react';
import {StyleSheet, Text, TouchableOpacity} from "react-native";
import PropTypes from "prop-types";
import {LinearGradient} from "expo-linear-gradient";
import {globalText} from "../fonts";
import {useColors} from "../store/whiteLabelConfig/util";

const PrimaryButton = (props) => {
    const {padding} = props;
    let colors = useColors();
    const style = buildStyle(props, padding, colors);

    return (
        <TouchableOpacity {...props}
                          style={[style.primaryButton, props.style]}
                          onPress={!props.disabled && props.onPress}
                          testID={props.testID}>
            <LinearGradient
                testID={props.testID? props.testID+"Gradient": "undefinedGradiant" }
                start={{x: 0.15, y: 0.45}}
                end={{x: 0.875, y: 0.55}}
                colors={colors.gradient["primary-gradient"]}
                style={style.gradient}>
                <Text style={style.primaryButtonText}>{props.title}</Text>
            </LinearGradient>
        </TouchableOpacity>
    );
};

const buildStyle = (props, padding, colors) => StyleSheet.create({
    primaryButton: {
        alignItems: "center",
        opacity: props.disabled ? 0.2 : 1,
    },
    gradient: {
        padding: padding,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 6,
    },
    primaryButtonText: {
        ...globalText.button,
        color: colors.text["text-inverse-color"],
    }
});

PrimaryButton.propTypes = {
    title: PropTypes.string,
    disabled: PropTypes.bool,
    onPress: PropTypes.func,
    padding: PropTypes.number,
    testID: PropTypes.string,
    style: PropTypes.any

};

PrimaryButton.defaultProps = {
    padding: 15,
};

export default PrimaryButton;
