import React from 'react';
import {Image, StyleSheet, Text, View} from "react-native";
import {globalText} from "../../fonts";
import PropTypes from "prop-types";
import AuctionStatus from "../auction/auctionStatus";
import PrimaryButton from "../primaryButton";
import Gavel from "../icons/gavel";
import Eye from "../icons/eye";
import Camera from "../icons/camera";
import {useTranslation} from "react-i18next";
import {getCurrency} from "locale-currency";
import useLargeFormFactorCheck from "../../utils/responsive";
import {useColors, useConfig} from "../../store/whiteLabelConfig/util";

const VehicleDetailsOverview = (props) => {

    const {t} = useTranslation();
    const locale = useConfig().country;
    const bidIncrement = 100;
    const {details, status, currentTime, endTime, showBidModal, showProxyModal, isHighBidder} = props;
    const highBid = details.bid_amount ? `${details.bid_amount.toLocaleString(locale, {
        style: 'currency',
        currency: getCurrency(locale), minimumFractionDigits: 0, maximumFractionDigits: 0
    })}` : `${details.start_price.toLocaleString(locale,
        {style: 'currency', currency: getCurrency(locale), minimumFractionDigits: 0, maximumFractionDigits: 0})}`;
    const proxyAmount = details.proxy_bid ? details.proxy_bid.amount.toLocaleString(locale, {
        style: 'currency',
        currency: getCurrency(locale), minimumFractionDigits: 0, maximumFractionDigits: 0
    }) : null;
    const proxyButtonTitle = proxyAmount ? t('editProxy') : t('setProxy');
    const numPhotos = "1 " + t("common:photo");
    const numViews = `${details.view_count} ${t("common:views")}`;
    const numBids = `${details.bid_count} ${t("common:bids")}`;

    const isAuctionEnded = (endTime <= currentTime || status !== 'active');
    const isBiddingPermitted = JSON.parse(useConfig().canBid);
    const bidDisabled = isHighBidder || !isBiddingPermitted || isAuctionEnded;
    const proxyDisabled = !isBiddingPermitted || isAuctionEnded;
    const bidButtonTitle = t('currentBid') + " + " + bidIncrement.toLocaleString(locale, {
        style: 'currency',
        currency: getCurrency(locale), minimumFractionDigits: 0, maximumFractionDigits: 0
    });

    const isDesktopOrLaptop = useLargeFormFactorCheck();

    let timerTitle = t('auction');
    if (status === 'active') {
        timerTitle = t('timeRemaining');
    }
    const currentBidTitle = t("currentBid");

    let colors = useColors();
    const styles = buildStyles(colors);
    return (
        <View testID="vehicle-details-overview" style={styles.container}>
            <View style={styles.overviewBanner}>
                <View style={styles.overviewContainer}>
                    <Gavel widthHeight={13} color={colors.greys["ui-grey-dark"]}/>
                    <Text style={styles.overviewDetailsText}>{numBids}</Text>
                    <View style={styles.detailContainer}>
                        <Eye widthHeight={13} color={colors.greys["ui-grey-dark"]}/>
                        <Text style={styles.overviewDetailsText}>{numViews}</Text>
                    </View>
                    <View style={styles.detailContainer}>

                        <Camera widthHeight={13} color={colors.greys["ui-grey-dark"]}/>
                        <Text style={styles.overviewDetailsText}>{numPhotos}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.imageContainer}>
                <Image source={{uri: details.primary_image.acv_thumbnail}}
                       style={styles.image}/>
            </View>
            <View style={ isDesktopOrLaptop ? styles.bidStatusContainer : styles.bidStatusContainerSmall}>
                <View style={styles.highBidTextContainer}>
                    <Text style={styles.currentBidTitle}>{currentBidTitle}</Text>
                    <Text style={styles.currentBidText}>{highBid}</Text>
                </View>
                <View style={styles.auctionStatusContainer}>
                    <Text style={styles.auctionStatusTitle}>{timerTitle}</Text>
                    <AuctionStatus
                        testIDSuffix={details.id.toString()}
                        showIcon={false}
                        style={{
                            fontFamily: "Roboto_700Bold",
                            fontSize: 39,
                            lineHeight: 39,
                            color: colors.text["text-primary-color"]
                        }}
                        status={status}
                        currentTime={currentTime}
                        endTime={endTime}/>
                </View>
                <View style={styles.actionContainer}>
                        <PrimaryButton
                            testID={'bid-button'}
                            onPress={showBidModal}
                            title={bidButtonTitle}
                            disabled={bidDisabled}
                        />
                        <PrimaryButton
                            testID={'bid-proxy'}
                            onPress={showProxyModal}
                            title={proxyButtonTitle}
                            disabled={proxyDisabled}
                       />
                </View>
            </View>
        </View>
    );
};

const buildStyles = colors => StyleSheet.create({
    container: {
        width: "100%",
        alignSelf: "center",
        paddingTop: 20,
    },
    overviewBanner: {
        backgroundColor: colors.background["background-2-color"],
        flexDirection: "column",
        padding: 20,
    },
    overviewContainer: {
        flexDirection: "row",
        alignSelf: "center",
        alignItems: "center"
    },
    overviewDetailsText: {
        ...globalText["body-sm-regular"],
        color: colors.text["text-tertiary-color"],
        paddingLeft: 5,
        paddingRight: 5,
    },
    detailContainer: {
        flexDirection: "row",
        paddingLeft: 5,
        alignSelf: "center",
        alignItems: "center",
        borderLeftWidth: 1,
        borderLeftColor: colors.text["text-tertiary-color"]
    },
    imageContainer: {
        padding: 10,
        width: 379,
        height: 379,
        flexDirection: "row",
        alignSelf: 'center'
    },
    image: {
        width: "100%",
        height: "100%",
        resizeMode: "contain"
    },
    bidStatusContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
        paddingBottom: 15,
        backgroundColor: colors.background["background-2-color"],
    },
    bidStatusContainerSmall: {
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: 15,
        backgroundColor: colors.background["background-2-color"],
    },
    highBidTextContainer: {
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 15,
        paddingHorizontal: 15,
    },
    currentBidTitle: {
        fontFamily: "Roboto_700Bold",
        fontSize: 13,
        lineHeight: 21,
        color: colors.text["text-secondary-color"],
        textTransform: "uppercase",
    },
    currentBidText: {
        fontFamily: "Roboto_700Bold",
        fontSize: 39,
        lineHeight: 39,
        color: colors.text["text-primary-color"],
    },
    auctionStatusContainer: {
        flexDirection: "column",
        paddingTop: 15,
        paddingHorizontal: 15,
    },
    auctionStatusTitle: {
        fontFamily: "Roboto_700Bold",
        fontSize: 13,
        lineHeight: 21,
        color: colors.text["text-secondary-color"],
        textTransform: "uppercase",
    },
    actionContainer: {
        width: 350,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: 15,
        paddingHorizontal: 15,
    },
    timerRowContainer: {
        flex: 1,
        marginRight: "auto"
    },
});



VehicleDetailsOverview.propTypes = {
    details: PropTypes.object,
    status: PropTypes.string,
    currentTime: PropTypes.number,
    endTime: PropTypes.number,
    showBidModal: PropTypes.func,
    showProxyModal: PropTypes.func,
    isHighBidder: PropTypes.bool,
};

export default VehicleDetailsOverview;
