import {StyleSheet} from "react-native";
import {globalHeading, globalText} from "../../fonts";

export const buildStyles = (colors) => StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        backgroundColor: colors.greys["ui-white-color"]
    },
    supportContainer: {
        marginTop: 10,
        padding: 10,
        paddingLeft: "10%",
        elevation: 4,
        width: "80%",
        maxWidth: 900,
        alignItems: "flex-start",
        alignSelf: "flex-start"
    },

    title: {
        paddingVertical: 10,
        ...globalHeading["h5-medium"]
    },
    contactIntro: {
        ...globalHeading["subtitle-base-regular"],
        paddingBottom: 15
    },
    contactTypes: {
        paddingBottom: 35,
    },
    contactType: {
        flex: 1,
        flexDirection: "row",
        paddingBottom: 10,
        paddingLeft: 35,
    },
    contactTitle: {
        ...globalHeading["subtitle-base-medium"],
        minWidth: 80,
    },
    contactValue: {
    },
    hoursTitle: {
        ...globalHeading["h5-medium"],
        paddingBottom: 15
    },
    hoursDay: {
        ...globalHeading["subtitle-base-medium"],
        paddingLeft: 15,
        paddingBottom: 5,
    },
    hoursValue: {
        paddingLeft: 30,
        paddingBottom: 10,
    },
    paragraphTitle: {
        ...globalText["body-sm-bold"],
        paddingRight: 5,
    }
});