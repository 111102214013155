import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {Image, StyleSheet, Text, View} from "react-native";
import {useColors} from "../../store/whiteLabelConfig/util";
import {useTranslation} from "react-i18next";
import VehiclePlaceholderIcon from "../icons/vehiclePlaceholder";
import {globalHeading, globalText} from "../../fonts";

export const Listing = ({listing}) => {
    const {t} = useTranslation();
    const styles = buildStyles(useColors());

    const getStatusStyle = useCallback(() => {
        switch (listing.status.value) {
            case 'max_launches':
            case 'expired':
                return styles.statusExpired;
            case "complete":
                return styles.statusReady;
            default:
                return styles.statusMissing;
        }
    }, [listing]);

    const getStatus = useCallback(() => {
        switch (listing.status.value) {
            case 'incomplete':
            case 'missing_photos':
                return t("Missing Info");
            case "complete":
                return t("Ready To Launch");
            default:
                return t("Unable To Launch");
        }
    }, [listing]);
    const yearMakeModel = `${listing.year} ${listing.make} ${listing.model}`;
    const vin = listing.vin;
    return (
        <View style={styles.container}>
            <View style={styles.imageRow}>
                <View style={styles.vehicleImage}>
                { listing["primary_image"] ? <Image style={styles.vehicleImage} source={{uri: listing["primary_image"].url}}/>
                    : <VehiclePlaceholderIcon height={94}/>}
                </View>

                <View style={styles.vehicleData}>
                    <Text testID={`yearMakeModel-${listing.id}`} style={styles.yearMakeModel}>{yearMakeModel}</Text>
                    <Text testID={`vin-${listing.id}`} style={styles.vin}>{vin}</Text>
                </View>
            </View>
            <View style={getStatusStyle()}><Text testID={`status-${listing.id}`}style={styles.statusText}>{getStatus()}</Text></View>
            <View style={styles.bottomDivider}/>
        </View>

    );
};
const buildStyles = colors => StyleSheet.create({
    container: {
        width: "100%",
        height: 157,
        flex: 1,
        flexDirection: "column",
        alignItems: "flex-start",
        paddingHorizontal: 16,
        marginTop: 14,
    },
    imageRow: {
        flexDirection: "row",
        marginBottom: 11,
    },
    vehicleImage: {
        height: 94,
        width: 94,
        borderRadius: 3,
    },
    vehicleData: {
      flex: 1,
        paddingLeft: 12,
      flexDirection: "column",
      alignItems: "flex-start"
    },
    yearMakeModel: {
        ...globalHeading["subtitle-sm-medium"],
        color: colors.text["text-primary-color"],
    },
    vin: {
        ...globalText["body-sm-regular"],
        color: colors.text["text-secondary-color"]
    },
    statusReady: {
        paddingHorizontal: 6,
        paddingVertical: 4,
        borderRadius: 3,
        backgroundColor: colors.secondaryShades["secondary-color-x-light"],
        color: colors.informational["ui-informational-color-dark"],
    },
    statusMissing: {
        paddingHorizontal: 6,
        paddingVertical: 4,
        borderRadius: 3,
        backgroundColor: colors.error["ui-error-color-x-light"],
        color: colors.error["ui-error-color-x-dark"]
    },
    statusExpired: {
        paddingHorizontal: 6,
        paddingVertical: 4,
        borderRadius: 3,
        backgroundColor: colors.secondaryShades["secondary-color-x-light"],
        color: colors.informational["ui-informational-color-dark"],
    },
    statusUnknown: {
        paddingHorizontal: 6,
        paddingVertical: 4,
        borderRadius: 3,
        backgroundColor: colors.secondaryShades["secondary-color-x-light"],
        color: colors.informational["ui-informational-color-dark"],
    },
    bottomDivider: {
        height: 1,
        width: "100%",
        marginTop: 18,
        backgroundColor: colors.greys["ui-grey-base-dark"]
    }
});

Listing.propTypes = {
    listing: PropTypes.object
};
