import {setJSExceptionHandler} from "react-native-exception-handler";
import {ErrorHandler} from "./errorHandler";
import {getPlatform} from "./platform";

/**
 * 1. setJSExceptionHandler is to set customized error handler in global ErrorUtils
 * However ErrorUtils is bound to the React Native embedded usage of JavaScriptCore engine - not the browser's Javascript engine,
 * so we should simply avoid using ErrorUtils altogether in RN4W (react native for web),
 * by using standard window.onError method
 * https://github.com/necolas/react-native-web/issues/1621
 * 2. Expo Updates methods for fetching new JS bundle and reloading are not supported in DEV mode
 * ( https://docs.expo.dev/versions/latest/sdk/updates/)
 */
/* istanbul ignore next */
// eslint-disable-next-line no-undef
if (!__DEV__) {
    if (getPlatform() !== "web") {
        setJSExceptionHandler(ErrorHandler, true);
    } else {
        window.onerror = function (msg, src, lineno, colno, error) {
            ErrorHandler(error);
        };
    }
}
