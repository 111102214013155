import {
    AUTH_COMPLETE,
    AUTH_LOGOUT,
    AUTH_REFRESH,
    AUTH_REFRESH_FAILED,
    AUTH_REFRESH_START,
    AUTH_SUCCESS,
} from "./actionTypes";

export const deleteSession = () => ({ type: AUTH_LOGOUT });
export const initSession = (user, tokens) => ({
  type: AUTH_SUCCESS,
  user,
  tokens,
});
export const loginComplete = () => ({type: AUTH_COMPLETE});
export const refreshStart = () => ({ type: AUTH_REFRESH_START });
export const refreshFailed = () => ({ type: AUTH_REFRESH_FAILED });
export const refreshSession = (tokens) => ({
  type: AUTH_REFRESH,
  tokens,
});
