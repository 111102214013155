import {Platform, StyleSheet} from "react-native";
import {globalText} from "../../fonts";

const buildMobileStyles = (colors) => ({
    container: {
        flex: 1,
        flexDirection: "column",
        backgroundColor: colors.greys["ui-white-color"]
    },
    loginContainer: {
        marginTop: "40%",
        padding: 10,
        elevation: 4,
        width: "90%",
        alignItems: "center",
        alignSelf: "center"
    },
    loginFormContainer: {
        paddingTop: 30,
        paddingBottom: 25,
        width: "90%",
    },
    userInput: {
        marginBottom: 15
    },
    passwordInput: {
        marginBottom: 15
    },
    loginError: {
        ...globalText["body-base-regular"],
        color: colors.text["text-error-color"],
        padding: 5,
        alignSelf: "center"
    },
    linksContainer: {
        marginTop: 10,
        flex: 1,
        minHeight: 60,
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column"
    },
    forgotPasswordContainer: {
        marginTop: 5
    }
});
const buildWebStyles = (colors) => ({
    container: {
        flex: 1,
        flexDirection: "column",
        backgroundColor: colors.greys["ui-white-color"]
    },
    loginContainer: {
        padding: 40,
        borderRadius: 4,
        marginTop: "12vh",
        width: "90%",
        alignItems: "center",
        alignSelf: "center"
    },
    loginFormContainer: {
        paddingTop: 30,
        width: "100%",
        maxWidth: 430,
    },
    loginError: {
        ...globalText["body-base-regular"],
        color: colors.text["text-error-color"],
        padding: 10,
        alignSelf: "center"
    },
    linksContainer: {
        marginTop: "10px",
        flex: 1,
        minHeight: 70,
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column"
    },
    forgotPasswordContainer: {
        marginTop: "15px"
    }
});
export const buildStyles = (colors) => {
    return StyleSheet.create({
        ...Platform.select({
            ios: buildMobileStyles(colors),
            android: buildMobileStyles(colors),
            default: buildWebStyles(colors)
        })
    });
};