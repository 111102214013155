/*
    These are the global headings defined in Figma in the
    "ACV Platform Molecules & Organisms 2.0" design.
 */
const globalHeading = {
    "h5-medium": {
        fontFamily: "Roboto_400Regular",
        fontSize: 24,
        lineHeight: 36,
    },
    "h6-medium": {
        fontFamily: "Roboto_500Medium",
        fontSize: 20,
        lineHeight: 30,
    },
    "subtitle-base-medium": {
        fontFamily: "Roboto_500Medium",
        fontSize: 16,
        lineHeight: 24,

    },
    "subtitle-base-regular": {
        fontFamily: "Roboto_400Regular",
        fontSize: 16,
        lineHeight: 24,

    },
    "subtitle-sm-medium": {
        fontFamily: "Roboto_500Medium",
        fontSize: 14,
        lineHeight: 18,
    },
    "subtitle-sm-regular": {
        fontFamily: "Roboto_400Regular",
        fontSize: 14,
        lineHeight: 21,
    }
};

/*
    These are the Global fonts defined in Figma in the
    "ACV Platform Molecules & Organisms 2.0" design.
 */
const globalText = {
    "body-base-regular": {
        fontFamily: "Roboto_400Regular",
        fontSize: 16,
        lineHeight: 24,
        letterSpacing: 0.15,
    },
    "body-sm-bold": {
        fontFamily: "Roboto_700Bold",
        fontSize: 14,
        lineHeight: 21,
        letterSpacing: 0.15,
    },
    "body-sm-regular": {
        fontFamily: "Roboto_400Regular",
        fontSize: 14,
        lineHeight: 21,
        letterSpacing: 0.25,
    },
    "button": {
        fontFamily: "Roboto_500Medium",
        fontSize: 14,
        lineHeight: 14,
        textTransform: "uppercase",
        letterSpacing: 1.25
    },
    "overline": {
        fontFamily: "Roboto_500Medium",
        fontSize: 10,
        lineHeight: 16,
        textTransform: "uppercase",
        letterSpacing: 1.5
    },
    "caption-base-regular": {
        fontFamily: "Roboto_400Regular",
        fontSize: 12,
        lineHeight: 18,
        letterSpacing: 0.4,
        textTransform: "capitalize",
    },
    "caption-base-bold": {
        fontFamily: "Roboto_700Bold",
        fontSize: 12,
        lineHeight: 14,
        textTransform: "uppercase",
    },
    "caption-sm-regular": {
        fontFamily: "Roboto_400Regular",
        fontSize: 10,
        lineHeight: 18,
        letterSpacing: 0.4,
        textTransform: "capitalize",
    },
    "status-badge-base": {
        fontFamily: "Roboto_400Regular",
        fontSize: 12,
        lineHeight: 12,
        letterSpacing: 0.4,
        textTransform: "capitalize",
    },
};

export {globalText, globalHeading};
