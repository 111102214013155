import {getReleaseChannel} from "./channel";

export const getConfig = () => {
    switch (getReleaseChannel()) {
        case 'prod':
            return configProd;
        case 'staging':
            return configStaging;
        default:
            // eslint-disable-next-line no-undef
            if(__DEV__) {
                return configDev;
            }
            return configLatest;
    }
};

export const getCodeFlag = (slug) => {
    let codeConfig;
    switch (getReleaseChannel()) {
        case 'prod':
            codeConfig = configProd;
            break;
        case 'staging':
            codeConfig = configStaging;
            break;
        default:
            // eslint-disable-next-line no-undef
            if(__DEV__) {
                codeConfig = configDev;
            }
            codeConfig = configLatest;
    }
    return codeConfig && codeConfig.codeFlag[slug];
};

const configDev =  {
    openAutoUrl: "http://localhost:19006",
    authApiUrl: "https://mobile-gateway.latest.acvauctions.com",
    acvApiUrl: "https://easy-pass.latest.acvauctions.com/api/acvapi/v2",
    acvApi3Url: "https://easy-pass.latest.acvauctions.com/api/acvapi/v3",
    privacyPolicyURL: "https://www.latest.acvauctions.com/privacy-policy",
    platformApiUrl: "https://platform-latest.gateway.nonprod.acvauctions.com",
    auctionApiUrl: "https://easy-pass.latest.acvauctions.com/bff",
    codeFlag: {
        unitTestFlag: true,
        showInventory: true
    }
};

const configLatest =  {
    openAutoUrl: "https://latest.open.auto",
    authApiUrl: "https://mobile-gateway.latest.acvauctions.com",
    acvApiUrl: "https://easy-pass.latest.acvauctions.com/api/acvapi/v2",
    acvApi3Url: "https://easy-pass.latest.acvauctions.com/api/acvapi/v3",
    privacyPolicyURL: "https://www.latest.acvauctions.com/privacy-policy",
    platformApiUrl: "https://platform-latest.gateway.nonprod.acvauctions.com",
    auctionApiUrl: "https://easy-pass.latest.acvauctions.com/bff",
    codeFlag: {
        unitTestFlag: true,
        showInventory: true
    }
};

const configStaging = {
    openAutoUrl: "https://staging.open.auto",
    authApiUrl: "https://mobile-gateway.staging.acvauctions.com",
    acvApiUrl: "https://easy-pass.staging.acvauctions.com/api/acvapi/v2",
    acvApi3Url: "https://easy-pass.staging.acvauctions.com/api/acvapi/v3",
    privacyPolicyURL: "https://www.staging.acvauctions.com/privacy-policy",
    platformApiUrl: "https://platform-staging.gateway.nonprod.acvauctions.com",
    auctionApiUrl: "https://easy-pass.staging.acvauctions.com/bff",
    codeFlag: {
        unitTestFlag: true,
        showInventory: true
    }
};

const configProd = {
    openAutoUrl: "https://open.auto",
    authApiUrl: "https://mobile-gateway.acvauctions.com",
    acvApiUrl: "https://easy-pass.acvauctions.com/api/acvapi/v2",
    acvApi3Url: "https://easy-pass.acvauctions.com/api/acvapi/v3",
    privacyPolicyURL: "https://www.acvauctions.com/privacy-policy",
    platformApiUrl: "https://platform.gateway.acvauctions.com",
    auctionApiUrl: "https://easy-pass.acvauctions.com/bff",
    codeFlag: {
        unitTestFlag: false,
        showInventory: false
    }
};
