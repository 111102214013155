import {TouchableOpacity, View} from "react-native";
import React, {useCallback, useRef} from "react";
import PropTypes from "prop-types";
import {Swipeable} from "react-native-gesture-handler";
import BidSwipeButton from "../bid/bidSwipeButton";
import AuctionSmall from "./auctionSmall";
import useLargeFormFactorCheck from "../../utils/responsive";
import AuctionLarge from "./auctionLarge";
import {useConfig} from "../../store/whiteLabelConfig/util";

const AuctionRow = (props) => {

    const {onOpen, onClose, onBidSwipeShow, navigation, onBidButtonShow, onProxyButtonShow} = props;

    const swipeRef = useRef();
    const auctionDetails = props.item;
    const highBid = auctionDetails['high_bid'] != null ? auctionDetails['high_bid'] : auctionDetails['start_price'];
    const isHighBidder = auctionDetails['status_pills'].includes('high_bidder');

    const currentTime = auctionDetails.currentTime;
    const endTime = auctionDetails.end_time;
    const isAuctionEnded = endTime <= currentTime;

    const isBidPermitted = JSON.parse(useConfig().canBid);
    const isProxyPermitted = JSON.parse(useConfig().canBid);
    const isReserveMet = auctionDetails['status_pills'].includes('reserve_met');
    const isNoReserve = auctionDetails['status_pills'].includes('no_reserve');
    const isOutBid = auctionDetails['status_pills'].includes('out_bid');
    const biddingDisabled = !isBidPermitted || isHighBidder || isAuctionEnded;

    const handleOpen = useCallback(() => {
        onOpen({...auctionDetails, highBid, closeAction: swipeRef.current.close});
    }, [auctionDetails, highBid]);

    const handleClose = useCallback(() => {
        onClose({...auctionDetails, highBid, closeAction: swipeRef.current.close});
    }, [auctionDetails, highBid]);

    /* istanbul ignore next */
    const renderRightButtons = useCallback(() => {
        if (biddingDisabled) {
            return null;
        }
        return <BidSwipeButton onPress={onBidSwipeShow} disabled={biddingDisabled}/>;
    }, [props]);

    const showVehicleDetails = useCallback(() => {
        navigation.push('vdp', {id: auctionDetails.id});
    }, []);

    const isDesktopOrLaptop = useLargeFormFactorCheck();

    return (
        <View style={{margin: 10}}>
            <TouchableOpacity onPress={showVehicleDetails} testID={'auction-' + auctionDetails.id}>
                <Swipeable
                    ref={swipeRef}
                    onSwipeableWillOpen={handleOpen}
                    onSwipeableClose={handleClose}
                    renderRightActions={renderRightButtons}>
                    {isDesktopOrLaptop ? (
                        <AuctionLarge item={props.item}
                                      isHighBidder={isHighBidder}
                                      isBidPermitted={isBidPermitted}
                                      isProxyPermitted={isProxyPermitted}
                                      isAuctionEnded={isAuctionEnded}
                                      isReserveMet={isReserveMet}
                                      isNoReserve={isNoReserve}
                                      isOutBid={isOutBid}
                                      highBid={highBid}
                                      onBidButtonShow={onBidButtonShow}
                                      onProxyButtonShow={onProxyButtonShow}/>

                    ) : (
                        <AuctionSmall item={props.item}
                                      isHighBidder={isHighBidder}
                                      isReserveMet={isReserveMet}
                                      isNoReserve={isNoReserve}
                                      isOutBid={isOutBid}
                                      highBid={highBid}/>
                    )}
                </Swipeable>
            </TouchableOpacity>
        </View>
    );
};

AuctionRow.propTypes = {
    item: PropTypes.object,
    currentTime: PropTypes.number,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onBidSwipeShow: PropTypes.func,
    onBidButtonShow: PropTypes.func,
    onProxyButtonShow: PropTypes.func,
    navigation: PropTypes.object,
};
export default AuctionRow;
