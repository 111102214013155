import * as React from "react";
import {ScrollView, Text, View} from "react-native";
import {SafeAreaView} from "react-native-safe-area-context";
import AnonymousTopBarNavigation from "../../navigation/anonymousTopBarNavigation";
import {buildStyles} from "./styles";
import NumberedText from "../../components/numberedText";
import ExternalLink from "../../components/ExternalLink";
import {useColors} from "../../store/whiteLabelConfig/util";

const PrivacyPolicyScreen = () => {
    const styles = buildStyles(useColors());

    return (
        <SafeAreaView style={styles.container}>
            <AnonymousTopBarNavigation tabName={"Privacy Policy"}/>
            <ScrollView>
                <View style={styles.privacyContainer}>
                    <View>
                        <Text style={styles.smallTitle}>OPEN AUTO PRIVACY POLICY</Text>
                        <Text style={styles.title}>1. About this Privacy Policy</Text>
                        <Text style={styles.paragraph}>Thank you for using ACV Auctions! Here at ACV Auctions
                            (collectively ACV Auctions Inc., our affiliates and subsidiaries, hereinafter referred to as
                            “ACV Auctions,” “us,” “we,” “our,” or “Company”) privacy is very important to us. This ACV
                            Auctions Privacy Policy (this “Privacy Policy”) describes how ACV Auctions collects, uses,
                            and handles your information when you use our website, app, software, and other services
                            (“Services”). By submitting personal information to ACV Auctions, by conducting business
                            with ACV Auctions or by using our Services, you (hereinafter referred to as “you,” or
                            “Customer”) agree to the terms of this Privacy Policy and expressly consent to the
                            collection, processing, use and disclosure of your personal information for the purposes set
                            out in this Privacy Policy.</Text>
                        <Text style={styles.paragraph}>This Privacy Policy does not apply to persons outside the United
                            States, including in the EEA/ European Union. ACV Auctions does not intend to offer goods
                            and services to, or collect personal information from, persons outside the United States. If
                            you are a California resident, you may have additional privacy rights as set forth
                            below.</Text>
                        <Text style={styles.paragraph}>At times, ACV handles personal information collected by another
                            company for the sole purpose of performing a service for that company, such as scheduling
                            and performing inspections of off-lease vehicles. We refer to these companies as our
                            “Clients,” and ACV processes this information only at the Client’s direction. In these
                            instances, we follow the Client’s privacy terms.</Text>
                        <Text style={styles.paragraph}>This Privacy Policy does not apply to other companies’ websites,
                            services or links that you may access to via our Services. Information collected or received
                            by these third parties is subject to third-party privacy policies. If you are located
                            outside the United States, please note that personal information collected via our Services
                            may be transferred to the United States and other countries which might not provide an
                            equivalent level of protection as the data protection laws in your own country.</Text>
                        <Text style={styles.paragraph}>This Privacy Policy is integrated into our Terms of Service and
                            certain other terms of use which we may, from time to time, require you to accept as a
                            condition of using our Services.</Text>
                        <Text style={styles.title}>2. Information We Collect</Text>
                        <Text style={styles.paragraph}>ACV Auctions collects information about our Customers in three
                            ways: (2.1) information you provide to us, (2.2) information collected through automated
                            technologies, and (2.3) information collected from third parties. All of the information
                            referred to in (2.1)-(2.3) are detailed below, and hereinafter referred to as
                            “Information.”</Text>
                        <Text style={styles.title}>2.1 Information You Provide to Us</Text>
                        <Text style={styles.paragraph}>In interacting with us and using our Services, you may provide us
                            with Information, including, without limitation:
                            <View>
                                <NumberedText prefix={"\u2022"}>Contact details, such as your name, email address,
                                    phone
                                    number, and physical address;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Account log in credentials, including unique
                                    identifiers
                                    such as usernames, passwords and similar security information;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Other account registration and profile information,
                                    such as
                                    the names, job titles and contact information for any other individuals or employees
                                    associated with your account;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Vehicle and inventory information;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Payment information, such as bank account and routing
                                    numbers;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Your social security number or driver’s license
                                    information;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Your geolocation data (including through our mobile
                                    app)
                                    and other information you provide or allow us to access, including data held on
                                    social
                                    media platforms, other software (for example, access to your contacts, calendar or
                                    photos), and information you send to customer support;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Additional information as otherwise described to you at
                                    the
                                    point of collection or pursuant to your consent.</NumberedText>
                            </View>
                        </Text>
                        <Text style={styles.title}>2.2 Information Collected Automatically</Text>
                        <Text style={styles.paragraph}>ACV Auctions may automatically collect, and associate with your
                            account, certain Information when you access our Services using cookies, log files, web
                            beacons, tags, or pixel tags, flash objects and other technologies. We collect this
                            Information automatically when you access our website or use our mobile app, and we use this
                            Information to improve the functionality and design of both our site and our app, and for
                            advertising purposes. This Information may include, without limitation:
                            <View>
                                <NumberedText prefix={"\u2022"}>IP address, which is the number associated with the
                                    service
                                    through which you access the Internet, like your ISP (internet service
                                    provider);</NumberedText>
                                <NumberedText prefix={"\u2022"}>Date and time of your visit or use of our
                                    Services;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Domain server from which you are using our
                                    Services;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Type of computer, web browsers, search engine used,
                                    operating
                                    system, or platform you use;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Data identifying the web pages you visited prior to and
                                    after
                                    visiting our website or use of our Services;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Your movement and activity within the website and
                                    app;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Geolocation information through the use of any of our
                                    mobile
                                    applications;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Mobile device information, including the type of device
                                    you use,
                                    operating system version, and the device identifier (or “UDID”);</NumberedText>
                                <NumberedText prefix={"\u2022"}>Mobile application identification and behavior, use, and
                                    aggregated usage, performance data, and where the application was downloaded
                                    from.</NumberedText>
                            </View>
                        </Text>
                        <Text style={styles.title}>2.3 Information We May Receive From Third Parties</Text>
                        <Text style={styles.paragraph}>ACV Auctions may also collect and receive Information
                            about you
                            from our business partners, our dealer Customers, and other third parties. We
                            collect this
                            information to provide services to you and to improve the content and quality of the
                            ACV
                            Auctions Service. This Information may include, without limitation:
                            <View>
                                <NumberedText prefix={"\u2022"}>Information related to your vehicle purchases and sales,
                                    and tax
                                    information;</NumberedText>
                                <NumberedText prefix={"\u2022"}>For our dealer Customers, information related to your
                                    dealership
                                    and your credit history;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Payment information, such as bank account routing
                                    numbers.</NumberedText>
                            </View>
                        </Text>
                        <Text style={styles.paragraph}>Please keep in mind that when you provide Information to
                            us via a
                            third-party website or platform the Information you provide may be separately
                            collected by
                            the third-party website or the social media platform.</Text>
                        <Text style={styles.paragraph}>The Information we collect is covered by this Privacy
                            Policy, and
                            the Information the third-party website or social media platform collects is subject
                            to the
                            third-party website or platform’s privacy practices. We encourage you to be aware
                            when you
                            leave our sites or applications and to read the privacy policies of other sites that
                            may
                            collect your Information.</Text>
                        <Text style={styles.title}>3. How We Use Your Information</Text>
                        <Text style={styles.paragraph}>ACV Auctions uses and processes Information that is collected for
                            things that may include, but are not limited to, the following general purposes:
                            <View>
                                <NumberedText prefix={"\u2022"}>To provide, activate and manage your access to and use
                                    of
                                    our
                                    Services;</NumberedText>
                                <NumberedText prefix={"\u2022"}>To process your applications and related documents
                                    required
                                    to
                                    become a contracted dealer and to make a decision regarding the acceptance or
                                    rejection
                                    of
                                    your applications;</NumberedText>
                                <NumberedText prefix={"\u2022"}>To verify the information that you provide to ACV
                                    Auctions;</NumberedText>
                                <NumberedText prefix={"\u2022"}>To verify, evaluate, and/or monitor your
                                    creditworthiness;</NumberedText>
                                <NumberedText prefix={"\u2022"}>To assist with the collection of any amounts owing by
                                    you
                                    to
                                    ACV
                                    Auctions;</NumberedText>
                                <NumberedText prefix={"\u2022"}>To process your payments to ACV Auctions (which, for the
                                    removal
                                    of doubt, includes affiliates and subsidiaries, such as ACV Capital
                                    LLC);</NumberedText>
                                <NumberedText prefix={"\u2022"}>To respond to your any requests, inquiries, complaints,
                                    or;</NumberedText>
                                <NumberedText prefix={"\u2022"}>To provide you with updates, announcements and other
                                    personalized
                                    information regarding our Service;</NumberedText>
                                <NumberedText prefix={"\u2022"}>To provide advertisements, notices and other information
                                    to
                                    you
                                    through our website, email messages, mobile applications, or other methods of
                                    communication;</NumberedText>
                                <NumberedText prefix={"\u2022"}>To deliver targeted advertisements, promotional
                                    messages,
                                    and
                                    other information about products, events and services of third parties related to
                                    your
                                    interests;</NumberedText>
                                <NumberedText prefix={"\u2022"}>To administer surveys, contests or other promotional
                                    activities
                                    or events sponsored by us, our vendors or other third-party service
                                    providers;</NumberedText>
                                <NumberedText prefix={"\u2022"}>To help us to evaluate and improve our Services,
                                    including
                                    our
                                    sales and marketing strategies and techniques, and to develop new products and
                                    services;</NumberedText>
                                <NumberedText prefix={"\u2022"}>To operate and improve our website;</NumberedText>
                                <NumberedText prefix={"\u2022"}>To provide third parties with information necessary to
                                    permit
                                    that third party to conduct business with ACV Auctions and/or effectively provide
                                    you
                                    with
                                    services;</NumberedText>
                                <NumberedText prefix={"\u2022"}>To assist ACV Auctions and third parties in evaluating
                                    the
                                    vehicle industry;</NumberedText>
                                <NumberedText prefix={"\u2022"}>To comply with our legal obligations, resolve disputes,
                                    and
                                    enforce our agreements;</NumberedText>
                                <NumberedText prefix={"\u2022"}>For any other purposes disclosed to you at the time we
                                    collect
                                    your Information and/or pursuant to your consent.</NumberedText>
                            </View>
                        </Text>
                        <Text style={styles.title}>4. Sharing Your Information</Text>
                        <Text style={styles.paragraph}>ACV Auctions may share your Information to provide the products
                            or services you have requested, when we have your permission, and under the following
                            circumstances.</Text>
                        <Text style={styles.title}>4.1 Our Affiliates and Service Providers</Text>
                        <Text style={styles.paragraph}>ACV Auctions may share your Information with service providers,
                            third party auctions, and our agents and representatives to help us provide, improve,
                            protect, and promote our Services to you. This may include, but is not limited to, payment
                            processors and IT service providers. We require these third parties to process your
                            Information in accordance with our instructions and in a manner consistent with this Privacy
                            Policy.</Text>
                        <Text style={styles.paragraph}>We may share your Information with subsidiaries, affiliates, and
                            third parties, including sponsors and our dealer Customers, so they can provide services and
                            advertisements of interest to you.</Text>
                        <Text style={styles.title}>4.2 Your Choices When Using the Service</Text>
                        <Text style={styles.paragraph}>ACV Auctions will display your profile Information, such as your
                            name, images, and auction history, to other Customers utilizing ACV’s Services. The Service
                            may let you post and share additional information, comments, material and other content.
                            Please exercise discretion when disclosing personal information in this manner.</Text>
                        <Text style={styles.title}>4.3 For Legal Reasons</Text>
                        <Text style={styles.paragraph}>We may disclose your Information to third parties if we determine
                            that such disclosure is reasonably necessary to (a) investigate, prevent, or take action
                            regarding suspected illegal activities or violations of our Terms of Service; (b) comply
                            with any law, regulation or governmental request; (c) protect any person from death or
                            serious bodily injury; (d) prevent fraud or abuse of ACV Auctions or our Customers; or (e)
                            protect ACV Auctions’ property rights.</Text>
                        <Text style={styles.paragraph}>We may share, sell, and/or transfer Information to a third party
                            in the event of a potential or actual acquisition of ACV Auctions or its merger with another
                            company.</Text>
                        <Text style={styles.title}>5. Cookies</Text>
                        <Text style={styles.paragraph}>ACV Auctions also collects Information from and about the devices
                            you use to access the Services. This includes things like IP addresses, the type of browser
                            and device you use, and identifiers associated with your device(s). Customer devices,
                            depending on their settings, may also transmit location Information to the Services.</Text>
                        <Text style={styles.paragraph}>Cookies are small text files that store data using your web
                            browser to make your browsing experience easier and faster. Cookies are required to use the
                            ACV Auctions Service. We, and our third-party service providers who assist with managing our
                            site, use cookies to record current session information. We may, from time to time, use
                            similar technologies, including web beacons, pixel tags, flash objects, location tracking
                            and/or third-party widgets for purposes of enhancing your web or mobile experience and/or
                            for purposes of allowing you to share Information on another platform, such as a social
                            media platform. ACV Auctions uses technologies like cookies to provide, improve, protect,
                            and promote our Services. For example, cookies help ACV Auctions with things like
                            remembering a Customer’s preferred settings. By using our Service or by signing up for an
                            account with us, you agree that we can place these cookies on your device. You may be able
                            to adjust your browser settings to manage your cookie preferences. If you reject cookies,
                            you may still use our Services, but the functionality of some areas may be limited.</Text>
                        <Text style={styles.title}>6. Children’s Privacy</Text>
                        <Text style={styles.paragraph}>ACV’s Services are intended for use by an adult general audience.
                            Our Services are not directed to children under the age of 13 and ACV Auctions does not
                            knowingly solicit personal information from or market to children under the age of 13. If we
                            learn that anyone under the age of 13 has provided any personal information through our
                            Services, we will use commercially reasonable efforts to promptly delete it. If you are a
                            parent or guardian and believe your child has provided us with personal information, please
                            contact us at the address below to request deletion.</Text>
                        <Text style={styles.title}>7. Notice To California Residents—Your California Privacy
                            Rights</Text>
                        <Text style={styles.paragraph}>The California Consumer Privacy Act (“CCPA”) provides California
                            residents with specific rights regarding their personal information. This section of our
                            Privacy Policy describes your CCPA rights and explains how to exercise those rights. It
                            applies solely to California residents. Any term defined in the CCPA shall have the same
                            meaning when used in this Section.</Text>
                        <Text style={styles.title}>7.1 Information We Collect</Text>
                        <Text style={styles.paragraph}>Category Examples</Text>
                        <Text style={styles.paragraph}>
                            <View>
                                <NumberedText prefix={"A."}><Text><Text
                                    style={styles.paragraphTitle}>Identifiers.</Text> Name, billing and shipping
                                    addresses, unique
                                    personal identifier, online identifier, Internet Protocol address, email address,
                                    account
                                    name, Social Security number, driver’s license number, or other similar identifiers.</Text></NumberedText>
                                <NumberedText prefix={"B."}><Text><Text
                                    style={styles.paragraphTitle}> Personal information categories listed in the
                                    California
                                    Customer Records statute (Cal. Civ. Code § 1798.80(e)).</Text> Name, signature,
                                    Social
                                    Security
                                    number, physical characteristics or description, address, telephone number, passport
                                    number,
                                    driver’s license or state identification card number, insurance policy number,
                                    employment,
                                    employment history, bank account number, credit card number, debit card number, or
                                    any other
                                    financial information.</Text></NumberedText>
                                <NumberedText prefix={"C."}><Text><Text
                                    style={styles.paragraphTitle}>Commercial information.</Text> Records of personal
                                    property,
                                    products
                                    or services purchased, obtained, or considered, or other purchasing or consuming
                                    histories
                                    or tendencies.</Text></NumberedText>
                                <NumberedText prefix={"D."}><Text><Text
                                    style={styles.paragraphTitle}>Internet or other similar network
                                    activity.</Text> Browsing
                                    history,
                                    search history, information on a consumer’s interaction with a website, application,
                                    or
                                    advertisement.</Text></NumberedText>
                                <NumberedText prefix={"E."}><Text><Text
                                    style={styles.paragraphTitle}>Geolocation data.</Text> Physical location or
                                    movements.</Text></NumberedText>
                                <NumberedText prefix={"F."}><Text><Text
                                    style={styles.paragraphTitle}>Sensory data.</Text> Audio, electronic, visual,
                                    thermal,
                                    olfactory,
                                    or similar information.</Text></NumberedText>
                                <NumberedText prefix={"G."}><Text><Text
                                    style={styles.paragraphTitle}>Professional or employment-related
                                    information.</Text> Current or
                                    past job history</Text></NumberedText>
                            </View>
                        </Text>
                        <Text style={styles.paragraph}>We obtain the categories of personal information listed above
                            from the following categories of sources:</Text>
                        <Text style={styles.paragraph}>
                            <NumberedText prefix={"\u2022"}>Directly and indirectly from you, our Customer, when using
                                our
                                Services or visiting our website.</NumberedText>
                            <NumberedText prefix={"\u2022"}>Directly and indirectly from our affiliates and
                                subsidiaries, to
                                assist us in providing Services to you;</NumberedText>
                            <NumberedText prefix={"\u2022"}>Directly from our service providers;</NumberedText>
                            <NumberedText prefix={"\u2022"}>Directly and indirectly from other third-parties, such as
                                advertisers, data brokers and social media platforms, who may provide us data they have
                                collected from or about you from public records and private sources;</NumberedText>
                            <NumberedText prefix={"\u2022"}>Directly from our Clients or their agents. For example, from
                                information our Clients provide to us related to the services for which they engage
                                us.</NumberedText>
                        </Text>
                        <Text style={styles.title}>7.2 Information Disclosed</Text>
                        <Text style={styles.paragraph}>In the preceding twelve (12) months, we have disclosed the
                            following categories of personal information for a business purpose:</Text>
                        <Text style={styles.paragraph}>
                            <View>
                                <NumberedText prefix={"\u2022"}>Identifiers;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Personal information categories listed in the California
                                    Customer
                                    Records statute;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Commercial information;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Internet or other similar network
                                    activity.</NumberedText>
                                <NumberedText prefix={"\u2022"}>We disclose your personal information for a business
                                    purpose
                                    to
                                    the following categories of third parties:</NumberedText>
                                <NumberedText prefix={"\u2022"}>Our affiliates and subsidiaries;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Service providers;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Advertisers;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Third parties to whom you or your agents authorize us to
                                    disclose
                                    your personal information in connection with products or services we provide to
                                    you.</NumberedText>
                            </View>
                        </Text>
                        <Text style={styles.paragraph}>If you do not want us to share your personal information for
                            advertising purposes, please email us at datasubjectrights@acvauctions.com or call us toll
                            free at 1 (855) 804-2858.</Text>
                        <Text style={styles.title}>7.3 Access to Specific Information and Data Portability Rights</Text>
                        <Text style={styles.paragraph}>You have the right to request that we disclose certain
                            information to you about our collection and use of your personal information over the past
                            12 months. Once we receive and confirm your verifiable consumer request, we will disclose to
                            you:</Text>
                        <Text style={styles.paragraph}>
                            <View>
                                <NumberedText prefix={"\u2022"}>The categories of personal information we collected
                                    about
                                    you;</NumberedText>
                                <NumberedText prefix={"\u2022"}>The categories of sources for the personal information
                                    we
                                    collected about you;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Our business or commercial purpose for collecting that
                                    personal
                                    information;</NumberedText>
                                <NumberedText prefix={"\u2022"}>The categories of third parties with whom we share that
                                    personal
                                    information;</NumberedText>
                                <NumberedText prefix={"\u2022"}>The specific pieces of personal information we collected
                                    about
                                    you (also called a data portability request);</NumberedText>
                                <NumberedText prefix={"\u2022"}>If we sold or disclosed your personal information for a
                                    business
                                    purpose:</NumberedText>
                                <NumberedText prefix={"\u2022"}>For sales, the personal information categories
                                    purchased;</NumberedText>
                                <NumberedText prefix={"\u2022"}>For disclosures for a business purpose, the personal
                                    information
                                    categories disclosed.</NumberedText>
                            </View>
                        </Text>
                        <Text style={styles.title}>7.4 Deletion Request Rights</Text>
                        <Text style={styles.paragraph}>You have the right to request that we delete any of your personal
                            information we collected from you and retained, subject to certain exceptions. Once we
                            receive and confirm your verifiable consumer request, we will delete (and direct our service
                            providers to delete) your personal information from our records, unless an exception
                            applies.</Text>
                        <Text style={styles.paragraph}>We may deny your deletion request if retaining the information is
                            necessary for us or our service providers to:</Text>
                        <Text style={styles.paragraph}>
                            <View>
                                <NumberedText prefix={"\u2022"}>Complete the transaction for which we collected the
                                    personal
                                    information, provide a good or service requested by you, or reasonably anticipated
                                    within
                                    the context of our ongoing business relationship with you, or otherwise perform our
                                    contract
                                    with you;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Detect security incidents, protect against malicious,
                                    deceptive,
                                    fraudulent, or illegal activity, and take all necessary and appropriate steps to
                                    mitigate
                                    current and future risk;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Debug products to identify and repair errors that impair
                                    existing
                                    intended functionality. debug and repair internal information technology as
                                    necessary;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Undertake internal research for technological
                                    development
                                    and
                                    demonstration;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Exercise free speech, ensure the right of another
                                    consumer to
                                    exercise his or her free speech rights, or exercise another right provided for by
                                    law;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Comply with the California Electronic Communications
                                    Privacy
                                    Act;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Enable solely internal uses that are reasonably aligned
                                    with
                                    consumer expectations based on your relationship with us;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Comply with a legal obligation;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Otherwise use your personal Information, internally, in
                                    a
                                    lawful
                                    manner that is compatible with the context in which you provided the
                                    Information.</NumberedText>
                            </View>
                        </Text>
                        <Text style={styles.title}>7.5 Exercising Access, Data Portability, and Deletion Rights</Text>
                        <Text style={styles.paragraph}>To exercise the access, data portability, and deletion rights
                            described above, please submit a verifiable consumer request to us by email, including your
                            full name and ten-digit phone number, at datasubjectrights@acvauctions.com or call us toll
                            free at 1 (855) 804-2858.</Text>
                        <Text style={styles.paragraph}>Only you, or a person that you authorize to act on your behalf,
                            may make a verifiable consumer request related to your personal Information. You may only
                            make a verifiable consumer request for access or data portability twice within a 12-month
                            period. The verifiable consumer request must:</Text>
                        <Text style={styles.paragraph}>
                            <View>
                                <NumberedText prefix={"\u2022"}>Provide sufficient information that allows us to
                                    reasonably
                                    verify you are the person about whom we collected personal information or an
                                    authorized
                                    representative.</NumberedText>
                                <NumberedText prefix={"\u2022"}>Describe your request with sufficient detail that allows
                                    us to
                                    properly understand, evaluate, and respond to it.</NumberedText>
                            </View>
                        </Text>
                        <Text style={styles.paragraph}>We will only use the personal information provided in a
                            verifiable consumer request to verify the requestor’s identity or authority to make the
                            request. We cannot respond to your request or provide you with personal information if we
                            cannot verify that the person making the request is the person about whom we collected the
                            information, or someone authorized to act on such person’s behalf. Making a verifiable
                            consumer request does not require you to create an account with us.</Text>
                        <Text style={styles.title}>7.6 Response Timing and Format</Text>
                        <Text style={styles.paragraph}>We endeavor to respond to a verifiable consumer request within 45
                            days of its receipt. If we require more time (up to 90 days), we will inform you of the
                            reason and extension period in writing. If you have an account with us, we will deliver our
                            written response to the registered email associated with the account. If you do not have an
                            account with us, we will deliver our written response by mail or electronically, at your
                            option. Any disclosures we provide will only cover the 12-month period preceding the
                            verifiable consumer request’s receipt. The response we provide will also explain the reasons
                            we cannot comply with a request, if applicable. For data portability requests, we will
                            select a format to provide your personal information that is readily usable and should allow
                            you to transmit the information from one entity to another entity without hindrance.</Text>
                        <Text style={styles.paragraph}>To the extent permitted by law, we may charge a reasonable fee to
                            comply with your request. If we determine that the request warrants a fee, we will tell you
                            why we made that decision and provide you with a cost estimate before completing your
                            request.</Text>
                        <Text style={styles.title}>7.7 Non-Discrimination</Text>
                        <Text style={styles.paragraph}>We may not, and will not, treat you differently or discriminate
                            against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will
                            not:</Text>
                        <Text style={styles.paragraph}>
                            <View>
                                <NumberedText prefix={"\u2022"}>Deny you goods or services;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Charge different prices or rates for goods or
                                    services;</NumberedText>
                                <NumberedText prefix={"\u2022"}>Provide you a different level or quality of goods or
                                    services;
                                    or</NumberedText>
                                <NumberedText prefix={"\u2022"}>Suggest that any of the preceding will
                                    occur.</NumberedText>
                            </View>
                        </Text>
                        <Text style={styles.paragraph}>However, we can and may charge you a different rate, or provide a
                            different level of quality, if the difference is reasonably related to the value provided by
                            your personal information. We may offer you certain financial incentives permitted by the
                            CCPA that can result in different prices, rates, or quality levels.</Text>
                        <Text style={styles.title}>7.8 Do Not Sell My Personal Information</Text>
                        <Text style={styles.paragraph}>The ACV Auctions app does not sell consumers’ personal
                            information. However, ACV Auctions offers other products, including trade-in value
                            estimators on third-party sites, that may collect and sell consumer personal information,
                            under the CCPA. California residents who enter their information into such products may
                            utilize the “Do Not Sell My Personal Information” webform, email us at
                            datasubjectrights@acvauctions.com or call us toll free at 1 (855) 804-2858 to opt-out of the
                            sale of certain personal information.</Text>
                        <Text style={styles.title}>7.9 Information</Text>
                        <Text style={styles.paragraph}>Statements pertaining to Section 7 are available in alternative
                            formats upon request by emailing datasubjectrights@acvauctions.com or calling us toll free
                            at 1 (855) 804-2858.</Text>
                        <Text style={styles.title}>8. Security</Text>
                        <Text style={styles.paragraph}>ACV Auctions uses a variety of commercially reasonable technical,
                            physical, and administrative measures to protect the confidentiality, integrity, and
                            security of Information. It is your responsibility to maintain the confidentiality of your
                            password(s) and other user credentials.</Text>
                        <Text style={styles.title}>9. Data Retention, Storage And Transmission</Text>
                        <Text style={styles.paragraph}>We will store your personal Information for as long as there is a
                            business need for it in accordance with applicable record retention policies and a range of
                            legal and regulatory recordkeeping requirements.</Text>
                        <Text style={styles.paragraph}>To provide you with the Services, we may store, process, and
                            transmit information in locations outside the United States.</Text>
                        <Text style={styles.title}>10. Your Communications Preferences</Text>
                        <Text style={styles.paragraph}>You agree that we, or third-party service providers with whom we
                            collaborate, may communicate with you regarding our Services via electronic messages,
                            including email, text message, or mobile push notification to, for example, send you
                            information relating to our products and Services, communicate with you about contests,
                            sweepstakes, offers, promotions, rewards, upcoming events, and other news about products and
                            services provided by or through us through permissible targeted advertisements offered by
                            us, our parent companies, our subsidiaries, our affiliates, and other business partners. See
                            Your Choices, below, for how you can update the way we contact you.</Text>
                        <Text style={styles.title}>10.1 Your Choices</Text>
                        <Text style={styles.paragraph}>By using our Services, you agree that we may contact you by email
                            as set forth herein. If you do not want to receive marketing and promotional emails from us,
                            you may click on the “unsubscribe” link in the email to unsubscribe and opt-out of marketing
                            email communications or contact us.</Text>
                        <Text style={styles.paragraph}>
                            <View>
                                <NumberedText prefix={"\u2022"}><Text><Text style={styles.paragraphTitle}>Mobile Push
                                    Notifications.</Text> By using our Services, you
                                    agree that
                                    we may contact you by calling or sending messages to your phone. You can use the
                                    settings on
                                    your mobile device to enable or turn off mobile push notifications from us or
                                    contact
                                    us.</Text></NumberedText>
                                <NumberedText prefix={"\u2022"}><Text><Text style={styles.paragraphTitle}>NumberedText
                                    Messages.</Text> By using our Services, you agree to be
                                    reached by
                                    text message. If you no longer want to receive text messages from us, reply STOP (or
                                    as
                                    otherwise instructed) in the text message or contact us.</Text></NumberedText>
                                <NumberedText prefix={"\u2022"}><Text><Text style={styles.paragraphTitle}>Location
                                    Choices.</Text> You can change the privacy settings of
                                    your
                                    device at any time to turn off the sharing of location information with our
                                    Services. If you
                                    choose to turn off location services, this could affect certain features or services
                                    of our
                                    Services.</Text></NumberedText>
                                <NumberedText prefix={"\u2022"}><Text><Text style={styles.paragraphTitle}>Opting Out of
                                    Direct Marketing.</Text> To exercise choices
                                    regarding the
                                    marketing information you receive, you may also review the following links:</Text>
                                    <View>
                                        <NumberedText prefix={"\u2022"}><Text>You may opt-out of tracking and receiving
                                            tailored
                                            advertisements
                                            on your mobile device by some mobile advertising companies and other similar
                                            entities by
                                            downloading the App Choices app at <ExternalLink
                                                url={"https://aboutads.info/appchoices"}/>.</Text></NumberedText>
                                        <NumberedText prefix={"\u2022"}><Text>You may opt-out of receiving permissible
                                            targeted
                                            advertisements
                                            by using the NAI Opt-out tool available at <ExternalLink
                                                url={"https://optout.networkadvertising.org/?c=1"}/>
                                            or
                                            visiting About Ads at <ExternalLink url={"https://optout.aboutads.info"}/>.</Text></NumberedText>
                                        <NumberedText prefix={"\u2022"}><Text>You can opt-out of having your activity on
                                            our Services
                                            made
                                            available to Google Analytics by installing the Google Analytics opt-out
                                            add-on for
                                            your web
                                            browser by visiting: <ExternalLink
                                                url={"https://tools.google.com/dlpage/gaoptout"}/> for your web
                                            browser.</Text></NumberedText>
                                    </View></NumberedText>
                            </View>
                        </Text>
                        <Text style={styles.title}>11. Do Not Track</Text>
                        <Text style={styles.paragraph}>Please note that because there is no consistent industry
                            understanding of how to respond to “Do Not Track” signals, we do not alter our data
                            collection and usage practices when we detect such a signal from your browser.</Text>
                        <Text style={styles.title}>12. Change Of Control</Text>
                        <Text style={styles.paragraph}>If we are involved in a reorganization, merger, acquisition or
                            sale of our assets, your Information may be transferred as part of that deal. We will notify
                            you (for example, via a message to the email address associated with your account) of any
                            such deal and outline your choices in that event.</Text>
                        <Text style={styles.title}>13. Changes To This Privacy Policy</Text>
                        <Text style={styles.paragraph}>We reserve the right to change, modify or amend this Privacy
                            Policy at any time to reflect changes in our Services, accommodate new technologies, respond
                            to regulatory requirements, or other purposes. If we modify our Privacy Policy, we will
                            update the “Effective Date” and such changes will be effective upon posting. It is your
                            obligation to check our Privacy Policy for any changes.</Text>
                        <Text style={styles.title}>14. How To Contact Us</Text>
                        <Text style={styles.paragraph}>If you have any questions or requests regarding this Privacy
                            Policy or our processing of your Information, please contact us at the following:</Text>
                        <View style={styles.paragraph}>
                            <Text style={styles.line}>Compliance & Regulatory</Text>
                            <Text style={styles.line}>ACV Auctions Inc.</Text>
                            <Text style={styles.line}>640 Ellicott St.</Text>
                            <Text style={styles.line}>Suite 321</Text>
                            <Text style={styles.line}>Buffalo, NY 14203</Text>
                            <Text style={styles.line}>Email: datasubjectrights@acvauctions.com</Text>
                            <Text style={styles.line}>Toll Free: 1 (855) 804-2858</Text>
                        </View>
                        <Text style={styles.paragraph}><Text style={styles.paragraphTitle}>Effective Date:</Text> July 19, 2021</Text>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export default PrivacyPolicyScreen;