import {Platform, StyleSheet} from "react-native";

const buildMobileStyles = (colors) => ({
    container: {
        flex: 1,
        flexDirection: "column",
        backgroundColor: colors.greys["ui-white-color"]
    },
    forgotPasswordContainer: {
        marginTop: "20%",
        padding: 10,
        elevation: 4,
        width: "90%",
        alignItems: "center",
        alignSelf: "center"
    },
    formContainer: {
        paddingBottom: 25,
        width: "90%",
        maxWidth: 430,
    },
    marginTop: {
        marginTop: 15
    },
    title: {
        fontFamily: "Roboto_700Bold",
        fontSize: 39,
        lineHeight: 39,
        color: colors.text["text-primary-color"],
    },
    description: {
        fontFamily: "Roboto_400Regular",
        fontSize: 18,
        lineHeight: 21,
        color: colors.text["text-secondary-color"],
    }
});

const buildWebStyles = (colors) => ({
    container: {
        flex: 1,
        flexDirection: "column",
        backgroundColor: colors.greys["ui-white-color"]
    },
    forgotPasswordContainer: {
        padding: 40,
        borderRadius: 4,
        marginTop: "12vh",
        width: "90%",
        alignItems: "center",
        alignSelf: "center"
    },
    formContainer: {
        paddingTop: 30,
        width: "100%",
        maxWidth: 430,
    },
    marginTop: {
        marginTop: 15
    },
    title: {
        fontFamily: "Roboto_700Bold",
        fontSize: 39,
        lineHeight: 39,
        color: colors.text["text-primary-color"],
    },
    description: {
        fontFamily: "Roboto_400Regular",
        fontSize: 18,
        lineHeight: 21,
        color: colors.text["text-secondary-color"],
    }
});

export const buildStyles = (colors) => {
    return StyleSheet.create({
        ...Platform.select({
            ios: buildMobileStyles(colors),
            android: buildMobileStyles(colors),
            default: buildWebStyles(colors)
        })
    });
};
