import * as React from "react";
import Svg, {Path} from "@cantoo/rn-svg";
import PropTypes from "prop-types";

const ReserveMet = (props) => {
    const {widthHeight, color} = props;
    return (
        <Svg
            testID="reserveMet"
            data-icon="reserveMet"
            viewBox={`0 0 20 20`}
            width={widthHeight}
            height={widthHeight}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="m6.94 13.704-3.314-3.313a.951.951 0 1 0-1.347 1.346l3.992 3.992a.951.951 0 0 0 1.346 0L17.721 5.626a.951.951 0 1 0-1.347-1.347L6.94 13.704Z"
                fill={color}
            />
        </Svg>
    );
};

ReserveMet.propTypes = {
    widthHeight: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired
};

export default ReserveMet;