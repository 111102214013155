import {View, StyleSheet} from "react-native";
import React, {useCallback} from "react";
import {globalText} from "../../fonts";
import PropTypes from "prop-types";
import VehicleDetailsRow from "./vehicleDetailsRow";
import {useColors} from "../../store/whiteLabelConfig/util";

const VehicleDetails = (props) => {
    const {details} = props;

    const styles = buildStyles(useColors());

    /**
     * Get style to render vehicle details table
     * @param index - index of the table row
     * @returns {*} - styles
     */
    const getStyle = (index) => {
        return {
            rowContainer: styles.rowContainer,
            cellKey: !index ? styles.cellKey : styles.cellKey2,
            cellValue: !index ? styles.cellValue : styles.cellValue2,
            textKey: styles.textKey,
            textValue: styles.textValue
        };
    };

    /**
     * Render vehicle detail row
     * @param items - Array of vehicle details
     * @returns {*} - VehicleDetailsRow component
     */
    const renderItems = useCallback((items) => {
        return items.map((item, index) => {
            return (<VehicleDetailsRow key={index} item={item} styles={getStyle(index%2)}/>);
        });
    }, []);

    return (
        <View>
            {renderItems(details)}
        </View>
    );
};

VehicleDetails.propTypes = {
    // Array of vehicle details
    details: PropTypes.array
};

const buildStyles = colors => StyleSheet.create({
    rowContainer: {
        flexDirection: "row",
        flex: 1,
        minHeight: 46,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderColor: colors.greys["ui-grey-base-dark"]
    },
    cellKey: {
        backgroundColor: colors.background["background-2-color"],
        borderWidth: 0,
        flex: 1,
        justifyContent: 'center',
        alignItems: "flex-start",
    },
    cellValue: {
        backgroundColor: colors.background["background-2-color"],
        borderWidth: 0,
        flex: 1,
        justifyContent: 'center',
        alignItems: "flex-end"
    },
    cellKey2: {
        backgroundColor: colors.background["background-3-color"],
        borderWidth: 0,
        flex: 1,
        justifyContent: 'center',
        alignItems: "flex-start",
    },
    cellValue2: {
        backgroundColor: colors.background["background-3-color"],
        borderWidth: 0,
        flex: 1,
        justifyContent: 'center',
        alignItems: "flex-end"
    },
    textKey: {
        ...globalText["body-base-regular"],
        paddingLeft: 20,
        color: colors.text["text-secondary-color"],
    },
    textValue: {
        fontFamily: "Roboto_700Bold",
        fontSize: 15,
        lineHeight: 21,
        paddingRight: 20,
        color: colors.text["text-primary-color"],
    }
});
export default VehicleDetails;
