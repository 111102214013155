import React, {useCallback, useEffect, useState} from "react";
import {useColors} from "../../store/whiteLabelConfig/util";
import {ActivityIndicator, FlatList, StyleSheet, View} from "react-native";
import {SafeAreaView} from "react-native-safe-area-context";
import ListingHeader from "../../components/listing/listingHeader";
import {fetchListings} from "../../apiClients/auctionsApi";
import {Listing} from "../../components/listing/listing";

export const ListingsScreen = () => {
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);

    const refreshListings = async () => {
        setLoading(true);

        const res= await fetchListings();

        setListings(res["saved_auctions"]);
        setLoading(false);
    };

    const refreshCallback = useCallback(refreshListings, []);
    useEffect(() => { refreshListings(); }, []);


    const colors = useColors();
    const styles = buildStyles();
    const renderListing = useCallback((listing) => {
        return <Listing listing={listing.item}/>;
    }, []);

    const getListingId = useCallback(item => item.id.toString(), []);


    return (
        <View style={{flex: 1, flexDirection: "column"}}>
            {loading ?
                <View style={styles.loadingContainer}>
                    <ActivityIndicator testID={"listingsLoading"} color={colors.secondaryShades["secondary-color-base"]}
                                       size={"large"}/>
                </View>
                :
                <SafeAreaView
                    style={{width: "100%", height: "100%", backgroundColor: colors.background["background-3-color"]}}>
                    <FlatList
                        ListHeaderComponent={<ListingHeader cars={listings}/>}
                        showsVerticalScrollIndicator={true}
                        data={listings}
                        keyExtractor={getListingId}
                        renderItem={renderListing}
                        onRefresh={refreshCallback}
                        refreshing={loading}
                    />
                </SafeAreaView>
            }
        </View>);
};

const buildStyles = () =>
    StyleSheet.create({
        loadingContainer: {
            flex: 1,
            justifyContent: "center",
        }
    });

