import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useCallback} from "react";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {useColors} from "../store/whiteLabelConfig/util";
import {pushMeasurement} from "../utils/telemetry";
import PropTypes from "prop-types";
import useLargeFormFactorCheck from "../utils/responsive";
import {globalHeading, globalText} from "../fonts";
import ExternalLink from "./icons/externalLink";

const MenuItem = ({actionName, action, title, isLink = false, padding = 16}) => {
    const {t} = useTranslation();
    const isDesktop = useLargeFormFactorCheck();
    const [hovered, setHovered] = useState(false);
    const canClick = action != null;
    const mouseEnter = useCallback(() => setHovered(canClick), []);
    const mouseExit = useCallback(() => setHovered(false), []);

    const onPressed = useCallback(() => {
        pushMeasurement(actionName);
        action();
    }, []);

    const colors = useColors();
    const styles = buildStyles(colors, isDesktop, padding);

    return (<TouchableOpacity testID={actionName}
                              disabled={!canClick}
                              onPress={onPressed}
                              onMouseEnter={mouseEnter}
                              onMouseLeave={mouseExit}
                              style={hovered ? [styles.menuItemContainerHovered, styles.menuItemContainer] : styles.menuItemContainer}>
        <View style={styles.contentRow}>
            <Text style={styles.menuItemText}>{t(title)}</Text>
            {!isDesktop && isLink && <ExternalLink color={colors.text["text-secondary-color"]} widthHeight={14}/>}
        </View>
    </TouchableOpacity>);

};

MenuItem.propTypes = {
    actionName: PropTypes.string,
    action: PropTypes.func,
    title: PropTypes.string,
    isLink: PropTypes.bool,
    padding: PropTypes.number
};

const buildStyles = (colors, isDesktop, padding) => {
    if (isDesktop) {
        return StyleSheet.create({
            menuItemContainerHovered: {
                backgroundColor: colors.background["background-1-color"]
            },
            menuItemContainer: {
                width: 200,
                height: 44,
                alignItems: "flex-end",
            },
            menuItemText: {
                paddingVertical: 14,
                paddingHorizontal: padding,
                color: colors.text["text-tertiary-color"],
                ...globalHeading["subtitle-sm-medium"],
                textAlign: "right"
            },
        });
    }
    return StyleSheet.create({
        menuItemContainer: {
            paddingHorizontal: padding,
            width: "100%",
            height: 44,
        },
        contentRow: {
            flexDirection: "row",
            flex: 1,
            alignItems: "center",
            borderBottomColor: colors.background["background-1-color"],
            borderBottomWidth: 1,
        },
        menuItemText: {
            ...globalText["body-sm-regular"],
            paddingVertical: 16,
            textAlign: "left",
            color: colors.text["text-secondary-color"],
            paddingRight: 6,
        },
    });
};

export default MenuItem;