const {Platform} = require("react-native");

/**
 * We use this to create a layer of abstraction and make it easy to mock out
 * in unit testing.
 * 
 * @returns {"android" | "ios" | "windows" | "macos" | "web"}
 */
export const getPlatform = () => Platform.OS;

export const getVersion = () => {
    const version = process.env.BUNDLE_VERSION || "DEV";
    return version.replace("-",".");
};