import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {useColors} from "../store/whiteLabelConfig/util";
import React, {useCallback} from "react";
import PropTypes from "prop-types";
import CancelIcon from "./icons/cancelIcon";
import CompleteIcon from "./icons/completeIcon";
import {globalText} from "../fonts";
import ErrorIcon from "./icons/errorIcon";
import InfoIcon from "./icons/infoIcon";

export const ToastMessage = (props) => {
    const {toastOptions} = props;
    const {onHide, data, message, type} = toastOptions;
    const {title, linkText, linkAction} = data;
    const colors = useColors();

    let borderColor = calculateBorderColor(colors, type);
    const style = buildStyles(colors, borderColor);

    const linkActionCallable = useCallback(() => linkAction(), [linkAction]);
    const hide = useCallback(() => onHide(), [onHide]);
    const statusIcon = getStatusIcon(type, borderColor);

    return (<View onPress={linkAction} style={[style.base, style.leadingBorder]}>
        <View style={style.statusIconContainer}>{statusIcon}</View>
        <View style={style.contentContainer}>
            <Text testID={'toastTitle'} style={style.title}>{title}</Text>
            <Text testID={'toastText'} style={style.message}>{message}</Text>
            {linkText && linkAction && (
                <TouchableOpacity onPress={linkActionCallable}><Text
                    style={style.link}>{linkText}</Text></TouchableOpacity>)}
        </View>
        <View style={style.closeIconContainer}>
            <TouchableOpacity onPress={hide} testID={"toastCloseIcon"}>
                <CancelIcon color={colors.text['text-primary-color']} widthHeight={13}/>
            </TouchableOpacity>
        </View>
    </View>);
};

const calculateBorderColor = (colors, type) => {
    switch (type) {
        case 'caution':
            return colors.caution["ui-caution-color-base"];
        case 'danger':
            return colors.error["ui-error-color-x-dark"];
        default:
            return colors.success["ui-success-color-base"];
    }
};

const getStatusIcon = (type, borderColor) => {
    switch (type) {
        case 'success':
            return (<CompleteIcon color={borderColor} widthHeight={20}/>);
        case 'caution':
            return (<InfoIcon color={borderColor} widthHeight={20}/>);
        default:
            return (<ErrorIcon color={borderColor} widthHeight={20}/>);
    }
};

ToastMessage.propTypes = {
    toastOptions: PropTypes.shape({
        type: PropTypes.string,
        data: PropTypes.shape({
            title: PropTypes.string,
            linkText: PropTypes.string,
            linkAction: PropTypes.func,
        }),
        message: PropTypes.string,
        onHide: PropTypes.func,
    }),
};

const buildStyles = (colors, borderType) => StyleSheet.create({
    base: {
        flexDirection: 'row',
        minHeight: 87,
        width: 360,
        borderRadius: 6,
        shadowOffset: {width: 0, height: 0},
        shadowOpacity: 0.1,
        shadowRadius: 6,
        elevation: 2,
        backgroundColor: colors.background["background-3-color"],
        marginBottom: 10,
    },
    leadingBorder: {
        borderLeftWidth: 5,
        borderLeftColor: borderType
    },
    contentContainer: {
        paddingVertical: 12,
        paddingHorizontal: 10,
        flex: 1,
        flexDirection: "column",
        alignItems: 'flex-start'
    },
    title: {
        ...globalText["body-sm-bold"],
        width: '100%'
    },
    message: {
        ...globalText["body-sm-regular"],
        width: '100%'
    },
    link: {
        paddingTop: 8,
        ...globalText.button,
        color: colors.text["text-link-color"]
    },
    closeIconContainer: {
        paddingTop: 14,
        paddingRight: 19,
    },
    statusIconContainer: {
        paddingTop: 14,
        paddingLeft: 19,
    }
});