import React, {useCallback, useState} from 'react';
import {StyleSheet, Text, TouchableOpacity} from "react-native";
import PropTypes from "prop-types";
import {globalText} from "../fonts";
import {useColors} from "../store/whiteLabelConfig/util";

const HoverableButton = (props) => {
    const {padding} = props;
    let colors = useColors();
    const style = buildStyle(props, padding, colors);

    const [hovering, setHovering] = useState(false);

    const setHoverEnter = useCallback(() => setHovering(true), []);
    const setHoverExit = useCallback(() => setHovering(false), []);

    return (
        <TouchableOpacity style={[style.hoverableButton, hovering ? style.hovering : {}]}
                          onMouseEnter={setHoverEnter}
                          onMouseLeave={setHoverExit}
                          onPress={!props.disabled && props.onPress}
                          disabled={props.disabled}
        >
            <Text testID={props.testID} style={style.buttonText} >{props.title}</Text>
        </TouchableOpacity>
    );
};

const buildStyle = (props, padding, colors) => StyleSheet.create({
    hoverableButton: {
        alignItems: "center",
        padding: 15,
        opacity: props.disabled ? 0.2 : 1,
        width: "100%",
        justifyContent: "center",
        borderRadius: 6,
        backgroundColor: props.buttonColor ? props.buttonColor : null
    },
    hovering: {
        backgroundColor: colors.background["border-1-color"]
    },
    buttonText: {
        ...globalText.button,
        color: props.textColor ? props.textColor : colors.text["text-link-color"]
    }
});

HoverableButton.propTypes = {
    testID: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    padding: PropTypes.number,
    buttonColor: PropTypes.string,
    textColor: PropTypes.string,
    onPress: PropTypes.func
};

HoverableButton.defaultProps = {
    padding: 15,
    disabled: false,
    testID: ''
};

export default HoverableButton;
