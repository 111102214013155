import React from 'react';
import {StyleSheet, Text, TouchableOpacity} from "react-native";
import {globalText} from "../fonts";
import PropTypes from "prop-types";
import {useColors} from "../store/whiteLabelConfig/util";

const TertiaryButton = (props) => {
    const styles = buildStyles(useColors());
    return (<TouchableOpacity
            style={styles.secondaryButton}
            {...props}>
            <Text style={styles.secondaryButtonText}>{props.title}</Text>
        </TouchableOpacity>
    );
};

const buildStyles = colors => StyleSheet.create({
    secondaryButton: {
        padding: 15,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.background["background-2-color"],
    },
    secondaryButtonText: {
        ...globalText.button,
        color: colors.secondaryShades["secondary-color-base"],
    }
});

TertiaryButton.propTypes = {
    title: PropTypes.string
};
export default TertiaryButton;