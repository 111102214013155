import {Alert} from "react-native";
import * as Updates from 'expo-updates';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {getStore} from "../store";
import {deleteSession} from "../store/auth/actions";

export const JS_NEW_BUNDLE_NEEDED = "JS_NEW_BUNDLE_NEEDED";

/**
 * Clear local storage and set app redux state to default
 * @returns {Promise<void>}
 */
/* istanbul ignore next */
const clearAsyncStorage = async() => {
    await AsyncStorage.clear();
    getStore().dispatch(deleteSession());
};

/**
 * Send crash data to backend telemetry service
 * @param error
 */
/* istanbul ignore next */
// eslint-disable-next-line no-unused-vars
const reporter = (error) => {
    // placeholder for future feature of reporting crash data
};

/**
 * Returns the ind whether a new JS bundle should be downloaded from server
 * If NEW_BUNDLE_NEEDED key is stored in local storage  or there was an error accessing local storage
 * returns TRUE indicator
 * @returns {Promise<boolean>}
 */
export const getNewBundleIndFromLS = async () => {

    const value = await AsyncStorage.getItem(JS_NEW_BUNDLE_NEEDED);
    return (value !== null ) ? true : false;

};

/* istanbul ignore next */
export const ErrorHandler = (e) => {
       // report an exception to a backend
        reporter(e);
        // check eithe a new JS bundle is required or not based on local storage data
        getNewBundleIndFromLS().then((newBundleNeeded) => {
            if (newBundleNeeded) {
                // setup a timer task that will check for available new OTA update on the server withing an interval
                // once a new bundle becomes available , download it and reload the App
                let showAlertMessage = true;
                const timer = setInterval(async() => {
                    const update = await Updates.checkForUpdateAsync();
                    if (update.isAvailable) {
                        await Updates.fetchUpdateAsync();
                        await Updates.reloadAsync();
                    } else {
                        if (showAlertMessage) {
                            Alert.alert("Unexpected Error occurred",
                                `We have reported this to our team ! Please wait, the app will restart soon!
                   `);
                            showAlertMessage = false;
                        }
                    }
                }, 5000);

                return () => clearInterval(timer);

            } else {
                const reloadExistingBundle = async () => {
                    clearAsyncStorage().then(()=>{
                        AsyncStorage.setItem(JS_NEW_BUNDLE_NEEDED, "true");
                    }).catch(() => {
                        // do nothing
                    });
                    await Updates.reloadAsync();
                };
                reloadExistingBundle();
            }
        });
};
