import {getState, getStore} from "../store";
import {clearMeasurements} from "../store/telemetry/actions";
import useInterval from "./interval";
import {pushMeasurements} from "../apiClients/platformApi";
import * as Device from 'expo-device';
import * as WebPlatform from 'platform';
import {getPlatform} from "./platform";

/**
 * A React hook to provide periodic delivery of measurements in the telemetry.js module.
 *
 * @param {number} intervalSeconds  The number of seconds between publishes.
 */
/* istanbul ignore next */
export const useTelemetry = (intervalSeconds) => {
    return useInterval(publishMeasurements, intervalSeconds * 1000);
};

/**
 * A manual method to attempt to publish messages that were recorded.
 *
 * NOTE: Delivery is not guaranteed.
 *
 */
/* istanbul ignore next */
export const publishMeasurements = () => {
    const deviceDetails = buildDeviceDetails();
    const measurements = getState().telemetry;
    if (measurements.length > 0) {
        pushMeasurements(deviceDetails, measurements).then(() => {
            getStore().dispatch(clearMeasurements(measurements));
        }).catch(() => {
            // Do nothing.  We will try again.
        });
    }
};

/* istanbul ignore next */
const buildDeviceDetails = () => {
    const arches = Device.supportedCpuArchitectures;
    const deviceDetails = {
        isDevice: Device.isDevice,
        brand: Device.brand,
        manufacturer: Device.manufacturer,
        modelName: Device.modelName,
        modelId: Device.modelId,
        designName: Device.designName,
        productName: Device.productName,
        deviceYearClass: Device.deviceYearClass,
        totalMemory: Device.totalMemory,
        supportedCpuArchitectures: arches ? arches : [],
        osName: Device.osName,
        osVersion: Device.osVersion,
        osBuildId: Device.osBuildId
    };
    if (getPlatform() === 'web') {
        deviceDetails.brand = 'web';
        deviceDetails.manufacturer = WebPlatform.name;
        deviceDetails.modelId = WebPlatform.version;
        deviceDetails.designName = WebPlatform.description;
    }
    return deviceDetails;
};
