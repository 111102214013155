import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Localization from 'expo-localization';
import {getPlatform} from "../utils/platform";

// Import locale to support string locale
// Important: Need to import desired languages
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/es';


import en from './en';
import fr from './fr';
import es from './es';

const LANGUAGES = {
    en,
    fr,
    es
};


const LANGUAGE_DETECTOR = {
    type: 'languageDetector',
    async: true,
    detect: callback => {
        AsyncStorage.getItem('user-language', (err, language) => {
            // if error fetching stored data or no language was stored
            // display errors when in DEV mode as console statements
            if (err || !language) {
                if (err) {
                    console.log('Error fetching Languages from asyncstorage ', err);
                }

                const findBestAvailableLanguage =
                    {languageTag: 'en', isRTL: false};
                callback(findBestAvailableLanguage.languageTag || 'en');

                return;
            }
            callback(language);
        });
    },
    init: () => {
    },
    cacheUserLanguage: language => {
        AsyncStorage.setItem('user-language', language);
    }
};

const LANGUAGE_DETECTOR_IOS = {
    type: 'languageDetector',
    async: true,
    detect: (callback) => {
        // We will get back a string like "en-US". We
        // return a string like "en" to match our language
        // files.
        callback(Localization.locale.split('-')[0]);
    },
    init: () => { },
    cacheUserLanguage: () => { },
};

const initTranslations = () => {

    if (getPlatform() == 'ios') {
        i18n
            // detect language
            .use(LANGUAGE_DETECTOR_IOS)
            .use(initReactI18next)
            // set options
            .init({
                compatibilityJSON: 'v3',
                fallbackLng: 'en',
                resources: LANGUAGES,
                react: {
                    useSuspense: false
                },
                interpolation: {
                    escapeValue: false
                },
                defaultNS: 'common'
            });

    } else {
        i18n
            // detect language
            .use(LANGUAGE_DETECTOR)
            // pass the i18n instance to react-i18next.
            .use(initReactI18next)
            // set options
            .init({
                compatibilityJSON: 'v3',
                fallbackLng: 'en',
                resources: LANGUAGES,
                react: {
                    useSuspense: false
                },
                interpolation: {
                    escapeValue: false
                },
                defaultNS: 'common'
            });
    }
};
export default initTranslations;



// If the locale exists in our common files it will return the translation, otherwise it returns the original string
// You need to pass it { t } which a function from the useTranslation hook
export const verifyLocaleAndTranslate = (str, t) => {
    const camelize = (str) => {
        return str.replace('-',' ').replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
            if (+match === 0) return "";
            return index === 0 ? match.toLowerCase() : match.toUpperCase();
        });
    };

// This verifies if the locale is translated within our common translation files
    const verifyLocale = (str) => {
        const stringToVerify = camelize(str.toString().toLowerCase());
        const locale = t(stringToVerify);

        return locale === stringToVerify ? false : true;
    };

    const translate = (str) => {
        let translation;

        switch(str){
            case(typeof(str) === "string"):
                translation = t(camelize(str.toLowerCase()));
                break;
            case(typeof(str) === "number"):
                translation = str;
                break;
            default:
                translation = t(camelize(str.toString().toLowerCase()));
        }

        return translation;
    };

    if(verifyLocale(str)) {
        return translate(str);
    } else {
        return str;
    }
};

