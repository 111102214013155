import {
    CLEAR_EVENTS,
    PUSH_EVENT
} from "./actionTypes";

export const defaultState = [];

const telemetryReducer = (state = defaultState, action) => {
    switch (action.type) {
        case PUSH_EVENT:
            return [...state, action.event];
        case CLEAR_EVENTS:
            return state.filter((event) => action.events.indexOf(event) < 0);
        default:
            return state;
    }
};

export default telemetryReducer;
