import {CONFIG_RESET, CONFIG_SET} from "./actionTypes";

export const resetConfig = () => ({
    type: CONFIG_RESET
});
export const setConfig = (config) => {
    return ({
        type: CONFIG_SET,
        config: config
    });
};
