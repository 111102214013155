import React from 'react';
import {useSelector} from "react-redux";
import useLargeFormFactorCheck from "../utils/responsive";
import TopBarNavigation from "./topBarNavigation";
import PropTypes from "prop-types";

const AppContainer = (props) => {

    const {user} = useSelector(state => state.auth);
    const isDesktopOrLaptop = useLargeFormFactorCheck();

    return (<>
        {user && isDesktopOrLaptop? (<TopBarNavigation navigation={props.navigation}/>) : null}

        {props.children}
    </>
    );
};

AppContainer.propTypes = {
    // Defines the content elements of the app container
    children: PropTypes.node.isRequired,
    navigation: PropTypes.object
};

export default AppContainer;
