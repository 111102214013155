import * as React from "react";
import {ScrollView, Text, View} from "react-native";
import {SafeAreaView} from "react-native-safe-area-context";
import AnonymousTopBarNavigation from "../../navigation/anonymousTopBarNavigation";
import ExternalLink from "../../components/ExternalLink";
import {buildStyles} from "./styles";
import {useColors} from "../../store/whiteLabelConfig/util";

const SupportScreen = () => {
    const styles = buildStyles(useColors());
    return (
        <SafeAreaView style={styles.container}>
            <AnonymousTopBarNavigation tabName={"Support"}/>
            <ScrollView>
                <View style={styles.supportContainer}>
                    <Text style={styles.title}>Contact Us</Text>
                    <Text style={styles.contactIntro}>Support for auction problems and related issues:</Text>

                    <View style={styles.contactTypes}>
                        <View style={styles.contactType}>
                            <Text style={styles.contactTitle}>Email:</Text>
                            <View style={styles.contactValue}><ExternalLink url={"mailto:open.auto@acvauctions.com"}
                                                                            linkText={"open.auto@acvauctions.com"}/></View>
                        </View>
                        <View style={styles.contactType}>
                            <Text style={styles.contactTitle}>Phone:</Text>
                            <View style={styles.contactValue}><ExternalLink url={"tel:1-800-553-4070"}
                                                                            linkText={"1-800-553-4070"}/></View>
                        </View>
                        <View style={styles.contactType}>
                            <Text style={styles.contactTitle}>Address:</Text>
                            <View style={styles.contactValue}><ExternalLink
                                url={"https://www.google.com/maps/place/ACV+Auctions/@42.896338,-78.868961,15z/data=!4m5!3m4!1s0x0:0xd5d809e01cd8d070!8m2!3d42.8960057!4d-78.8688111?hl=en-US"}
                                linkText={"640 Ellicott St\nSuite #321\nBuffalo, NY 14203"}/></View>
                        </View>
                    </View>
                    <View style={styles.hoursOfOperation}>
                        <Text style={styles.hoursTitle}>Support Hours</Text>
                        <Text style={styles.hoursDay}>Monday - Friday</Text>
                        <Text style={styles.hoursValue}>9:00am - 10:00pm ET</Text>
                        <Text style={styles.hoursDay}>Saturday</Text>
                        <Text style={styles.hoursValue}>9:00am - 2:00pm ET</Text>
                        <Text style={styles.hoursDay}>Sunday</Text>
                        <Text style={styles.hoursValue}>Closed</Text>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export default SupportScreen;