import {getConfig} from "../config/config";
import ApiClient from "./apiClient";
import {pushMeasurement} from "../utils/telemetry";

export const fetchListings = async () => {
    const gatewayClient = new ApiClient(getConfig().acvApiUrl);
    const listingsResponse = await gatewayClient.get(`/saved_auctions?all_dealers=true`);
    return listingsResponse.data.data;
};

export const fetchCarsForLane = async (laneId) => {
    const gatewayClient = new ApiClient(getConfig().auctionApiUrl);
    const privateLineResponse = await gatewayClient.get(`/filters/auctions/buying/private_lane/${laneId}`);
    return privateLineResponse.data.data;
};

export const fetchAuctionInfo = async (auctionIds) => {
    const gatewayClient = new ApiClient(getConfig().auctionApiUrl);
    const auctionInfo = await gatewayClient.post('/auctions/buying/search/list_by_id', auctionIds);
    return auctionInfo.data.data;
};

export const fetchAuctionDetailsByID = async (auctionId) => {
    const gatewayClient = new ApiClient(getConfig().auctionApiUrl);
    const auctionDetails = await gatewayClient.get(`/auctions/buying/search/auction/${auctionId}`);
    return auctionDetails.data.data;
};

export const increaseViewCount = async (auctionId) => {
    const gatewayClient = new ApiClient(getConfig().acvApi3Url);
    const response = await gatewayClient.post(`/auction/${auctionId}/view_count`);
    pushMeasurement("viewAuction", {auctionId});
    return response;
};

const _doBidPost = async (amount, auctionId, isProxy) => {
    const gatewayClient = new ApiClient(getConfig().acvApi3Url);
    const bidInfo = {
        amount,
        auctionId,
        persistent: false,
        proxy: isProxy,
        userId: gatewayClient.userId
    };
    return await gatewayClient.post(`/auction/${auctionId}/bid`, bidInfo);
};

export const bid = async (auctionId, amount) => {
    const auctionInfo = await _doBidPost(amount, auctionId, false);
    pushMeasurement("bid", {auctionId, amount});
    return auctionInfo.data.data;
};

export const proxyBid = async (auctionId, amount) => {
    const proxyResponse = await _doBidPost(amount, auctionId, true);
    pushMeasurement("proxy", {auctionId, amount});
    return proxyResponse.data.data;
};

