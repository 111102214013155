import React, {useCallback, useEffect, useState} from 'react';
import {ScrollView, StyleSheet, Text, View} from "react-native";
import {SafeAreaView} from "react-native-safe-area-context";
import {bid, fetchAuctionDetailsByID, increaseViewCount, proxyBid} from "../../apiClients/auctionsApi";
import useInterval from "../../utils/interval";
import PropTypes from "prop-types";
import useLargeFormFactorCheck from "../../utils/responsive";
import VehicleDetailsOverview from "../../components/vdp/vehicleDetailsOverview";
import {getStore} from "../../store";
import {useTranslation} from "react-i18next";
import BidButtonModal from "../../components/bid/bidButtonModal";
import BidSwipeModal from "../../components/bid/bidSwipeModal";
import VehicleDetails from "../../components/vdp/vehicleDetails";
import {useColors} from "../../store/whiteLabelConfig/util";
import {pushMeasurement} from "../../utils/telemetry";
import {useToastMessaging} from "../../utils/messaging";
import BidProxyModal from "../../components/bid/bidProxyModal";

const VDPScreen = (props) => {

    const toast = useToastMessaging();
    const styles = buildStyles(useColors());
    const {t} = useTranslation();
    const auctionId = props.route.params.id;
    const [details, setDetails] = useState(null);
    const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));
    const [showBidSwipeModal, setShowBidSwipeModal] = useState(false);
    const [showProxyModal, setShowProxyModal] = useState(false);
    const store = getStore();
    const userId = store.getState().auth.user["user_id"];
    const isHighBidder = userId === details?.high_bidder;

    /* istanbul ignore next */
    useInterval(() => {
        setCurrentTime(Math.floor(Date.now() / 1000));
    }, props.timerIntervalMs);

    const startBidSwipeModal = useCallback(() => {
        setShowBidSwipeModal(true);
    }, []);

    const startProxyModal = useCallback(() => {
        setShowProxyModal(true);
    }, []);

    const bidOnAuction = useCallback(async (auctionId, amount) => {
        try {
            if (auctionId && amount) {
                await bid(auctionId, amount);
                setDetails({...details, bid_amount: amount, high_bidder: userId});
            }
        } catch (error) {
            const title = t("There was a problem.");
            const errorMessage = t("There was a problem placing your bid. Please try again.");
            toast.showError(title, errorMessage);
            pushMeasurement("bidError", {error});
        } finally {
            setShowBidSwipeModal(false);
        }
    }, [details]);

    const proxyOnAuction = useCallback(async (auctionId, amount) => {
        try {
            if (auctionId && amount) {
                await proxyBid(auctionId, amount);
            }
        } catch (error) {
            const title = t("There was a problem.");
            const errorMessage = t("errorProxyGeneric.");
            toast.showError(title, errorMessage);
            pushMeasurement("proxyError", {error});
        } finally {
            setShowProxyModal(false);
        }
    }, [details]);

    /**
     * Increase auction view count once VDP page is opened
     */
    useEffect(() => {
        increaseViewCount(auctionId);
    }, []);

    /**
     * Fetch auction detail every timerIntervalMs
     */
    useEffect(() => {
        let mounted = true;
        const getAuctionDetails = async (auctionId) => {
            const auctionDetails = await fetchAuctionDetailsByID(auctionId);
            /* istanbul ignore next */
            if (mounted) {
                auctionDetails.endTime = Math.floor(Date.parse(auctionDetails.end_time.replace(' ', 'T')+'Z') / 1000);
                setDetails(auctionDetails);
            }
        };

        getAuctionDetails(auctionId);
        /* istanbul ignore next */
        return () => {
            mounted = false;
        };
    }, [currentTime]);


    const calcHighBid = useCallback(() => {
        return details.bid_amount ? details.bid_amount : details.start_price;
    }, [details]);

    const isDesktopOrLaptop = useLargeFormFactorCheck();
    if (!details) {
        return null;
    }
    const vehicleInformationTitle = t("vehicleDetails");

    return (
        <SafeAreaView testID="vdp-screen" style={styles.container}>
            <View style={styles.vdpContainer}>
                <ScrollView showsVerticalScrollIndicator={true}>
                    <>
                        <VehicleDetailsOverview
                            details={details}
                            status={details.status}
                            currentTime={currentTime}
                            endTime={details.endTime}
                            showBidModal={startBidSwipeModal}
                            showProxyModal={startProxyModal}
                            isHighBidder={isHighBidder}
                        />
                        <Text style={styles.vehicleInformationTitle}>{vehicleInformationTitle}</Text>
                    </>

                    <VehicleDetails details={details.display_info}/>
                    <>
                        {showBidSwipeModal && isDesktopOrLaptop &&
                            <BidButtonModal
                                closeAction={bidOnAuction}
                                auctionDetails={details}
                                highBid={calcHighBid()}
                                currentTime={currentTime}
                                endTime={details.endTime}
                            />}
                        {showBidSwipeModal && !isDesktopOrLaptop &&
                            <BidSwipeModal
                                closeAction={bidOnAuction}
                                auctionDetails={details}
                                highBid={calcHighBid()}
                                currentTime={currentTime}
                                endTime={details.endTime}
                            />}
                    </>
                    <>
                        {showProxyModal &&
                        <BidProxyModal
                            closeAction={proxyOnAuction}
                            auctionDetails={details}
                            highBid={calcHighBid()}
                            currentTime={currentTime}
                            endTime={details.endTime}
                        />}
                    </>
                </ScrollView>
            </View>
        </SafeAreaView>
    );
};

const buildStyles = colors => StyleSheet.create({
    container: {
        backgroundColor: colors.background["background-3-color"],
        flex: 1,
        flexDirection: "column"
    },
    vdpContainer: {
        flex: 1,
        alignSelf: "center",
        width: "90%",
        maxWidth: 1000,
    },
    vehicleInformationTitle: {
        fontFamily: "Roboto_700Bold",
        fontSize: 15,
        lineHeight: 21,
        textTransform: "uppercase",
        paddingTop: 20,
        paddingBottom: 15,
        color: colors.text["text-secondary-color"]
    }
});

VDPScreen.defaultProps = {
    timerIntervalMs: 1000
};

VDPScreen.propTypes = {
    route: PropTypes.object,
    // Defines the interval between updating the timer status on the auctions.ß
    timerIntervalMs: PropTypes.number
};

export default VDPScreen;
