import React, {useCallback} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {globalHeading, globalText} from "../../fonts";
import CancelIcon from "../icons/cancelIcon";
import ACVModal from "../modal";
import TimerText from "../timerText";
import PropTypes from "prop-types";
import {useTranslation} from 'react-i18next';
import PrimaryButton from "../primaryButton";
import TertiaryButton from "../tertiaryButton";
import {getCurrency} from "locale-currency";
import {useColors, useConfig} from "../../store/whiteLabelConfig/util";

const BidButtonModal = (props) => {

    const {closeAction, auctionDetails, highBid, currentTime, endTime} = props;
    const locale = useConfig().country;
    const bidIncrement = 100;
    const convertedIncrement = bidIncrement.toLocaleString(locale, {
        style: 'currency',
        currency: getCurrency(locale), minimumFractionDigits: 0, maximumFractionDigits: 0
    });

    const handleButtonPress = useCallback(async () =>
        await closeAction(auctionDetails.id, highBid + bidIncrement)
    , []);
    const colors = useColors();
    const {t} = useTranslation();
    const placeBid = t("common:bid") + " + " + convertedIncrement;
    const cancel = t("common:cancel");
    const styles = buildStyles(colors);

    return (
        <ACVModal closeAction={closeAction}>
            <View style={styles.modalContent}>
                <View style={styles.rowContainer}>
                    <Text style={styles.bidTitle}>{t('bid')} + {convertedIncrement}</Text>
                    <TouchableOpacity style={styles.cancel} testID={"cancelIcon"} onPress={closeAction}>
                        <CancelIcon color={useColors().text["text-secondary-color"]} widthHeight={12}/>
                    </TouchableOpacity>
                </View>
                <View style={styles.timerRowContainer}>
                    <Text style={styles.timerDetailsTitle}>{t('timeRemaining')}:</Text>
                    <TimerText
                        style={styles.timerDetailsValue}
                        currentTime={currentTime}
                        endTime={endTime}/>
                </View>
                <View style={styles.bidInfoRowContainer}>
                    <View style={styles.currentBidContainer}>
                        <Text style={styles.bidDetailsTitle}>{t('currentBid')}</Text>
                        <Text style={styles.currentBid}>{highBid.toLocaleString(locale, {
                            style: 'currency',
                            currency: getCurrency(locale), minimumFractionDigits: 0, maximumFractionDigits: 0
                        })}</Text>
                    </View>
                    <View style={styles.bidContainer}>
                        <Text style={styles.bidDetailsTitle}>{t("yourBid")}</Text>
                        <Text style={styles.bidDetailsValue}>{(highBid + bidIncrement).toLocaleString(locale, {
                            style: 'currency',
                            currency: getCurrency(locale), minimumFractionDigits: 0, maximumFractionDigits: 0
                        })}</Text>
                    </View>
                </View>

                <View style={styles.buttonRowContainer}>
                    <TertiaryButton onPress={closeAction} title={cancel}/>
                    <PrimaryButton testID={"bid-button-primary"} onPress={handleButtonPress} title={placeBid}/>
                </View>
            </View>
        </ACVModal>);
};

const buildStyles = colors => StyleSheet.create({
    cancel: {
        color: colors.text["text-secondary-color"],
        ...globalText.button
    },
    cancelText: {
        paddingLeft: 10,
        color: colors.text["text-inverse-color"],
        ...globalText.button
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
    },
    bidTitle: {
        ...globalHeading["h6-medium"],
        fontFamily: 'Roboto_700Bold',
        color: colors.text["text-primary-color"],
    },
    timerDetailsTitle: {
        ...globalHeading["subtitle-base-medium"],
        color: colors.text["text-tertiary-color"]
    },
    timerDetailsValue: {
        paddingLeft: 5,
        ...globalHeading["subtitle-base-medium"],
        fontFamily: 'Roboto_700Bold',
        color: colors.text["text-tertiary-color"]
    },
    bidDetailsTitle: {
        fontSize: 17,
        lineHeight: 20,
        fontFamily: 'Roboto_700Bold',
        color: colors.text["text-secondary-color"],
        paddingBottom: 5,
    },
    bidDetailsValue: {
        ...globalHeading["subtitle-base-regular"],
        fontFamily: 'Roboto_700Bold',
        fontSize: 38,
        color: colors.text["text-secondary-color"]
    },
    currentBid: {
        ...globalHeading["subtitle-base-regular"],
        fontFamily: 'Roboto_700Bold',
        fontSize: 38,
        color: colors.text["text-tertiary-color"],
        textDecorationLine: "line-through"
    },
    timerRowContainer: {
        width: "100%",
        flexDirection: "row",
        paddingBottom: 18,
        maxWidth: 330,
        alignSelf: "center"
    },
    bidInfoRowContainer: {
        width: 298,
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: 35,
        maxWidth: 330,
        alignSelf: "center",
        borderBottomWidth: 1,
        borderColor: colors.background["border-1-color"],
    },
    rowContainer: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: 7,
        maxWidth: 330,
        alignSelf: "center"
    },
    buttonRowContainer: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: 25,
        maxWidth: 330,
        alignSelf: "center"
    },
    bidContainer: {
        flexDirection: "column",
        alignItems: "flex-start",
        paddingRight: 20,
    },
    currentBidContainer: {
        paddingRight: 40,
        flexDirection: "column",
        alignItems: "flex-start"
    },
    modalContent: {
        backgroundColor: colors.background["background-2-color"],
        padding: 22
    },
    bottomModal: {
        justifyContent: 'flex-end',
        margin: 0,
    },
    webModal: {
        alignSelf: "center",
        justifyContent: 'center',
    },
    webModalContainer: {
        height: "100%",
        width: "100%",
        position: "absolute",
        overflow: "hidden"
    }
});

BidButtonModal.propTypes = {
    closeAction: PropTypes.func,
    auctionDetails: PropTypes.object,
    highBid: PropTypes.number,
    currentTime: PropTypes.number,
    endTime: PropTypes.number
};

export default BidButtonModal;
