import * as React from "react";
import Svg, {Path} from "@cantoo/rn-svg";
import PropTypes from "prop-types";

const TimerIcon = (props) => {
    let widthHeight = props.height;
    return (
        <Svg
            aria-hidden="true"
            data-prefix="far"
            data-icon="clock"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width={widthHeight}
            height={widthHeight}
            fill={props.color}
        >
            <Path
                color="currentColor"
                d="M256 8a248 248 0 1 0 0 496 248 248 0 0 0 0-496zm0 448a200 200 0 1 1-.1-399.9A200 200 0 0 1 256 456zm61.8-104.4-84.9-61.7a12 12 0 0 1-4.9-9.7V116a12 12 0 0 1 12-12h32a12 12 0 0 1 12 12v141.7l66.8 48.6a12 12 0 0 1 2.6 16.8L334.6 349a12 12 0 0 1-16.8 2.6z"
            />
        </Svg>
    );
};
TimerIcon.propTypes = {
    color: PropTypes.string.isRequired,
    height: PropTypes.number
};

TimerIcon.defaultProps = {
    height: 13
};

export default TimerIcon;