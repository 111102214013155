import * as React from "react";
import Svg, {Path, Rect} from "@cantoo/rn-svg";
import PropTypes from "prop-types";

const VehiclePlaceholderIcon = (props) => {
    let widthHeight = props.height;
    return (
        <Svg
            aria-hidden="true"
            data-prefix="far"
            data-icon="clock"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 92 92"
            width={widthHeight}
            height={widthHeight}
        >
            <Rect width={92} height={92} rx={3} fill="#EEE" />
            <Path
                d="M71.082 37.667h-6.236l-1.734-4.334A11.607 11.607 0 0 0 52.281 26H39.05c-4.797 0-9.05 2.878-10.832 7.333l-1.733 4.334h-6.235a1.25 1.25 0 0 0-1.213 1.553l.625 2.5a1.25 1.25 0 0 0 1.213.947h2.09c-1.399 1.221-2.3 2.998-2.3 5v5c0 1.679.642 3.194 1.667 4.367v5.633A3.334 3.334 0 0 0 25.666 66H29a3.334 3.334 0 0 0 3.333-3.333v-3.334H59v3.334A3.334 3.334 0 0 0 62.333 66h3.333A3.334 3.334 0 0 0 69 62.667v-5.633c1.025-1.172 1.666-2.687 1.666-4.367v-5c0-2.002-.9-3.779-2.299-5h2.091a1.25 1.25 0 0 0 1.213-.947l.624-2.5a1.25 1.25 0 0 0-1.213-1.553Zm-36.673-1.858a5 5 0 0 1 4.643-3.142H52.28a5 5 0 0 1 4.643 3.142L59 41H32.333l2.076-5.19Zm-5.41 16.837c-2 0-3.333-1.33-3.333-3.323C25.666 47.329 27 46 29 46s5 2.99 5 4.984c0 1.994-3 1.662-5 1.662Zm33.334 0c-2 0-5 .332-5-1.662 0-1.993 3-4.984 5-4.984s3.333 1.33 3.333 3.323c0 1.994-1.333 3.323-3.333 3.323Z"
                fill="#D6D6D6"
            />
        </Svg>
    );
};
VehiclePlaceholderIcon.propTypes = {
    height: PropTypes.number
};
export default VehiclePlaceholderIcon;
