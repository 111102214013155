import {combineReducers} from "redux";
import authReducer from "./auth/reducer";
import whiteLabelReducer from "./whiteLabelConfig/reducer";
import telemetryReducer from "./telemetry/reducer";

export default combineReducers({
    auth: authReducer,
    whiteLabelConfig: whiteLabelReducer,
    telemetry: telemetryReducer
});
