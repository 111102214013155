export const defaultColors = {
    primaryShades: {
        "primary-color-x-dark": "#73378E",
        "primary-color-dark": "#8F59A1",
        "primary-color-base": "#9974a4",
        "primary-color-light": "#cfbfd3",
        "primary-color-x-light": "#cfbfd3",
    },
    gradient: {
        "primary-gradient": ["#8F59A1", "#73378E"],
    },
    secondaryShades: {
        "secondary-color-x-dark": "#00304C",
        "secondary-color-dark": "#004066",
        "secondary-color-base": "#004E7D",
        "secondary-color-light": "#ADE0FF",
        "secondary-color-x-light": "#EBF6FF",
    },
    text: {
        "text-primary-color": "#212121",
        "text-secondary-color": "#545454",
        "text-tertiary-color": "#757575",
        "text-disabled-color": "#A1A1A1",
        "text-inverse-color": "#FFFFFF",
        "text-error-color": "#FC4243",
        "text-link-color": "#004E7D",
        "text-link-inverse-color": "#ADE0FF",
        "text-success-color": "#ADE0FF",
    },
    background: {
        "border-1-color": "#DCDFE8",
        "background-1-color": "#dadada",
        "background-2-color": "#e4e5e6",
        "background-3-color": "#FFFFFF",
        "background-grid-1-color": "#E0E6EE",
        "transparent": "#FFFFFF00"
    },
    greys: {
        "ui-grey-x-dark": "#212121",
        "ui-grey-dark": "#757575",
        "ui-grey-base-dark": "#E0E0E0",
        "ui-grey-light": "#F5F5F5",
        "ui-white-color": "#FFFFFF",
    },
    error: {
        "ui-error-color-x-dark": "#A70202",
        "ui-error-color-dark": "#D80305",
        "ui-error-color-base": "#FC4243",
        "ui-error-color-light": "#FEAFAE",
        "ui-error-color-x-light": "#FFEBEB",
    },
    success: {
        "ui-success-color-x-dark": "#005C42",
        "ui-success-color-dark": "#007554",
        "ui-success-color-base": "#00A878",
        "ui-success-color-light": "#C2E9D9",
        "ui-success-color-x-light": "#F0FAF6",
    },
    caution: {
        "ui-caution-color-x-dark": "#856300",
        "ui-caution-color-dark": "#B88A00",
        "ui-caution-color-base": "#FFC000",
        "ui-caution-color-light": "#FFEBAD",
        "ui-caution-color-x-light": "#FFFAEB",
    },
    informational: {
        "ui-informational-color-x-dark": "#212121",
        "ui-informational-color-dark": "#004066",
        "ui-informational-color-base": "#004E7D",
        "ui-informational-color-light": "#ADE0FF",
        "ui-informational-color-x-light": "#EBF6FF",
    }
};
