import React, {useCallback} from 'react';
import {StyleSheet, Text, View} from "react-native";
import HighestBidder from "./icons/highestBidder";
import PropTypes from "prop-types";
import {globalText} from "../fonts";
import ReserveMet from "./icons/reserveMet";
import OutbidIcon from "./icons/outbidIcon";
import {useColors} from "../store/whiteLabelConfig/util";

const StatusPillLarge = (props) => {
    const {iconType, text, backgroundColor} = props;
    const colors = useColors();
    const styles = buildStyles(colors);

    const renderIcon = useCallback((iconType) => {
        switch (iconType) {
            case "highest_bidder":
                return (<HighestBidder widthHeight={12} color={colors.text["text-inverse-color"]}/>);
            case "reserve_met":
                return (<ReserveMet widthHeight={12} color={colors.text["text-inverse-color"]}/>);
            case "out_bid":
                return (<OutbidIcon widthHeight={12} color={colors.text["text-inverse-color"]}/>);
        }
    }, []);

    return (
        <View style={[styles.container, {backgroundColor: backgroundColor}]}>
            {renderIcon(iconType)}
            <Text numberOfLines={1} style={[styles.pillText, {backgroundColor: backgroundColor}]}>{text}</Text>
        </View>
    );
};

const buildStyles = colors => StyleSheet.create({
    container: {
        flexDirection: "row",
        borderRadius: 3,
        paddingHorizontal: 6,
        paddingVertical: 4,
        marginLeft: 4,
    },
    pillText: {
        ...globalText["status-badge-base"],
        paddingLeft: 5,
        color: colors.greys["ui-white-color"]
    }
});


StatusPillLarge.propTypes = {
    iconType: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
};

export default StatusPillLarge;