export default {
    languageSelector: 'Sélecteur de langue',
    marketplace: 'Marché',
    results: 'Résultats',
    bid: 'Enchère',
    menu: 'Menu',
    liveAuctions: 'Enchères en direct',
    logout: 'Se déconnecter',
    privacyPolicy: 'Politique de confidentialité',
    changeLanguage: 'Changer de langue',
    emailAddress: 'Adresse courriel',
    password: 'Mot de passe',
    login: 'Connexion',
    forgotPassword: 'Mot de passe oublié?',
    requestResetPassword: 'Demander la réinitialisation du mot de passe',
    NetworkError: 'Erreur de réseau',
    wrongCredentials: 'L\'adresse courriel et/ou le mot de passe est erroné',
    noServerConnection: 'N\'a pas pu se connecter au serveur. Veuillez vérifier votre connexion et réessayer plus tard',
    unablePasswordReset: 'Impossible de réinitialiser le mot de passe',
    resetPassword: 'Réinitialisez votre mot de passe',
    resetPasswordDesc: "Pour réinitialiser votre mot de passe, veuillez saisir l'adresse e-mail associée à votre compte.",
    emailSent: 'Un courriel a été envoyé avec des instructions pour réinitialiser votre mot de passe',
    requestReset: 'Demande de réinitialisation',
    returnToLogin: 'Revenir à la connexion',
    vehicleDetails: 'Les détails du véhicule',
    views: 'Vues',
    bids: 'Enchères',
    photo: 'Photo',
    ending: 'FIN',
    bidding: 'Enchère',
    vin: 'NIV',
    city: 'Ville',
    odometer: 'Odomètre',
    transmission: 'Transmission',
    automatic: 'Automatique',
    manual: 'Manuelle',
    trim: 'Version',
    drivetrain: 'Transmission',
    engine: 'Moteur',
    fuelType: 'Type de carburant',
    year: 'Année',
    make: 'Marque',
    model: 'Modèle',
    color: 'Couleur',
    auctionId: 'ID d\'enchère',
    auctionDate: 'Date d\'enchère',
    sold: 'VENDU',
    unsold: 'INVENDU',
    swipeToBid: 'Faites glisser pour enchérir',
    currentBid: 'Enchère actuelle',
    yourBid: 'Votre enchère',
    timeRemaining: 'Temps restant',
    vehicle: 'Véhicules',
    cancel: 'Annuler',
    highBid: 'Surenchères',
    active: 'actif',
    remaining: 'restant',
    reservedPrice: 'Prix de réserve',
    highestBid: 'Offre la plus élevée',
    highestBidder: 'Le plus offrant',
    reservedMet: 'Réserve remplie',
    noReserve: 'Pas de réserve',
    version: 'Version',
    outBid: 'Surenchére',
    auctionDetails: "Détails de l'enchère",
    Miles: 'Milles',
    km: 'km',
    day: 'jour',
    days: 'jours',

    // COLORS
    black: 'Noire',
    white: 'Blanche',
    silver: 'Argent',
    red: 'Rouge',
    gray: 'Grise',
    green: 'Verte',
    blue: 'Bleue',
    purple: 'Mauve',
    orange: 'Orange',
    beige: 'Beige',
    brown: 'Brune',
    yellow: 'Jaune',
    gold: 'Or',
    blueLight: 'Bleue (Claire)',
    blueDark: 'Bleue (Foncée)',
    tan: 'Bronzée',
    maroon: 'Bordeaux',
    pearl: 'Perle',
    pink: 'Rose',

    // Proxy Bidding
    proxyBid: 'Enchère Par Procuration',
    setProxy: 'Place Une Procuration',
    swipeProxy: 'Glissez Pour Placer Une Procuration',
    editProxy: 'Editer Procuration',
    yourProxy: 'Votre Procuration',
    proxyMultiples: 'Encherez En Multiples De $50',
    confirmProxyTitle: 'Confirmer Procuration',
    confirmProxySubtitle: 'Veuillez Confirmer Le Montant',
    errorMismatch: 'Erreur! Les montants ne correspondent pas. Modifiez les montants.',
    errorProxyIncrement: 'Erreur! Encherez En Multiples De $50.',
    errorLowProxy: 'Erreur! Le montant est trop bas. Augmentez votre montant.',
    errorHighProxy: 'Erreur! Le montant est trop élevé. Réduisez votre montant.',
    errorProxyGeneric: 'Erreur! Il y avait un problème. Veuillez essayer plus tard.',

    // Fuel Type
    gas: 'Gaz',
    flex: 'Polycarburant',
    diesel: 'Diesel',
    hybrid: 'Hybride',
    lpg: 'LPG',
    plugInHybrid: 'Hybride Rechargeable',
    electric: 'Électrique',
    cng: 'CNG',
    PrivacyPolicy: 'Politique de confidentialité',
    TermsAndConditions: 'Mentions légales',
    Support: "Soutien",
    success: 'Succès.',
    resetPasswordEmailSent: 'Votre mot de passe a été réinitialisé. Veuillez vérifier votre e-mail.',
    "There was a problem.": "Il y avait un problème.",
    "The email/password combination you entered is invalid. Please try again.": "La combinaison e-mail/mot de passe que vous avez saisie n'est pas valide. Veuillez réessayer.",
    "There was a problem placing your bid. Please try again.": "Un problème est survenu lors de l'envoi de votre enchère. Veuillez réessayer."
};
