import * as React from "react";
import {useCallback} from "react";
import PropTypes from "prop-types";
import {Linking, StyleSheet, Text} from "react-native";
import {useColors} from "../store/whiteLabelConfig/util";

const ExternalLink = (props) => {
    let {linkText, url} = props;
    if (!linkText) {
        linkText = url;
    }
    const styles = buildStyles(useColors());
    const openLink = useCallback(() => Linking.openURL(url), []);
    return (<Text style={styles.link} onPress={openLink}>{linkText}</Text>);
};

const buildStyles = colors => StyleSheet.create({
    link: {
        color: colors.text["text-link-color"]
    },
});

ExternalLink.propTypes = {
    linkText: PropTypes.string,
    url: PropTypes.string,
};

export default ExternalLink;