import {StyleSheet, Text, View} from "react-native";
import React from "react";
import {globalHeading} from "../../fonts";
import PropTypes from "prop-types";
import {useTranslation} from 'react-i18next';
import {useColors} from "../../store/whiteLabelConfig/util";

const ListingHeader = (props) => {
    let numberCars = props.cars.length;
    const {t} = useTranslation();
    const styles = buildStyles(useColors());
    const title = t("Listings");
    const results = t('Results');
    return (<View style={styles.smallContainer}>
            <Text style={[styles.smallListingsText, globalHeading["subtitle-sm-medium"]]}>{title}</Text>
            <Text testID="number-of-listings-small"
                  style={[{marginLeft: "auto"},
                      globalHeading["subtitle-sm-medium"], styles.smallAuctionsText]}
            >{numberCars} {results}</Text>
        </View>
    );
};

const buildStyles = colors => StyleSheet.create({
    smallContainer: {
        padding: 12,
        backgroundColor: colors.background["background-2-color"],
        flexDirection: "row",
        borderBottomColor: colors.greys["ui-grey-base-dark"],
        borderBottomWidth: 2
    },

    smallListingsText: {color: colors.text["text-tertiary-color"]},

    largeContainer: {
        padding: 12,
        flexDirection: "row",
        alignItems: "flex-end",
    },
});

ListingHeader.propTypes = {
    cars: PropTypes.array
};

export default ListingHeader;
