import React, {useCallback} from 'react';
import {StyleSheet, View} from "react-native";
import HighestBidder from "./icons/highestBidder";
import PropTypes from "prop-types";
import ReserveMet from "./icons/reserveMet";
import OutbidIcon from "./icons/outbidIcon";
import {useColors} from "../store/whiteLabelConfig/util";

const StatusPillSmall = (props) => {
    const {iconType, backgroundColor} = props;
    const colors = useColors();
    const renderIcon = useCallback((iconType) => {
        switch (iconType) {
            case "highest_bidder":
                return (<HighestBidder widthHeight={12} color={colors.text["text-inverse-color"]}/>);
            case "reserve_met":
                return (<ReserveMet widthHeight={12} color={colors.text["text-inverse-color"]}/>);
            case "out_bid":
                return (<OutbidIcon widthHeight={12} color={colors.text["text-inverse-color"]}/>);
        }
    }, []);

    return (
        <View style={[styles.container, {backgroundColor: backgroundColor}]}>
            {renderIcon(iconType)}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: 20,
        height: 20,
        padding: 4,
        marginLeft: 4,
        borderRadius: 10,
    }
});

StatusPillSmall.propTypes = {
    iconType: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
};

export default StatusPillSmall;