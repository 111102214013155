import * as React from "react";
import Svg, {Path} from "@cantoo/rn-svg";

const DoubleArrow = (props) => (
    <Svg
        width={13}
        height={12}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path
            d="M.292.294a.996.996 0 0 0 0 1.41l3.88 3.88-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L1.703.284c-.38-.38-1.02-.38-1.41.01Z"
            fill="#fff"
        />
        <Path
            d="M6.293.294a.996.996 0 0 0 0 1.41l3.88 3.88-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L7.703.284c-.38-.38-1.02-.38-1.41.01Z"
            fill="#fff"
        />
    </Svg>
);

export default DoubleArrow;