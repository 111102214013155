import * as React from "react";
import {useCallback, useState} from "react";
import {View} from "react-native";
import {SvgXml} from "@cantoo/rn-svg";
import PrimaryButton from "../../components/primaryButton";
import {getStore} from "../../store";
import * as api from "../../apiClients/auth";
import {initSession, loginComplete} from "../../store/auth/actions";
import {fetchWhiteLabelConfig} from "../../apiClients/platformApi";
import {setConfig} from "../../store/whiteLabelConfig/actions";
import AcvTextInput from "../../components/textInput";
import {useTranslation} from "react-i18next";
import ExternalLink from "../../components/ExternalLink";
import {getConfig} from "../../config/config";
import {buildStyles} from "./styles";
import {useColors, useConfig} from "../../store/whiteLabelConfig/util";
import {pushMeasurement} from "../../utils/telemetry";
import {useToastMessaging} from "../../utils/messaging";
import HoverableButton from "../../components/hoverableButton";
import PropTypes from "prop-types";

const LoginScreen = (props) => {
    const toast = useToastMessaging();
    const {t} = useTranslation();
    const {navigation} = props;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const {logo} = useConfig();
    const legalUrl = `${getConfig().openAutoUrl}/legal`;
    const privacyUrl = `${getConfig().openAutoUrl}/privacy`;
    const supportUrl = `${getConfig().openAutoUrl}/support`;

    const login = useCallback(async () => {
        try {
            setLoading(true);
            const response = await api.login(email, password);
            const tokens = extractTokens(response);
            getStore().dispatch(initSession(response.data, tokens));
            const whiteLabelConfig = await fetchWhiteLabelConfig();
            getStore().dispatch(setConfig(whiteLabelConfig));
            getStore().dispatch(loginComplete());
            pushMeasurement("login", {dealerGroupId: whiteLabelConfig.dealerGroupId});
        } catch (error) {
            const title = t("There was a problem.");
            const errorMessage = t("The email/password combination you entered is invalid. Please try again.");
            toast.showError(title, errorMessage);
            pushMeasurement("loginError", {error});
        } finally {
            setLoading(false);
        }
    }, [email, password, toast]);

    const extractTokens = response => ({
        jwtToken: response.jwtToken,
        refreshToken: response.refreshToken,
    });

    const forgotPassword = useCallback(() => {
        navigation.push('forgot');
    },[]);

    const updateEmail = useCallback(email => setEmail(email), [email]);
    const updatePassword = useCallback(password => setPassword(password), [password]);
    const styles = buildStyles(useColors());

    return (
        <View style={styles.container}>
            <View style={styles.loginContainer}>
                <SvgXml width="90%" height="80px" xml={logo}/>
                <View style={styles.loginFormContainer}>
                    <AcvTextInput
                        autoFocus={true}
                        autoCapitalize="none"
                        label={t("emailAddress")}
                        keyboardType="email-address"
                        testID="email"
                        onChangeText={updateEmail}
                        value={email}/>
                    <AcvTextInput
                        label={t("password")}
                        testID="password"
                        secureTextEntry={true}
                        onChangeText={updatePassword}
                        value={password}/>
                    <PrimaryButton
                        title={t("login")}
                        testID="login"
                        disabled={loading}
                        onPress={login}/>
                </View>

                <View style={styles.forgotPasswordContainer}>
                    <HoverableButton
                        testID={"forgot-password"}
                        title={t("forgotPassword")}
                        onPress={forgotPassword}
                    />
                </View>

                <View style={styles.linksContainer}>
                    <ExternalLink linkText={t('common:TermsAndConditions')} url={legalUrl}/>
                    <ExternalLink linkText={t('common:PrivacyPolicy')} url={privacyUrl}/>
                    <ExternalLink linkText={t('common:Support')} url={supportUrl}/>
                </View>
            </View>
        </View>);
};

LoginScreen.propTypes = {
    navigation: PropTypes.object
};

export default LoginScreen;
