import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {pushMeasurement} from "../../utils/telemetry";
import MenuItem from "../../components/menuItem";

const LANGUAGES = [
    {code: 'en', label: 'English'},
    {code: 'fr', label: 'Français'},
    {code: 'es', label: 'Español'}
];

const LanguageSelectorScreen = () => {
    const {t, i18n} = useTranslation();
    const selectedLanguageCode = i18n.language;
    const setLanguageCallback = useCallback(code => {
        return () => {
            i18n.changeLanguage(code);
            pushMeasurement("languageChange", {code});
        };
    }, []);

    return (
        <>
            <MenuItem title={t('common:languageSelector')}/>
            {LANGUAGES.map(language => {
                const suffix = language.code === selectedLanguageCode ? " * " : "";
                // eslint-disable-next-line react/jsx-key
                return (<MenuItem
                    key={language.code}
                    title={language.label + suffix}
                    action={setLanguageCallback(language.code)}
                    actionName={`setLanguage${language.code}`}
                    padding={35}/>);
            })}
        </>
    );
};

export default LanguageSelectorScreen;