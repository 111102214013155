import {
    AUTH_COMPLETE,
    AUTH_LOGOUT,
    AUTH_REFRESH,
    AUTH_REFRESH_FAILED,
    AUTH_REFRESH_START,
    AUTH_SUCCESS,
} from "./actionTypes";

export const defaultState = {
  user: null,
  tokens: null,
  isLoggedIn: false,
  error: null,
  loading: false,
  refreshingToken: false,
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_REFRESH_START:
      return {
        ...state,
        refreshingToken: true,
      };
    case AUTH_REFRESH_FAILED:
      return {
        ...state,
        refreshingToken: false,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        user: action.user,
        tokens: action.tokens,
        isLoggedIn: false,
        refreshingToken: false,
      };
    case AUTH_COMPLETE:
      return {
        ...state,
        isLoggedIn: true,
      };
    case AUTH_LOGOUT:
      return defaultState;
    case AUTH_REFRESH:
      return {
        ...state,
        tokens: action.tokens,
        refreshingToken: false,
      };
    default:
      return state;
  }
};

export default authReducer;
