import * as React from "react";
import {useCallback} from "react";
import MultiSlider from "@ptomasroos/react-native-multi-slider";
import SliderButton from "./sliderButton";
import {StyleSheet, Text, View} from "react-native";
import {globalText} from "../../fonts";
import * as PropType from "prop-types";
import {useColors} from "../../store/whiteLabelConfig/util";

const BidSlider = (props) => {
    const {sliderValue, handleSliderChange, testID} = props;

    const sliderButton = useCallback(() => {
        return <SliderButton testIdPrefix={testID}/>;
    });
    const styles = buildStyles(useColors());

    return (
        <View style={styles.container}
              testID={testID}>
            <Text style={styles.swipeText}>{props.title}</Text>
            <MultiSlider
                sliderLength={290}
                containerStyle={{
                    marginTop: 20,
                    marginLeft: 24,
                    height: 48,
                    justifyContent: "center",
                    backgroundColor: useColors().background.transparent,
                    borderRadius: 6,
                }}
                trackStyle={styles.track}
                values={sliderValue}
                allowOverlap={true}
                customMarker={sliderButton}
                onValuesChangeFinish={handleSliderChange}
            />
        </View>);

};

const buildStyles = (colors) =>
    StyleSheet.create({
        container: {
            backgroundColor: colors.background["border-1-color"],
            paddingRight: 16,
            borderRadius: 6
        },
        track: {
            height: 0,
        },
        swipeText: {
            textTransform: "uppercase",
            marginTop: 18,
            marginBottom: -52,
            ...globalText.button,
            color: colors.greys["ui-grey-dark"],
            textAlign: "center",
            backgroundColor: colors.background.transparent
        }
    });

BidSlider.propTypes = {
    sliderValue: PropType.arrayOf(PropType.number),
    handleSliderChange: PropType.func,
    testID: PropType.string,
    title: PropType.string
};

export default BidSlider;
