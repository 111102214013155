import {installInterceptors} from "./interceptors";
import BaseApiClient from "./baseApiClient";

/**
 * The standard API client that should be used by all API modules.  This one will add an auth interceptor
 * to refresh the JWT token when it is needed.
 */
class ApiClient extends BaseApiClient {

    /**
     * Constructor for the API client.  This will also install auth interceptors.
     * @param baseUri The name of the base URI to use when making calls.  This allows the calls to only specify
     * the path and work for different environments.
     */
    constructor(baseUri) {
        super(baseUri);
        installInterceptors(this.axiosInstance);
    }
}

export default ApiClient;
