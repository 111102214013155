/* istanbul ignore file */
import {useEffect, useRef} from "react";

/**
 * A react hook that allows us to update the state of component
 * on a periodic basis.
 *
 * Many thanks to: https://upmostly.com/tutorials/setinterval-in-react-components-using-hooks
 *
 * @param {function} callback The function to update the state of the component.
 * @param {number} delay The amount of delay (millis) between each callback invocation.
 * @param {boolean} runImmediately if true, the callback will be fired immediately before the timers start.
 */
const useInterval = (callback, delay, runImmediately = false) => {
    const savedCallback = useRef();
    const mounted = useRef(true);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Make the callback immediately if set.
    useEffect(() => {
        if(runImmediately) {
            callback(true);
        }
    }, []);

    const unmount = (id) => {
      mounted.current = false;
      clearInterval(id);
    };

    // Set up the interval.
    useEffect(() => {
        const tick = () => savedCallback.current(mounted.current);
        if (delay != null) {
            const id = setInterval(tick, delay);
            return () => unmount(id);
        }
    }, [delay]);
};

export default useInterval;
