// We ignore this for unit testing as it is only used for gluing screens together.  Should be tested at
// a higher level.
/* istanbul ignore file */
import {useSelector} from "react-redux";
import TabBarFlow from "./mobileTabBarNavigation";
import LoginScreen from "../screens/login";
import * as React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import HomeScreen from "../screens/home";
import useLargeFormFactorCheck from "../utils/responsive";
import VDPScreen from "../screens/vdp";
import {NavigationContainer} from "@react-navigation/native";
import {useTranslation} from "react-i18next";
import TermsAndConditionsScreen from "../screens/termsAndConditions";
import AppContainer from "./appContainer";
import PrivacyPolicyScreen from "../screens/privacyPolicy";
import {pushMeasurement} from "../utils/telemetry";
import {useEffect} from "react";
import SupportScreen from "../screens/support";
import ForgotPasswordScreen from "../screens/forgot-password";
import {useTelemetry} from "../utils/telemetryPublish";
import {ListingsScreen} from "../screens/listings";

const MainAppNavigation = () => {
    const Stack = createNativeStackNavigator();
    const {isLoggedIn} = useSelector(state => state.auth);
    const isDesktopOrLaptop = useLargeFormFactorCheck();
    const {t} = useTranslation();
    const liveActionTitle = t("liveAuctions");
    const vdpTitle = t("auctionDetails");
    useTelemetry(10);
    // We wrap this in useEffect, so it is only run once.
    useEffect(() => pushMeasurement("appStart"), []);
    let linking;
    if (isLoggedIn) {
        linking = {
            config: {
                screens: {
                    home: '/auctions',
                    vdp: '/auction/:id/',
                    legal: '/legal',
                    privacy: '/privacy',
                    support: '/support',
                    listings: '/listings',
                }
            },
        };
    } else {
        linking = {
            config: {
                screens: {
                    login: '/login',
                    forgot: '/forgot',
                    legal: '/legal',
                    privacy: '/privacy',
                    support: '/support',
                }
            }
        };
    }

    return (

        <NavigationContainer linking={linking}>
            <AppContainer navigation={Stack}>
                {isLoggedIn ? (
                    <>
                        {
                            isDesktopOrLaptop ? (
                                <Stack.Navigator screenOptions={{headerShown: false}}>
                                    <Stack.Screen name="home" component={HomeScreen}
                                                  options={{title: liveActionTitle}}/>
                                    <Stack.Screen name="vdp" component={VDPScreen}
                                                  options={{title: vdpTitle}}/>
                                    <Stack.Screen name="legal" component={TermsAndConditionsScreen}
                                                  options={{title: "Legal Terms & Conditions"}}/>
                                    <Stack.Screen name="privacy" component={PrivacyPolicyScreen}
                                                  options={{title: "Privacy Policy"}}/>
                                    <Stack.Screen name="support" component={SupportScreen}
                                                  options={{title: "Support"}}/>
                                    <Stack.Screen name="listings" component={ListingsScreen}
                                                  options={{title: "Listings"}}/>
                                </Stack.Navigator>
                            ) : (
                                <TabBarFlow/>
                            )
                        }
                        </>
                        ) : (
                        <Stack.Navigator screenOptions={{headerShown: false}}>
                            <Stack.Screen name="login" component={LoginScreen}
                                          options={{title: null}}/>
                            <Stack.Screen name="forgot" component={ForgotPasswordScreen}
                                          options={{title: "Forgot Password"}}/>
                            <Stack.Screen name="legal" component={TermsAndConditionsScreen}
                                          options={{title: "Legal Terms & Conditions"}}/>
                            <Stack.Screen name="privacy" component={PrivacyPolicyScreen}
                                          options={{title: "Privacy Policy"}}/>
                            <Stack.Screen name="support" component={SupportScreen}
                                          options={{title: "Support"}}/>
                        </Stack.Navigator>

                        )
                }
            </AppContainer>
        </NavigationContainer>
    );
};

export default MainAppNavigation;
