import React, {useCallback, useState} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {SvgXml} from "@cantoo/rn-svg";
import {globalHeading, globalText} from "../fonts";
import {useSelector} from "react-redux";

import MenuIcon from "../components/icons/menuIcon";
import {Link} from "@react-navigation/native";
import MenuScreen from "../screens/menu";
import {useTranslation} from 'react-i18next';
import {getPlatform} from "../utils/platform";
import {useColors, useConfig} from "../store/whiteLabelConfig/util";

const TopBarNavigation = () => {

    const logo = useConfig().logo;
    const {user} = useSelector(state => state.auth);
    const initial = user.full_name ? user.full_name[0] : "?";
    const [hovering, setHovering] = useState(false);
    const [isMenuVisible, setShowMenu] = useState(false);
    const dealershipName = user.dealerships.find(dealership => dealership.id === user.dealer_id).legal_name;
    const colors = useColors();
    const styles = buildStyles(colors);

    const setHoverEnter = useCallback(() => setHovering(true), []);
    const setHoverExit = useCallback(() => setHovering(false), []);
    const toggleMenu = useCallback(() => setShowMenu(!isMenuVisible), [isMenuVisible]);
    const hideMenu = useCallback(() => setShowMenu(false), []);
    const {t} = useTranslation();
    const platform = getPlatform();

    if (platform === 'web') {
        const currentPath = window.location.pathname;
        if (currentPath === '/legal' || currentPath === '/privacy' || currentPath === '/support') {
            return null;
        }
    }

    return (
        <View style={styles.container}>
            <SvgXml height="45px" width="100px" xml={logo}/>

            <View style={styles.screenLinks}>
                <Link to={{screen: 'home'}} style={styles.screenLink}>
                    <Text>{t('marketplace')}</Text>
                </Link>
            </View>
            <TouchableOpacity style={[styles.settingsContainer, hovering ? styles.settingsHover : {}]}
                              onMouseEnter={setHoverEnter}
                              onMouseLeave={setHoverExit}
                              onPress={toggleMenu}
            >
                <Text style={styles.initials}>{initial}</Text>
                <View style={styles.nameContainer}>
                    <Text style={styles.fullName}>{user.full_name}</Text>
                    <Text style={styles.dealerName}>{dealershipName}</Text>
                </View>
                <View style={styles.menuIcon}>
                    <MenuIcon color={colors.text["text-tertiary-color"]}/>
                </View>
            </TouchableOpacity>
            {isMenuVisible ? (
                <View style={styles.menuContainer}>
                    <MenuScreen
                        onMouseLeave={hideMenu}
                    />
                </View>) : null}
        </View>
    );
};

const buildStyles = colors => StyleSheet.create({
    container: {
        height: 58,
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 10,
        paddingLeft: 30,
        paddingRight: 30,
        borderBottomWidth: 1,
        borderBottomColor: colors.background["border-1-color"],
        zIndex: 1,
    },
    screenLinks: {
        marginRight: "auto",
        paddingLeft: 32,
        flexDirection: "row",
        alignContent: "flex-end"
    },
    screenLink: {
        marginRight: 32,
        paddingVertical: 13,
        paddingTop: 10,
        marginBottom: -11,
        borderBottomWidth: 2,
        borderBottomColor: colors.primaryShades["primary-color-base"],
        ...globalText["body-base-regular"],
        color: colors.primaryShades["primary-color-base"],
    },
    initials: {
        alignSelf: "center",
        height: 30,
        width: 30,
        marginRight: 10,
        textAlign: "center",
        textTransform: "capitalize",
        backgroundColor: colors.primaryShades["primary-color-base"],
        color: colors.text["text-inverse-color"],
        borderRadius: 100,
        ...globalHeading["subtitle-base-medium"],
        lineHeight: 30,
    },
    fullName: {
        ...globalHeading["subtitle-sm-medium"],
        color: colors.text["text-tertiary-color"]
    },
    dealerName: {
        ...globalText["caption-base-regular"],
        color: colors.text["text-tertiary-color"]
    },
    nameContainer: {
        alignSelf: "center",
        paddingRight: 23
    },
    menuIcon: {
        alignSelf: "center",
        padding: 2
    },
    settingsContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: 3,
        padding: 5
    },
    languageSettingContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: 3,
        padding: 10
    },
    settingsHover: {
        backgroundColor: colors.background["border-1-color"]
    },
    menuContainer: {
        position: 'absolute',
        top: 54,
        right: 30,
        left: "auto",
        bottom: "auto",
        backgroundColor: colors.background["background-1-color"],
        borderRadius: 2,
        borderWidth: 1,
        borderColor: colors.background["border-1-color"]
    }
});

export default TopBarNavigation;
